import { FC } from "react";
import { Image, Modal } from "react-bootstrap";
import { X } from "react-feather";
import BaseComponentProps from "../../../base-component-props";
import CoverModalStyles from "./CoverModal.module.css";

export interface CoverModalProps extends BaseComponentProps {
  show: boolean;
  handleClose: () => void;
  coverImageUrl: string;
  name: string;
}

const CoverModal: FC<CoverModalProps> = ({
  show,
  handleClose,
  coverImageUrl,
  children,
  name,
}) => {
  return (
    <Modal
      id={`cover-modal-${name}`}
      centered
      show={show}
      onHide={handleClose}
      dialogClassName={CoverModalStyles.coverModalWidth}
      contentClassName={`border-0 ${CoverModalStyles.coverModal} text-center`}
    >
      <X
        id={`cover-modal-${name}-close-button`}
        color="white"
        className="position-absolute cursor-pointer"
        style={{ top: 20, right: 22 }}
        onClick={handleClose}
      />
      <Image src={coverImageUrl} className="card-img-top" />
      {children}
    </Modal>
  );
};

export default CoverModal;
