import { FC, useState } from "react";
import { Button, Image, Spinner } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import { useRecoilCallback, useRecoilState } from "recoil";
import styled from "styled-components";
import { toastError, CircleIconButtonTooltip } from "visible-ui";
import MeClient from "../../communication/clients/me-client";
import SurveyResponsesClient from "../../communication/clients/survey-client";
import { LocaleCodes } from "../../model/locale";
import { myInfoAtom } from "../../state/atoms/me-atoms";
import { currentSurveyAtom } from "../../state/atoms/survey-responses-atoms";
import { useAuth } from "../../hooks/auth-hooks";

export const LocaleButtonsMap: Record<LocaleCodes, string> = {
  [LocaleCodes.en]: "English",
  [LocaleCodes.zh]: "Chinese (中文)",
  [LocaleCodes.ja]: "Japanese (日本語)",
  [LocaleCodes.ko]: "Korean (한국어)",
  [LocaleCodes.fr]: "French (Français)",
  [LocaleCodes.es]: "Spanish (Español)",
  [LocaleCodes.pt]: "Portuguese (Português)",
  [LocaleCodes.ar]: "Arabic (العربية)",
};

const BgLoader = styled.section`
  background-color: rgba(255, 255, 255, 0.7);
`;

const LocaleSelect: FC = () => {
  const [myInfo, setMyInfo] = useRecoilState(myInfoAtom);
  const { getAccessToken } = useAuth();
  const [loading, setLoading] = useState(false);
  const { t } = useTranslation();

  const updateCurrentSurvey = useRecoilCallback(
    ({ set, snapshot }) =>
      async (locale: LocaleCodes) => {
        try {
          const currentSurvey = await snapshot.getPromise(currentSurveyAtom);
          if (currentSurvey?.submitted) {
            return true;
          }
          const accessToken = await getAccessToken();
          const survey = await SurveyResponsesClient.Instance.getCurrentSurvey(
            accessToken
          );
          if (survey) {
            set(currentSurveyAtom, survey);
            return true;
          } else {
            toastError(
              t("errorMessage.loadLocaleSurvey", {
                locale: LocaleButtonsMap[locale],
              })
            );
          }
        } catch (error) {
          console.error(error);
          toastError(
            t("errorMessage.loadLocaleSurvey", {
              locale: LocaleButtonsMap[locale],
            })
          );
        }
        return false;
      }
  );

  const setLocale = async (locale: LocaleCodes) => {
    if (!myInfo) {
      toastError(t("errorMessage.userDetailsMissing"));
      return;
    }
    setLoading(true);
    try {
      const accessToken = await getAccessToken();
      const myInfoToUpdate = { ...myInfo, locale };
      const res = await MeClient.Instance.updateMyInfo(
        accessToken,
        myInfoToUpdate
      );
      if (res?.success) {
        const isSuccess = await updateCurrentSurvey(locale);
        if (isSuccess) {
          setMyInfo(myInfoToUpdate);
          localStorage.setItem("locale", locale);
        } else {
          await MeClient.Instance.updateMyInfo(accessToken, myInfo);
        }
      } else {
        toastError(res?.error_message || t("errorMessage.changeLocale"));
      }
    } catch (error) {
      console.error(error);
      toastError(t("errorMessage.changeLocale"));
    }
    setLoading(false);
  };
  return (
    <>
      <CircleIconButtonTooltip
        offsetX={50}
        width="250px"
        closeOnScroll
        userLocale={myInfo?.locale}
        icon={
          <Image
            className="opacity-75"
            src={`${process.env.PUBLIC_URL}assets/img/locale.svg`}
            alt="Locale"
          />
        }
      >
        <section
        data-testid="locale-select-listbox"
        className="vr-card py-3 d-flex flex-column position-relative overflow-hidden">
          {Object.entries(LocaleButtonsMap).map(([localeCode, locale], i) => (
            <Button
              key={`locale-btn-${localeCode}`}
              onFocus={ev => ev.target.blur()}
              className={`btn-toolbar border-0 px-4 shadow-none align-items-center justify-content-between ${
                myInfo?.locale === localeCode ? "text-primary" : "text-gray-700"
              }`}
              size="lg"
              variant="white"
              onClick={() => setLocale(localeCode as LocaleCodes)}
            >
              {locale}
              {myInfo?.locale === localeCode && (
                <Image
                  className="opacity-75"
                  src={`${process.env.PUBLIC_URL}assets/img/checked.svg`}
                  alt="Checked"
                />
              )}
            </Button>
          ))}
          {loading && (
            <BgLoader className="position-absolute top-0 d-flex align-items-center justify-content-center w-100 h-100">
              <Spinner
                as="span"
                animation="border"
                size="sm"
                role="status"
                aria-hidden="true"
              />
            </BgLoader>
          )}
        </section>
      </CircleIconButtonTooltip>
    </>
  );
};

export default LocaleSelect;
