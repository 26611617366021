import { FC, useState } from "react";
import styled from "styled-components";
import { readFile } from "../../../utils/file-utils";

const FileInputStyled = styled.section`
  position: relative;
  cursor: pointer;
  input[type="file"] {
    position: absolute;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    opacity: 0;
    cursor: pointer;
  }
  input[type="file"]::-webkit-file-upload-button {
    /* chromes and blink button */
    cursor: pointer;
  }
`;

interface FileInputProps
  extends React.DetailedHTMLProps<
    React.InputHTMLAttributes<HTMLInputElement>,
    HTMLInputElement
  > {
  setFileContent: (fileContent: string | ArrayBuffer) => void;
  label?: string;
  asText?: boolean;
}

const FileInput: FC<FileInputProps> = ({
  label,
  setFileContent,
  asText = false,
  ...props
}) => {
  const [fileName, setFileName] = useState("Select a File");

  return (
    <section className="form-group">
      <div className="form-label">{label}</div>
      <FileInputStyled>
        <div className="form-control text-primary cursor-pointer">
          {fileName}
        </div>
        <input
          {...props}
          type="file"
          onChange={async ev => {
            const file = ev.target.files && ev.target.files[0];
            if (file) {
              const fileContent = await readFile(file, asText);
              setFileName(file.name);
              fileContent && setFileContent(fileContent);
            }
          }}
        />
      </FileInputStyled>
    </section>
  );
};

export default FileInput;
