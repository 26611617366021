import { FC, useState } from "react";
import { Link } from "react-router-dom";
import { Nav } from "react-bootstrap";
import { ChevronDown } from "react-feather";
import { Collapse } from "react-collapse";
import { Can } from "../../base";
import { SideMenuItemProps } from "./SideMenuItem";

import SideMenuGroupItemStyles from "./SideMenuGroupItemStyles.module.css";
export interface SideMenuGroupItemProps extends Omit<SideMenuItemProps, "to"> {
  to?: string;
  collapsible?: boolean;
}
interface NavItemProps
  extends Omit<SideMenuGroupItemProps, "permissions" | "userInfo"> {
  collapsible?: boolean;
}

const NavItem: FC<NavItemProps> = ({
  icon,
  title,
  to = "",
  collapsible,
  onClick,
  children,
  ...props
}) => {
  const [open, setOpen] = useState(!collapsible);

  const handleClick = () => {
    if (collapsible) {
      setOpen(!open);
    }
    onClick && onClick();
  };

  return (
    <Nav.Item as="li">
      <Nav.Link as={Link} to={to} {...props} onClick={handleClick}>
        {icon}
        {title}
        {collapsible && (
          <ChevronDown
            className={
              open
                ? SideMenuGroupItemStyles.filterChevronRotate
                : SideMenuGroupItemStyles.filterChevron
            }
            size={12}
          />
        )}
      </Nav.Link>
      <Collapse
        isOpened={open}
        theme={{
          collapse: SideMenuGroupItemStyles.ReactCollapseCollapse,
        }}
        initialStyle={
          open
            ? { height: "auto", overflow: "initial" }
            : { height: "0px", overflow: "hidden" }
        }
      >
        <ul className="nav nav-sm flex-column">{children}</ul>
      </Collapse>
    </Nav.Item>
  );
};

const SideMenuGroupItem: FC<SideMenuGroupItemProps> = ({
  icon,
  title,
  permissions,
  userInfo,
  ...props
}) => {
  return userInfo && permissions ? (
    <Can
      userInfo={userInfo}
      requiredPermissions={permissions}
      yes={() => <NavItem icon={icon} title={title} {...props} />}
      no={() => (
        <Nav.Item as="li">
          <Nav.Link disabled>
            {icon}
            {title}
          </Nav.Link>
        </Nav.Item>
      )}
    />
  ) : (
    <NavItem icon={icon} title={title} {...props} />
  );
};

export default SideMenuGroupItem;
