import AuthClient from "../communication/clients/auth-client";
import { TokensResponse } from "../model/auth";
import { storeSessionTokens } from "./session-persistency";

export const IS_VR_EDIT_MODE_KEY = "VRUserEditState";

export async function signIn(
  username: string,
  password: string
): Promise<void> {
  const serverResponse: TokensResponse | null = await AuthClient.Instance.login(
    username,
    password
  );

  if (serverResponse === null) {
    throw Error("error while getting server response");
  }

  storeSessionTokens(serverResponse);
}

export async function resetPassword(email: string): Promise<boolean> {
  const response = await AuthClient.Instance.submitResetPassword(email);

  if (response !== null && !response.success) {
    throw new Error(`${response.error_message}`);
  }

  return true;
}

export function getIsEditModeLocalStorage(): boolean {
  return localStorage.getItem(IS_VR_EDIT_MODE_KEY) === "true";
}
