import { BaseAuthorizedRequest } from "visible-ui";
import { AxiosResponse } from "axios";
import { Survey } from "../../model/survey";

export default class GetCurrentSurveyRequest extends BaseAuthorizedRequest<
  Survey,
  Survey
> {
  constructor(accessToken: string) {
    super("GET", `/`, undefined, accessToken);
  }

  processResponse(response: AxiosResponse<Survey>): Survey {
    let result = response.data;
    return result;
  }
}
