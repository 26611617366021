export enum NAReasonsCategories {
  waiting_for_client = "waiting for client",
  could_not_analyze_data = "could not analyze data",
  technical_issues = "technical issues",
  can_not_be_collected = "can not be collected",
  not_relevant_for_this_engagement = "not relevant for this engagement",
}

export enum NaReasonEnum {
  collection_is_not_implemented_yet = "collection is not implemented yet",
  missing_questionnaire = "missing questionnaire",
  missing_tool = "missing tool",
  missing_report = "missing report",
  missing_data = "missing data",
  analyzer_failed_to_analyze = "analyzer failed to analyze",
  analyzer_not_implemented = "analyzer not implemented",
  ambiguous_data = "ambiguous data",
  tool_failed_to_collect = "tool failed to collect",
  failed_to_collect_data = "failed to collect data",
  not_applicable_for_scoring = "not applicable for scoring",
}

export const NaReasonConvert: Record<
  NaReasonEnum,
  NaReasonEnum | NAReasonsCategories
> = {
  [NaReasonEnum.collection_is_not_implemented_yet]:
    NAReasonsCategories.can_not_be_collected,
  [NaReasonEnum.missing_questionnaire]: NaReasonEnum.missing_questionnaire,
  [NaReasonEnum.missing_tool]: NaReasonEnum.missing_tool,
  [NaReasonEnum.missing_report]: NaReasonEnum.missing_report,
  [NaReasonEnum.missing_data]: NaReasonEnum.missing_data,
  [NaReasonEnum.analyzer_failed_to_analyze]:
    NaReasonEnum.analyzer_failed_to_analyze,
  [NaReasonEnum.analyzer_not_implemented]:
    NaReasonEnum.analyzer_not_implemented,
  [NaReasonEnum.ambiguous_data]: NaReasonEnum.ambiguous_data,
  [NaReasonEnum.tool_failed_to_collect]: NaReasonEnum.tool_failed_to_collect,
  [NaReasonEnum.failed_to_collect_data]: NaReasonEnum.failed_to_collect_data,
  [NaReasonEnum.not_applicable_for_scoring]:
    NaReasonEnum.not_applicable_for_scoring,
};

export interface NAReason {
  na_category: NAReasonsCategories;
  na_reason: NaReasonEnum;
  na_details?: string;
}

export const missingQuestionnaire: NAReason = {
  na_category: NAReasonsCategories.waiting_for_client,
  na_reason: NaReasonEnum.missing_questionnaire,
};

export const missingTool: NAReason = {
  na_category: NAReasonsCategories.waiting_for_client,
  na_reason: NaReasonEnum.missing_tool,
};

export const missingReport: NAReason = {
  na_category: NAReasonsCategories.waiting_for_client,
  na_reason: NaReasonEnum.missing_report,
};

export const missingData: NAReason = {
  na_category: NAReasonsCategories.waiting_for_client,
  na_reason: NaReasonEnum.missing_data,
};

export const analyzerFailedToAnalyze: NAReason = {
  na_category: NAReasonsCategories.could_not_analyze_data,
  na_reason: NaReasonEnum.analyzer_failed_to_analyze,
};

export const analyzerNotImplemented: NAReason = {
  na_category: NAReasonsCategories.could_not_analyze_data,
  na_reason: NaReasonEnum.analyzer_not_implemented,
};

export const ambiguousData: NAReason = {
  na_category: NAReasonsCategories.could_not_analyze_data,
  na_reason: NaReasonEnum.ambiguous_data,
};

export const toolFailedToCollect: NAReason = {
  na_category: NAReasonsCategories.technical_issues,
  na_reason: NaReasonEnum.tool_failed_to_collect,
};

export const failedToCollectData: NAReason = {
  na_category: NAReasonsCategories.technical_issues,
  na_reason: NaReasonEnum.failed_to_collect_data,
};

export const collectionIsNotImplementedYet: NAReason = {
  na_category: NAReasonsCategories.can_not_be_collected,
  na_reason: NaReasonEnum.collection_is_not_implemented_yet,
};

export const notApplicableForScoring: NAReason = {
  na_category: NAReasonsCategories.not_relevant_for_this_engagement,
  na_reason: NaReasonEnum.not_applicable_for_scoring,
};

export const naReasons: NAReason[] = [
  collectionIsNotImplementedYet,
  missingQuestionnaire,
  missingTool,
  missingReport,
  missingData,
  analyzerFailedToAnalyze,
  analyzerNotImplemented,
  ambiguousData,
  toolFailedToCollect,
  failedToCollectData,
  notApplicableForScoring,
];
