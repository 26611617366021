import { useEffect, useState, FC, useRef } from "react";
import { Button, Card } from "react-bootstrap";
import styled from "styled-components";

const PADDING_TOP_SCREEN_WRAPPER = 60;
const PADDING_INLINE_SCREEN_WRAPPER = 37;
interface PositionTile {
  offsetTop: number;
  offsetLeft: number;
}

const TileExpendedContainer = styled.main<{
  isActive: boolean;
  positionTile: PositionTile | null;
}>`
       height: 100%;
       width: 100%;
       top: 0;
       left: 0;
       z-index: -1;
       transition: .3s .2s;
       > *, &:before  {
        opacity: 0;
        transition: opacity .5s 0s;
      }
      ${p =>
        p.isActive &&
        `
        top: calc((${
          p.positionTile?.offsetTop
        }px * -1) + ${PADDING_TOP_SCREEN_WRAPPER}px);
        left: calc((${
          p.positionTile?.offsetLeft
        }px * -1) + ${PADDING_INLINE_SCREEN_WRAPPER}px);
        transition: .3s 0s;
        width: calc(100vw - ${PADDING_INLINE_SCREEN_WRAPPER * 2}px);
        height: calc(100vh - ${PADDING_TOP_SCREEN_WRAPPER}px);
        // Todo: get all z-index by map
        z-index: 2401;
        > *, &:before  {
          opacity: 1;
          transition: opacity .5s 0.3s;
        }
      `}
      position: absolute;
      overflow-x: hidden;
      ::-webkit-scrollbar {
        display: none;
      }
      display: flex;
      flex-direction: column;
      align-items: center;
      padding: 0 50px;
      border-top-left-radius: 12px;
      border-top-right-radius: 12px;
      background-color: #fff;
      &:before {
        content: "";
        position: absolute;
        top: 0;
        left: 0;
        height: 750px;
        width: 100%;
        background-image: url("${
          process.env.PUBLIC_URL
        }assets/img/tiles/tile-expended-bg-top.svg");
        -webkit-mask-image:-webkit-gradient(linear, left top, left bottom, from(rgba(0,0,0,1)) 75%, to(rgba(0,0,0,0)));
        mask-image: linear-gradient(to bottom, rgba(0,0,0,1) 75%, rgba(0,0,0,0));
        background-attachment: fixed;
        background-position: bottom;
        background-size: cover;
      }
      .card {
        &:first-child {
          border: none;
          padding 43px;
          display: grid;
          grid-template-columns: 1fr 1fr;
          width: 50%;
          height: 150px;
          margin-block-start: 250px;
          margin-block-end: 160px;
        }
        &:not(:first-child) {
          padding 48px;
          width: 100%;
          min-height: 360px;
          margin-block-end: 36px;
          .title-section-tile-expended {
              font-size: 20px;
              font-weight: bold;
              color: #1f2d3d;
          }
        }
        &:last-child {
          margin-block-end: 170px;
        }
        > section {
          height: 100%;
          display: flex;
          align-items: center;
          justify-content: center;
          &:first-child {
            border-inline-end: 2px dotted #00000030;
            > section {
              height: 100%;
              display: flex;
              flex-direction: column;
              align-items: flex-start;
              justify-content: center;
            }
          }
        }
      }
`;
const ScreenWrapper = styled.section<{ isActive: boolean }>`
  position: fixed;
  top: 0;
  left: 0;
  background-color: #000000aa;
  opacity: 0;
  z-index: -1;
  width: 100%;
  height: 100%;
  padding: 0 37px;
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  transition: opacity 0.3s 0s, z-index 0s 0.5s;

  > button {
    margin: 10px 0;
    width: 87px;
    font-size: 13px;
    background-color: #fff;
    border: none;
  }
  ${p =>
    p.isActive &&
    `
      transition: opacity 0.3s 0.2s, z-index 0s 0s;
      z-index: 1039;
      opacity: 1;
      `}
`;

export interface TileSection {
  title: string;
}

export interface TileExpendedProps {
  isActive: boolean;
  mainTitle?: string;
  sections?: [];
  closeTile: () => void;
}

const TileExpended: FC<TileExpendedProps> = ({
  isActive = false,
  // Todo: remove default and get it from parent
  mainTitle = "Loss Exposure",
  sections = [
    { title: "section 1" },
    { title: "section 2" },
    { title: "section 3" },
  ],
  closeTile,
}) => {
  const mainTileRef = useRef<HTMLElement | null>(null);
  const [positionTile, setPositionTile] = useState<PositionTile | null>(null);

  useEffect(() => {
    if (mainTileRef.current) {
      isActive && mainTileRef.current.scrollTo({ top: 0, behavior: "smooth" });
      const { parentElement, offsetParent } = mainTileRef.current;

      if (parentElement && offsetParent?.parentElement) {
        setPositionTile({
          offsetTop:
            parentElement.offsetTop +
            offsetParent.parentElement.offsetTop -
            window.scrollY,
          offsetLeft:
            parentElement.offsetLeft + offsetParent.parentElement.offsetLeft,
        });
      }
    }
  }, [isActive]);

  return (
    <>
      <ScreenWrapper
        onClick={ev => {
          ev.stopPropagation();
          closeTile();
        }}
        isActive={isActive}
      >
        <Button variant="outline-primary">Close</Button>
      </ScreenWrapper>
      <TileExpendedContainer
        isActive={isActive}
        positionTile={positionTile}
        // style={{
        //   [OFFSET_TOP]: `calc((${positionTile?.offsetTop}px * -1) + ${PADDING_TOP_SCREEN_WRAPPER}px)`,
        //   [OFFSET_LEFT]: `calc((${positionTile?.offsetLeft}px * -1) + ${PADDING_INLINE_SCREEN_WRAPPER}px)`,
        // }}
        ref={mainTileRef}
      >
        <Card>
          <section>
            <section>
              <span className="fs-5 text-gray-700">Live result</span>
              <span className="fs-2">{mainTitle}</span>
            </section>
          </section>
          <section>
            <span>60.4M</span>
          </section>
        </Card>
        {sections.map((sectionTile, i) => {
          return (
            <Card key={"section-tile-" + i}>
              <span className="title-section-tile-expended">
                {sectionTile.title}
              </span>
            </Card>
          );
        })}
      </TileExpendedContainer>
    </>
  );
};

export default TileExpended;
