import { forwardRef, RefObject, useCallback, useMemo, useState } from "react";
import { useRecoilState, useRecoilValue } from "recoil";
import { debounce, flatten } from "lodash";
import { useTranslation } from "react-i18next";
import { Form } from "react-bootstrap";
import { Collapse } from "react-collapse";

import {
  DEFAULT_FORMAT_LOCALE,
  GenericResponse,
  NumberParser,
  toastError,
} from "visible-ui";

import { surveyAnswersMap } from "../../../state/atoms/survey-responses-atoms";
import SurveyResponsesClient from "../../../communication/clients/survey-client";
import SurveyCantAnswerQuestion from "../SurveyCantAnswerQuestion";
import {
  SurveyAnswer,
  SurveyQuestion,
  QuestionDependencyStatus,
  AnswerStatus,
} from "../../../model/survey";
import {
  getQuestionDependencyStatus,
  questionTextWithPrefix,
  isFreeTextResponse,
  isQuestionAnswered,
} from "../survey-utils";
import SurveyResponseQuestionElement from "./SurveyResponseQuestionElement";
import SurveyResponseQuestionCard from "./SurveyResponseQuestionCard";
import SurveyResponseQuestionController from "./SurveyResponseQuestionController";
import { currentCurrencySelectorFamily } from "../../../state/selectors/survey-selector";
import { useAuth } from "../../../hooks/auth-hooks";

interface SurveyResponseQuestionProps {
  question: SurveyQuestion;
  flatQuestions: SurveyQuestion[];
  cardRef?: RefObject<HTMLDivElement>;
}

const SurveyResponseQuestion = forwardRef<any, SurveyResponseQuestionProps>(
  ({ question, flatQuestions, cardRef }, ref) => {
    const { t } = useTranslation();

    const [{ answersMap }, setAnswersMap] = useRecoilState(surveyAnswersMap);
    const { status: questionDependencyStatus, questionDependentOn } =
      getQuestionDependencyStatus(question, flatQuestions, answersMap);
    const disabled =
      questionDependencyStatus === QuestionDependencyStatus.DISABLED ||
      questionDependencyStatus === QuestionDependencyStatus.IRRELEVANT;
    const isQuestionAnsweredValue = isQuestionAnswered(
      answersMap.get(question.id)
    );

    const currentAnswer = answersMap.get(question.id);

    const [answerStatus, setAnswerStatus] = useState<AnswerStatus>(
      currentAnswer?.will_not_answer
        ? AnswerStatus.CantAnswer
        : currentAnswer?.value && currentAnswer?.value.length > 0
        ? AnswerStatus.Answered
        : AnswerStatus.Empty
    );
    const [freeTextAnswer, setFreeTextAnswer] = useState(currentAnswer?.value);
    const [isSaving, setIsSaving] = useState(false);

    // save previous checked ids to be able to revert if save fails
    const [prevCheckedIds, setPrevCheckedIds] = useState<
      Array<string | undefined>
    >(currentAnswer?.possible_answer_ids || []);

    const [checkedIds, setCheckedIds] = useState<Array<string | undefined>>(
      currentAnswer?.possible_answer_ids || []
    );
    const currency = useRecoilValue(currentCurrencySelectorFamily(question.id));

    const { getAccessToken, user } = useAuth();

    const changeAnswerableStatus = useCallback(
      async (answer: SurveyAnswer): Promise<GenericResponse | null> => {
        try {
          const accessToken = await getAccessToken();
          return await SurveyResponsesClient.Instance.changeAnswerableStatus(
            answer,
            accessToken
          );
        } catch (error) {
          console.error(error);
          return null;
        }
      },
      []
    );

    const submitAnswer = useCallback(
      async (answer: SurveyAnswer): Promise<GenericResponse | null> => {
        try {
          const accessToken = await getAccessToken();
          return await SurveyResponsesClient.Instance.saveAnswer(
            answer,
            accessToken
          );
        } catch (error) {
          console.error(error);
          return null;
        }
      },
      []
    );

    const clearAnswer = useCallback(
      (questionId: string) => {
        answersMap.delete(questionId);
        setAnswersMap({
          answersMap: new Map(answersMap),
          lastUpdate: new Date(),
          lastUpdater: user?.name,
        });
        setFreeTextAnswer([""]);
        setAnswerStatus(AnswerStatus.Empty);
        setCheckedIds([]);
      },
      [answersMap, setAnswersMap]
    );

    const handleSubmitQuestion = useCallback(
      async (
        handler: (answer: SurveyAnswer) => Promise<GenericResponse | null>,
        answer: Partial<SurveyAnswer>,
        selectedOptionsIds: Array<string | undefined>,
        newStatus: AnswerStatus
      ) => {
        const answerValue = isFreeTextResponse(question, answer)
          ? answer.value || []
          : (flatten(
              selectedOptionsIds
                .map(
                  x => question.possible_answers.find(y => y.id === x)?.value
                )
                .filter(x => x !== undefined)
            ) as Array<string>);

        const answerToSubmit: SurveyAnswer = {
          possible_answer_ids: selectedOptionsIds as Array<string>,
          value: answerValue,
          question_id: question.id,
          question_version: question.version,
          will_not_answer: answer.reason_to_not_answer ? true : false,
          reason_to_not_answer: answer.reason_to_not_answer,
          is_other: answer.is_other,
          answered_by: user?.name,
          currency_code: answer.currency_code,
        };

        setIsSaving(true);
        const res = await handler(answerToSubmit);
        setIsSaving(false);

        if (res?.success) {
          if (answer.value || answer.reason_to_not_answer) {
            setAnswersMap({
              answersMap: new Map(answersMap.set(question.id, answerToSubmit)),
              lastUpdate: new Date(),
              lastUpdater: user?.name,
            });
            setAnswerStatus(newStatus);
          } else {
            clearAnswer(question.id);
          }
          // set prev checked ids to current checked ids
          setPrevCheckedIds(checkedIds);
        } else {
          // revert to previous checked ids
          setCheckedIds(prevCheckedIds);
          toastError(res?.error_message || t("errorMessage.saveAnswer"));
        }
      },
      [
        answersMap,
        clearAnswer,
        question,
        setAnswersMap,
        undefined /* FIXME get user info */,
      ]
    );

    const handleSubmitFreeTextQuestion = useCallback(
      (newValue: string[]) => {
        setCheckedIds([]);
        handleSubmitQuestion(
          submitAnswer,
          {
            id: currentAnswer?.id,
            value: newValue[0].trim() ? newValue : undefined,
            currency_code: currency,
          },
          [],
          newValue[0].trim() ? AnswerStatus.Answered : AnswerStatus.Empty
        );
      },
      [currentAnswer?.id, handleSubmitQuestion]
    );

    const handleSubmitCantAnswerResponse = useCallback(
      async (newValue: string) => {
        await handleSubmitQuestion(
          changeAnswerableStatus,
          {
            id: currentAnswer?.id,
            will_not_answer: true,
            reason_to_not_answer: newValue,
          },
          checkedIds,
          AnswerStatus.CantAnswer
        );
      },
      [currentAnswer?.id, checkedIds, handleSubmitQuestion]
    );

    const debouncedSubmitFreeTextQuestion = useMemo(() => debounce(newValue => handleSubmitFreeTextQuestion(newValue), 2000),
     [handleSubmitFreeTextQuestion]);

    const onFreeTextChange = (newValue: string, isInvalid?: boolean) => {
      const parsedInput = new NumberParser(DEFAULT_FORMAT_LOCALE).parse(
        newValue
      );
      setAnswerStatus(AnswerStatus.Empty);
      setFreeTextAnswer([newValue]);
      if (!isInvalid) {
        // eslint-disable-next-line use-isnan
        if (Number.isNaN(parsedInput)) {
          debouncedSubmitFreeTextQuestion([newValue]);
        } else {
          debouncedSubmitFreeTextQuestion([parsedInput.toString()]);
        }
      }
    };

    return (
      <div ref={ref}>
        <SurveyResponseQuestionCard
          disabled={disabled}
          questionDependencyStatus={questionDependencyStatus}
          cardRef={cardRef}
          isQuestionAnsweredValue={isQuestionAnsweredValue}
          questionDependentOn={questionDependentOn}
        >
          <div className="fw-bold pre-wrap">
            {questionTextWithPrefix(question)}
          </div>
          {!!question.inner_note && (
            <Form.Text className="mt-1">{question.inner_note}</Form.Text>
          )}
          <section className="pt-4 pb-3">
            <SurveyResponseQuestionElement
              question={question}
              freeTextAnswer={freeTextAnswer}
              onFreeTextChange={onFreeTextChange}
              handleSubmitQuestion={handleSubmitQuestion}
              submitAnswer={submitAnswer}
              currentAnswer={currentAnswer}
              disabled={disabled}
              checkedIds={checkedIds}
              setCheckedIds={setCheckedIds}
            />
            {disabled || (
              <section
                data-testid="question-controller-and-cant-answer-container"
                className="d-flex flex-column gap-4"
              >
                <Collapse isOpened>
                  {answerStatus === AnswerStatus.CantAnswer && (
                    <SurveyCantAnswerQuestion
                      questionId={question.id}
                      handleSubmitCantAnswerResponse={
                        handleSubmitCantAnswerResponse
                      }
                      reasonToNotAnswer={currentAnswer?.reason_to_not_answer}
                    />
                  )}
                </Collapse>
                <SurveyResponseQuestionController
                  answerStatus={answerStatus}
                  handleSubmitQuestion={handleSubmitQuestion}
                  isSaving={isSaving}
                  question={question}
                  setAnswerStatus={setAnswerStatus}
                  setCheckedIds={setCheckedIds}
                  submitAnswer={submitAnswer}
                />
              </section>
            )}
          </section>
        </SurveyResponseQuestionCard>
      </div>
    );
  }
);

export default SurveyResponseQuestion;
