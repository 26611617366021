import { BaseAuthorizedRequest } from "visible-ui";
import { AxiosResponse } from "axios";
import { SurveyUser } from "../../model/user";
import { GenericResponse } from "visible-ui";

export default class PutShareSurvey extends BaseAuthorizedRequest<
  GenericResponse,
  GenericResponse
> {
  constructor(accessToken: string, user: SurveyUser) {
    super(
      "PUT",
      `/share/`,
      {
        payload: user,
      },
      accessToken
    );
  }

  processResponse({ data }: AxiosResponse<GenericResponse>): GenericResponse {
    return data;
  }
}
