import { FC } from "react";
import { useTranslation } from "react-i18next";
import { ReactSVG } from "react-svg";
import styled from "styled-components";
import { MaxWidthSpan } from "visible-ui";

const LayoutStyled = styled.section`
  min-height: 75vh;
`;

export interface SurveySubmittedOrEndedScreenProps {
  mode: "submitted" | "ended";
}

const SurveySubmittedOrEndedScreen: FC<SurveySubmittedOrEndedScreenProps> = ({
  mode,
}) => {
  const { t } = useTranslation();
  return (
    <LayoutStyled className="d-flex flex-column align-items-center justify-content-center">
      {mode === "submitted" && (
        <section className="rounded-circle p-5 bg-white text-primary mb-5">
          <ReactSVG
            className="p-2"
            src={process.env.PUBLIC_URL + "assets/img/email-mail-post-card.svg"}
          />
        </section>
      )}
      <div className="fs-1 mb-3">
        {mode === "submitted"
          ? t("submitSurvey.successfullySubmitted")
          : t("submitSurvey.theSurveyHasEnded")}
      </div>
      <MaxWidthSpan
        maxWidth="350px"
        className="fs-2 text-center text-gray-700 fw-lighter"
      >
        {mode === "submitted" ? (
          t("submitSurvey.thankForTakingTime")
        ) : (
          <>
            {t("submitSurvey.thankAndHope")}
            <br />
            {t("layout.moodysInvestorsService")}
          </>
        )}
      </MaxWidthSpan>
    </LayoutStyled>
  );
};

export default SurveySubmittedOrEndedScreen;
