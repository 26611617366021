import { FC } from "react";
import { Container, Row, Col } from "react-bootstrap";
import styled from "styled-components";

import { BaseComponentProps } from "visible-ui";
import { useAuth } from "../../hooks/auth-hooks";

const HeaderStyled = styled.h1`
  font-size: 24px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: -0.24px;
  text-align: center;
  color: var(--dark-blue);
`;

const SubHeaderStyled = styled.span`
  font-size: 20px;
  font-weight: 300;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.4;
  letter-spacing: -0.2px;
  text-align: center;
  color: var(--grey-7);
`;

export interface ErrorPageProps extends BaseComponentProps {
  logoutCallback?: () => void;
}

const ErrorPage: FC<ErrorPageProps> = ({ style, className = "" }) => {
  const { logout } = useAuth();
  return (
    <Container
      className={`d-flex justify-content-center align-items-center text-center ${className}`}
      style={style}
    >
      <Row>
        <Col>
          <HeaderStyled>We encountered an unexpected error.</HeaderStyled>
          <SubHeaderStyled>
            {"If the problem persists, please "}
            <a href={`mailto:${process.env.REACT_APP_CUSTOMER_SUPPORT_EMAIL}`}>
              {"contact our support"}
            </a>
            {" or "}
            {/* eslint-disable-next-line jsx-a11y/anchor-is-valid */}
            <a href="#" className="p-0 mb-1" onClick={logout}>
              logout
            </a>
          </SubHeaderStyled>
        </Col>
      </Row>
    </Container>
  );
};

export default ErrorPage;
