import BaseClient from "./base-client";
import LoginRequest from "../auth/login-request";
import SubmitResetPasswordRequest from "../auth/submit-reset-password-request";
import SendOtpRequest from "../auth/send-otp-request";
import SubmitOtpRequest from "../auth/submit-otp-request";
import RefreshOtpTokenRequest from "../auth/refresh-otp-token-request";
import { Credentials, Tokens, TokensResponse } from "../../model/auth";
import { GenericResponse } from "../../model/base";

export enum AuthMethodEnum {
  Email = "email",
  Phone = "sms",
}

export default class AuthClient extends BaseClient {
  protected static instance: AuthClient;

  protected constructor() {
    super("/api/v1/users");
  }

  public static get Instance(): AuthClient {
    return this.instance || (this.instance = new AuthClient());
  }

  public async login(
    username: string,
    password: string
  ): Promise<TokensResponse | null> {
    const credentials: Credentials = { username, password };
    return this.doRequest(new LoginRequest(credentials));
  }

  public async refreshTokens(tokens: Tokens): Promise<TokensResponse | null> {
    return this.doRequest(new RefreshOtpTokenRequest(tokens));
  }

  public async sendOtp(
    userIdentifier: string,
    authMethod: AuthMethodEnum
  ): Promise<GenericResponse | null> {
    return this.doRequest(new SendOtpRequest(userIdentifier, authMethod));
  }

  public async submitOtp(
    userIdentifier: string,
    otp: string,
    authMethod: AuthMethodEnum
  ): Promise<TokensResponse | null> {
    return this.doRequest(
      new SubmitOtpRequest(userIdentifier, otp, authMethod)
    );
  }

  public async submitResetPassword(
    email: string
  ): Promise<GenericResponse | null> {
    return this.doRequest(new SubmitResetPasswordRequest(email));
  }
}
