import { Organization, OrganizationStatus } from "./organization";

export interface EngagementsStatusMap {
  organizationStatus: OrganizationStatus;
  engagements: Engagement[];
}

export enum ReportDownloadStatus {
  Collecting = "collecting",
  Validating = "validating",
  FinalReport = "final_report",
}

export const ReportDownloadStatusClientPortalName: Record<
  ReportDownloadStatus,
  string
> = {
  [ReportDownloadStatus.Collecting]: "",
  [ReportDownloadStatus.Validating]: "Draft Rating Analysis",
  [ReportDownloadStatus.FinalReport]: "Full Rating Analysis",
};
export interface Engagement {
  engagement_id: string;
  organization: Organization;
  start_date: string;
  active: boolean;
  manager_id: string;
  report_download_status: ReportDownloadStatus;
}

export interface EngagementManager {
  id: string;
  name: string;
  location: string;
  email: string;
  image_url: string;
}
