export enum UserPermissions {
  login_to_dashboard = "client:dashboard",
  questionnaires = "client:questionnaires",
  knowledgebase = "client:knowledgebase",
  tools = "client:tools",
  admin = "client:admin",
  champion = "client:champion",
  files_upload = "client:files_upload",
  files_download = "client:files_download",
  questionnaire_templates = "ah:questionnaires",
  knowledgebase_repo = "ah:knowledgebase",
  tools_management = "ah:tools_management",
  playbook_editing = "ah:engagements",
}

const restricted: string[] = [];
const viewer = [UserPermissions.login_to_dashboard];
const tools = [
  ...viewer,
  UserPermissions.knowledgebase,
  UserPermissions.files_download,
  UserPermissions.files_upload,
  UserPermissions.tools,
];
const questionnaires = [...viewer, UserPermissions.questionnaires];
const contributor = [...tools, UserPermissions.questionnaires];
const admin = [...contributor, UserPermissions.admin];
const champion = [...admin, UserPermissions.champion];

export enum RoleModel {
  admin = "clientAdmin",
  champion = "clientChampion",
  questionnaires_contributor = "clientQuestionnaires",
  tools_contributor = "clientTools",
  all_contributor = "clientContributor",
  restricted = "clientRestricted",
  vr_admin = "vrAdmin",
}

export const roles = {
  clientChampion: champion,
  clientAdmin: admin,
  clientContributor: contributor,
  clientQuestionnaires: questionnaires,
  clientTools: tools,
  clientDashboard: restricted,
  clientRestricted: restricted,
};
