import { Dispatch, FC, SetStateAction, useEffect, useState } from "react";
import {
  getQuestionPermissionsAsString,
  ShortTxtWithTooltip,
  UserListLoader,
  UserRow,
} from "visible-ui";
import { useRecoilValue, useRecoilValueLoadable } from "recoil";
import { flatQuestionsSurveySelector } from "../../state/selectors/survey-selector";
import { allOrganizationUsersWithoutMeSelector } from "../../state/selectors/survey-users-selectors";
import { useTranslation } from "react-i18next";
import DeleteSubPermissionsSurveyUser from "./DeleteSubPermissionsSurveyUser";
import { SurveyUser } from "../../model/user";
import { Button } from "react-bootstrap";
import { Edit2 } from "react-feather";

interface ShareSurveyUserListProps {
  setCurrentUser: Dispatch<SetStateAction<SurveyUser | null>>;
}

const ShareSurveyUserList: FC<ShareSurveyUserListProps> = ({
  setCurrentUser,
}) => {
  const { t } = useTranslation();
  const noneYetTxt = t("shareSurvey.noneYet");
  const usersLoadable = useRecoilValueLoadable(
    allOrganizationUsersWithoutMeSelector
  );

  const [users, setUsers] = useState<SurveyUser[]>([]);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    setLoading(usersLoadable.state === "loading");
    if (usersLoadable.state === "hasValue") {
      setUsers(usersLoadable.getValue() || []);
    }
  }, [usersLoadable]);

  const flatQuestions = useRecoilValue(flatQuestionsSurveySelector);

  return loading ? (
    <section className="py-3">
      <UserListLoader animation noneYetTxt={noneYetTxt} />
    </section>
  ) : users.length ? (
    <>
      {users.map((user, idx) => {
        return (
          <UserRow
            key={`user-row-${user.id}-${idx}`}
            user={{ ...user, disabled: false, full_name: user.name }}
            idx={idx}
            role={
              user.sub_permissions?.length ? (
                <ShortTxtWithTooltip
                  maxWidth="164px"
                  txt={`${t("shareSurvey.numberQuestions", {
                    number: user.sub_permissions.length,
                  })}: ${getQuestionPermissionsAsString(
                    flatQuestions,
                    user.sub_permissions
                  )}`}
                />
              ) : (
                <>{t("shareSurvey.allQuestions")}</>
              )
            }
            moreOptions={
              <section className="d-flex align-items-center gap-3">
                <Button
                  variant="link"
                  className="d-flex align-items-center gap-2"
                  onClick={() => setCurrentUser({ ...user })}
                >
                  <Edit2 size={15} /> {t("shareSurvey.edit")}
                </Button>
                <DeleteSubPermissionsSurveyUser user={user} />
              </section>
            }
          />
        );
      })}
    </>
  ) : (
    <section className="py-3">
      <UserListLoader noneYetTxt={noneYetTxt} />
    </section>
  );
};

export default ShareSurveyUserList;
