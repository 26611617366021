import { FC } from "react";
import { useNavigate } from "react-router-dom";
import BaseComponentProps from "../../../base-component-props";

interface PrivacyPolicyLinkProps extends BaseComponentProps {
  privacyPolicyTxt?: string;
}

const PrivacyPolicyLink: FC<PrivacyPolicyLinkProps> = ({
  className,
  privacyPolicyTxt = "Privacy Policy",
}) => {
  const navigate = useNavigate();
  return (
    <span
      onClick={() => navigate("/privacy")}
      className={`cursor-pointer ${className}`}
    >
      {privacyPolicyTxt}
    </span>
  );
};

export default PrivacyPolicyLink;
