import { FC } from "react";
import { Container, Row, Col, Image, Button } from "react-bootstrap";
import BaseComponentProps from "../../../base-component-props";
import { eventEmitter } from "../../../utils/event-emitter";

export interface ErrorPageProps extends BaseComponentProps {
  errorMsg: string;
  imageUrl: string;
  hideFooter?: boolean;
  logoutCallback?: () => void;
}

const ErrorPage: FC<ErrorPageProps> = ({
  errorMsg,
  imageUrl,
  hideFooter,
  style,
  className = "",
  logoutCallback = () => eventEmitter.emit("REFRESH_TOKEN_EXPIRED"),
}) => {
  return (
    <Container
      className={`d-flex justify-content-center align-items-center text-center ${className}`}
      style={style}
    >
      <Row>
        <Col>
          <Image src={imageUrl} className="mb-6" />
          <h1>{errorMsg}</h1>
          {!hideFooter && (
            <span>
              {"You can return to our "}
              <a href="/">homepage.</a>
              {" If the problem persists, please "}
              <a
                href={`mailto:${process.env.REACT_APP_CUSTOMER_SUPPORT_EMAIL}`}
              >
                {"contact your client relationship manager "}
              </a>
              {"or try to "}
              <Button variant="link" className="p-1" onClick={logoutCallback}>
                logout
              </Button>
            </span>
          )}
        </Col>
      </Row>
    </Container>
  );
};

export default ErrorPage;
