import { EngagementTaskType } from "./engagement-task";
import { ReportDownloadStatus } from "./engagement";
import { RoleModel, UserPermissions } from "./permissions";
import { CommonQuestionnaire, CommonQuestion } from "./questionnaire";
import { Organization } from "./organization";

export interface Tokens {
  access_token: string | null;
  id_token: string | null;
  refresh_token: string | null;
  refresh_rate?: string;
}

export interface TokensResponse {
  tokens: Tokens;
  creation_date: string;
}

export interface Credentials {
  username: string;
  password?: string;
  newPassword?: string;
  verificationCode?: string;
}

export enum UserTutorialStatus {
  Unseen = "unseen",
  Visited = "visited",
  Completed = "completed",
}

export interface UserMetadata {
  tutorials: {
    cp1_dashboard_banner: UserTutorialStatus;
    cp2_dashboard_banner: UserTutorialStatus;
  };
}

export interface BaseUser {
  id?: string;
  organization_id: string;
  organization_name: string;
  email: string;
}

export interface User extends BaseUser {
  auth0_id?: string;
  full_name: string;
  handler: string;
  disabled: boolean;
  permissions?: Array<UserPermissions | string>;
  sub_permissions_tree?: Record<
    EngagementTaskType | string,
    Record<string, string[]>
  >;
  super_mode?: boolean;
  roles?: RoleModel[];
  phone?: string;
  report_download_status?: ReportDownloadStatus;
  metadata: UserMetadata;
  possible_organizations?: Organization[];
}

export interface ResourcePermissionRequest {
  resource_id: string; // UUID
  resource_type: EngagementTaskType | string;
  sub_permissions?: string[]; // Array of UUID
  personal_message?: string;
  send_email?: boolean;
  resource_name?: string;
  resource_link?: string;
}

export interface ShareResourcesRequest {
  resources: ResourcePermissionRequest[];
  users: User[];
}

export type UseQuestionnaireByResponseId<T extends BaseUser = User> = (
  responseId: string,
  userId?: string
) => {
  loading: boolean;
  questionnaire: CommonQuestionnaire<T> | null;
  flatQuestions: CommonQuestion<T>[];
};

export interface HookShareTaskMap<T extends BaseUser = User> {
  [EngagementTaskType.questionnaire]: UseQuestionnaireByResponseId<T>;
}
