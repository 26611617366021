import { BaseAuthorizedRequest } from "visible-ui";
import { AxiosResponse } from "axios";
import { SurveyUser } from "../../model/user";

export default class GetMyInfoRequest extends BaseAuthorizedRequest<
  SurveyUser,
  SurveyUser
> {
  constructor(accessToken: string) {
    super("GET", `/`, undefined, accessToken);
  }

  processResponse(
    {data}: AxiosResponse<SurveyUser>
  ): SurveyUser {
    return data;
  }
}
