import { Fragment } from "react";
import { Download } from "react-feather";
import { AssetFilesClient } from "../../communication";
import { BucketType } from "../../model/static-assets";

export enum DownloadClassNames {
  DOWNLOAD_WITH_SVG= "download_file_svg",
  DOWNLOAD_FILE= "download_file",
  BOLD_DOWNLOAD_WITH_SVG= "bold_download_file_svg",
  BOLD_DOWNLOAD_FILE= "bold_download_file",
  SMALL_DOWNLOAD_FILE= "small_download_file",
};

const createDownloadLink = (
  element: any,
  bold?: boolean,
  showSvg?: boolean,
  small?: boolean
) => {
  const text = element.attribs.id.includes(":")
    ? element.attribs.id.split(":")[0]
    : element.attribs.id;

  const fileName = element.attribs.id.includes(":")
    ? element.attribs.id.split(":")[1]
    : element.attribs.id;

  return (
    <Fragment>
      <p
        className={`text-primary cursor-pointer d-inline-flex mb-0 ${
          bold ? "fw-bold" : ""
        } ${small ? "small" : ""}`}
        onClick={async () => {
          const link =
            await AssetFilesClient.Instance.generatePreSignedDownloadLink(
              [fileName],
              BucketType.resources
            );
          if (link) {
            window.open(link[0].response_url || "", "_blank");
          }
        }}
      >
        {text}&thinsp;
      </p>
      {showSvg && (
        <Download
          className="text-primary cursor-pointer mb-1"
          size={16}
          strokeWidth={2.2}
        />
      )}
    </Fragment>
  );
};

export const injectionsMap = {
  bold_download_file_svg: (element: Element): JSX.Element => {
    return createDownloadLink(element, true, true);
  },
  bold_download_file: (element: Element): JSX.Element => {
    return createDownloadLink(element, true);
  },
  download_file_svg: (element: Element): JSX.Element => {
    return createDownloadLink(element, false, true);
  },
  download_file: (element: Element): JSX.Element => {
    return createDownloadLink(element);
  },

  small_download_file: (element: Element): JSX.Element => {
    return createDownloadLink(element, false, false, true);
  },
};
