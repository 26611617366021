export enum OrganizationStatus {
  Active = "active",
  Archived = "archived",
  Demo = "demo",
}

export interface Organization {
  id: string;
  organization_name: string;
  domain: string;
  status: OrganizationStatus;
  timezone: string;
  is_spm: boolean;
  bitsight_entity_id: string;
}
