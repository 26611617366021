import translationEN from "../locales/en/default.json";
import translationAR from "../locales/ar/default.json";
import translationES from "../locales/es/default.json";
import translationZH from "../locales/zh/default.json";
import translationFR from "../locales/fr/default.json";
import translationJA from "../locales/ja/default.json";
import translationKO from "../locales/ko/default.json";
import translationPT from "../locales/pt/default.json";
import { LocaleCodes } from "../model/locale";

// the translations
// (tip move them in a JSON file and import them,
// or even better, manage them separated from your code: https://react.i18next.com/guides/multiple-translation-files)
const resources: Record<
  LocaleCodes,
  Record<"translation", typeof translationEN>
> = {
  en: {
    translation: translationEN,
  },
  ar: {
    translation: translationAR,
  },
  es: {
    translation: translationES,
  },
  zh: {
    translation: translationZH,
  },
  fr: {
    translation: translationFR,
  },
  ja: {
    translation: translationJA,
  },
  ko: {
    translation: translationKO,
  },
  pt: {
    translation: translationPT,
  },
};

export default resources;
