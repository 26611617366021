import { TaskDefinition } from "./task-definition";
import { TaskExecution } from "./task-execution";

export enum TaskFamilies {
  Collector = "collector",
  Analyzer = "analyzer",
  Workflow = "workflow",
  ArtifactsIndexer = "artifacts_indexer",
  ArtifactsETL = "artifacts_etl",
}

export enum DatabricksJobTypes {
  ArtifactsETL = "artifacts_etl",
}

export enum CollectorTypes {
  AwsCollector = "AWS_COLLECTOR",
  AwsCiCollector = "AWS_COLLECTOR",
  EmailCollector = "OFFICE_365_EMAIL_TEST",
  CrowdStrikeCollector = "CROWDSTRIKE_COLLECTOR",
  OracleCollector = "ORACLE_COLLECTOR",
  LinuxCollector = "LINUX_COLLECTOR",
  ManualLinuxCollector = "MANUAL_LINUX_COLLECTOR",
  ActiveDirectoryCollector = "AD_COLLECTOR",
  ExFilCollector = "EXFIL_COLLECTOR",
  VirtualizationCollector = "VIRTUALIZATION_COLLECTOR",
  WindowsCollector = "WINDOWS_COLLECTOR",
  OktaCollector = "OKTA_COLLECTOR",
  SplunkCollector = "SPLUNK_COLLECTOR",
  AzureCollector = "AZURE_COLLECTOR",
  SccmBaselineReport = "SCCM_BASELINE_REPORT",
}

export enum TaskGroups {
  Cloud = "cloud_security",
  Email = "email_security",
  DataBase = "database_security",
  EDR = "edr",
  SIEM = "siem",
  Linux = "linux",
  Virtualization = "virtualization",
  Exfil = "exfil",
  Windows = "windows",
  OnPremIDP = "OnPremIDP",
  CloudIDP = "CloudIDP",
  Sccm = "Sccm",
}

export enum StatusIndicatorType {
  Loading = "loading",
  Configured = "configured",
  PreConfig = "pre-config",
  Failed = "failed",
  Running = "running",
  jobDeployed = "job-deployed",
  onHold = "on-hold",
}

export enum TaskStatus {
  READY = "READY",
  JOB_DEPLOYED = "JOB_DEPLOYED",
  RUNNING = "RUNNING",
  SUCCESS = "SUCCESS",
  FAILED = "FAILED",
  TIMEOUT = "TIMEOUT",
  FAILED_APPLICATION = "FAILED_APPLICATION",
  FAILED_PROCESS = "FAILED_PROCESS",

}

export enum TaskUpdateMessageTypes {
  TaskExecutionCreated = "task_executions_created",
  TaskExecutionUpdated = "task_executions_updated",
}

// Interface & Type
export interface TaskDefinitionExecutionBaseModel {
  _id: string;
  client_id: string | undefined;
  family: TaskFamilies;
  task_type: CollectorTypes;
}

export interface TaskUpdateMessageWrapper {
  task_definition_id: string;
  message_type: TaskUpdateMessageTypes;
  message:
    | TaskExecution
    | TaskDefinition
    | Array<TaskDefinition>
    | Array<TaskExecution>;
}

export const BASE_URL_TASK_DEFINITIONS = "/api/v1/task_definitions";
export const BASE_URL_TOOLS_MANAGEMENT = "/api/v1/tools_management";

export const CollectorsMap: Record<TaskGroups, CollectorTypes[]> = {
  [TaskGroups.Cloud]: [
    CollectorTypes.AwsCollector,
    CollectorTypes.AwsCiCollector,
    CollectorTypes.AzureCollector,
  ],
  [TaskGroups.Email]: [CollectorTypes.EmailCollector],
  [TaskGroups.EDR]: [CollectorTypes.CrowdStrikeCollector],
  [TaskGroups.SIEM]: [CollectorTypes.SplunkCollector],
  [TaskGroups.DataBase]: [CollectorTypes.OracleCollector],
  [TaskGroups.Linux]: [CollectorTypes.LinuxCollector],
  [TaskGroups.Virtualization]: [CollectorTypes.VirtualizationCollector],
  [TaskGroups.Exfil]: [CollectorTypes.ExFilCollector],
  [TaskGroups.Windows]: [CollectorTypes.WindowsCollector],
  [TaskGroups.OnPremIDP]: [CollectorTypes.ActiveDirectoryCollector],
  [TaskGroups.CloudIDP]: [CollectorTypes.OktaCollector],
  [TaskGroups.Sccm]: [CollectorTypes.SccmBaselineReport],
};

export const TASK_FAMILIES_MAP: Record<TaskFamilies, CollectorTypes[]> = {
  [TaskFamilies.Collector]: [
    CollectorTypes.AwsCollector,
    CollectorTypes.EmailCollector,
    CollectorTypes.CrowdStrikeCollector,
    CollectorTypes.SplunkCollector,
    CollectorTypes.OktaCollector,
    CollectorTypes.OracleCollector,
    CollectorTypes.LinuxCollector,
    CollectorTypes.VirtualizationCollector,
    CollectorTypes.ExFilCollector,
    CollectorTypes.WindowsCollector,
    CollectorTypes.ActiveDirectoryCollector,
    CollectorTypes.SccmBaselineReport,
  ],
  [TaskFamilies.Analyzer]: [],
  [TaskFamilies.ArtifactsIndexer]: [],
  [TaskFamilies.ArtifactsETL]: [],
  [TaskFamilies.Workflow]: [CollectorTypes.AwsCiCollector],
};

export const COLLECTOR_GROUP_HUMANIZE: Record<TaskGroups, string> = {
  [TaskGroups.Cloud]: "Cloud Security",
  [TaskGroups.Email]: "Email Security",
  [TaskGroups.EDR]: "EDR",
  [TaskGroups.DataBase]: "Database Security",
  [TaskGroups.Linux]: "Linux",
  [TaskGroups.Virtualization]: "Virtualization",
  [TaskGroups.Exfil]: "Exfiltration",
  [TaskGroups.Windows]: "Windows",
  [TaskGroups.OnPremIDP]: "Identity",
  [TaskGroups.SIEM]: "SIEM",
  [TaskGroups.CloudIDP]: "Identity",
  [TaskGroups.Sccm]: "SCCM Hardening Baseline",
};

export const TITLE_BY_COLLECTOR: Record<CollectorTypes, string> = {
  [CollectorTypes.AwsCollector]: "AWS Collector",
  [CollectorTypes.AwsCiCollector]: "AWS Control Insights Collector",
  [CollectorTypes.ActiveDirectoryCollector]: "Active Directory Collector",
  [CollectorTypes.CrowdStrikeCollector]: "CrowdStrike Collector",
  [CollectorTypes.EmailCollector]: "Microsoft 365 Email Tester",
  [CollectorTypes.ExFilCollector]: "Exfiltration Tester",
  [CollectorTypes.LinuxCollector]: "Linux Collector",
  [CollectorTypes.OracleCollector]: "Oracle Collector",
  [CollectorTypes.VirtualizationCollector]: "Virtualization Collector",
  [CollectorTypes.WindowsCollector]: "Windows Endpoint Collector",
  [CollectorTypes.OktaCollector]: "Okta Collector",
  [CollectorTypes.SplunkCollector]: "Splunk Collector",
  [CollectorTypes.AzureCollector]: "Azure Collector",
  [CollectorTypes.ManualLinuxCollector]: "Manual Linux Collector",
  [CollectorTypes.SccmBaselineReport]: "SCCM Baseline Report",
};

export const REVERSE_COLLECTOR_MAP: Record<CollectorTypes, TaskGroups> = {
  [CollectorTypes.AwsCollector]: TaskGroups.Cloud,
  [CollectorTypes.AwsCiCollector]: TaskGroups.Cloud,
  [CollectorTypes.EmailCollector]: TaskGroups.Email,
  [CollectorTypes.CrowdStrikeCollector]: TaskGroups.EDR,
  [CollectorTypes.OracleCollector]: TaskGroups.DataBase,
  [CollectorTypes.LinuxCollector]: TaskGroups.Linux,
  [CollectorTypes.VirtualizationCollector]: TaskGroups.Virtualization,
  [CollectorTypes.ExFilCollector]: TaskGroups.Exfil,
  [CollectorTypes.WindowsCollector]: TaskGroups.Windows,
  [CollectorTypes.ActiveDirectoryCollector]: TaskGroups.OnPremIDP,
  [CollectorTypes.SplunkCollector]: TaskGroups.SIEM,
  [CollectorTypes.OktaCollector]: TaskGroups.CloudIDP,
  [CollectorTypes.AzureCollector]: TaskGroups.Cloud,
  [CollectorTypes.ManualLinuxCollector]: TaskGroups.Linux,
  [CollectorTypes.SccmBaselineReport]: TaskGroups.Sccm,
};

export const TO_CONFIG = "to-config";
export const ON_HOLD = "on-hold";

export enum TaskConfigurationStatus {
  Loading = "loading",
  Configured = "configured",
  PreConfig = "pre-config",
  Failed = "failed",
  Running = "running",
  OnHold = "on-hold",
}

export type PriorityByStatusType =
  | TaskStatus
  | typeof TO_CONFIG
  | typeof ON_HOLD;

export const STATUS: Record<PriorityByStatusType, TaskConfigurationStatus> = {
  [TaskStatus.FAILED]: TaskConfigurationStatus.Failed,
  [TaskStatus.FAILED_PROCESS]: TaskConfigurationStatus.Failed,
  [TaskStatus.FAILED_APPLICATION]: TaskConfigurationStatus.Failed,
  [TaskStatus.JOB_DEPLOYED]: TaskConfigurationStatus.Running,
  [TaskStatus.READY]: TaskConfigurationStatus.Configured,
  [TaskStatus.RUNNING]: TaskConfigurationStatus.Running,
  [TaskStatus.SUCCESS]: TaskConfigurationStatus.Configured,
  [TaskStatus.TIMEOUT]: TaskConfigurationStatus.Failed,
  [TO_CONFIG]: TaskConfigurationStatus.PreConfig,
  [ON_HOLD]: TaskConfigurationStatus.OnHold,
};

export const STATUS_HUMANIZE: Record<TaskConfigurationStatus, string> = {
  [TaskConfigurationStatus.Loading]: "Loading...",
  [TaskConfigurationStatus.Configured]: "Completed",
  [TaskConfigurationStatus.PreConfig]: "Configuration required",
  [TaskConfigurationStatus.Failed]: "Failed",
  [TaskConfigurationStatus.Running]: "Running...",
  [TaskConfigurationStatus.OnHold]: "Paused Automation",
};

export interface TaskDefinitionStatusMap {
  preConfig: number;
  configured: number;
  running: number;
  jobDeployed: number;
  failed: number;
  onHold: number;
}
