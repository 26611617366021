import LRU from "lru-cache";
import AuthClient from "../communication/clients/auth-client";
import { Tokens, TokensResponse } from "../model/auth";

const ID_TOKEN = "id_token";
const ACCESS_TOKEN = "access_token";
const REFRESH_TOKEN = "refresh_token";
const CREATION_DATE = "creation_date";
const EXPIRATION_RATE = "refresh_rate";

const cache = new LRU({
  maxAge: 1000,
});

export async function getAuthToken(): Promise<string> {
  return getTokens(ACCESS_TOKEN, refreshTokens);
}

async function getTokens(
  tokenType: string,
  refreshMethod: (tokens: Tokens) => Promise<Tokens>
) {
  try {
    if (cache.has(tokenType)) {
      return cache.get(tokenType);
    }
    const currentTokens: Tokens = {
      access_token: localStorage.getItem(ACCESS_TOKEN),
      id_token: localStorage.getItem(ID_TOKEN),
      refresh_token: localStorage.getItem(REFRESH_TOKEN),
    };
    const creationDate = localStorage.getItem(CREATION_DATE);
    const expirationRate = localStorage.getItem(EXPIRATION_RATE);
    if (
      currentTokens.access_token &&
      currentTokens.id_token &&
      currentTokens.refresh_token &&
      creationDate &&
      expirationRate
    ) {
      const creationTime = new Date(creationDate).getTime();

      const outdated =
        new Date().getTime() - creationTime > parseInt(expirationRate) * 1000;

      if (outdated) {
        const refreshedTokens: Tokens = await refreshMethod(currentTokens);
        cache.set(tokenType, refreshedTokens[tokenType]);
        return refreshedTokens[tokenType];
      } else {
        return currentTokens[tokenType];
      }
    } else {
      return undefined;
    }
  } catch (error) {
    console.error(error);
  }
}

export async function refreshTokens(tokens: Tokens): Promise<Tokens> {
  const tokensResponse: TokensResponse | null =
    await AuthClient.Instance.refreshTokens(tokens);

  if (tokensResponse === null) {
    throw Error("invalid response while trying to get the tokens");
  }
  storeSessionTokens(tokensResponse);

  return tokensResponse.tokens;
}

export function storeSessionTokens(serverTokenResponse: TokensResponse): void {
  const serverTokens: Tokens = serverTokenResponse.tokens;

  localStorage.setItem(ACCESS_TOKEN, `${serverTokens.access_token}`);
  localStorage.setItem(ID_TOKEN, `${serverTokens.id_token}`);
  localStorage.setItem(REFRESH_TOKEN, `${serverTokens.refresh_token}`);
  localStorage.setItem(CREATION_DATE, `${serverTokenResponse.creation_date}`);
  localStorage.setItem(EXPIRATION_RATE, `${serverTokens.refresh_rate}`);
}

export function readAccessTokenPermissions(token: string): any | null {
  try {
    const claims = token.split(".")[1];
    if (claims) {
      return JSON.parse(atob(claims).toString());
    }
  } catch (error) {
    console.error(error);
  }
  return null;
}

export function clearSessionTokens(): void {
  localStorage.removeItem(ACCESS_TOKEN);
  localStorage.removeItem(ID_TOKEN);
  localStorage.removeItem(REFRESH_TOKEN);
  localStorage.removeItem(CREATION_DATE);
  localStorage.removeItem(EXPIRATION_RATE);
}
