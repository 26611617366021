import { FC } from "react";
import { useTranslation } from "react-i18next";
import TopBarLayout from "../layout/TopBarLayout";
import ParticipantAgreementContent from "./ParticipantAgreementContent";

const ParticipantAgreementPage: FC = () => {
  const { t } = useTranslation();
  return (
    <TopBarLayout title={t("participantAgreement.title")}>
      <section className="pt-4" />
      <section
        style={{
          maxWidth: "800px",
        }}
        className="vr-card-light my-5 m-auto p-5 w-fit"
      >
        <h1
          style={{
            fontSize: "20px",
            marginBottom: "48px",
          }}
        >
          {t("participantAgreement.title")}
        </h1>
        <ParticipantAgreementContent />
      </section>
    </TopBarLayout>
  );
};

export default ParticipantAgreementPage;
