import { AxiosResponse } from "axios";
import { MultiPartUploadResponse } from "../../model/static-assets";
import BaseAuthorizedRequest from "../requests/base-authorized-request";

export default class GenerateMultiPartUploadRequest extends BaseAuthorizedRequest<
  MultiPartUploadResponse,
  MultiPartUploadResponse
> {
  constructor(fileName: string, fileType: string) {
    super("PUT", `/static/url/multi_part/upload`, {
      payload: {
        file_name: fileName,
        file_type: fileType,
      },
    });
  }

  processResponse(
    response: AxiosResponse<MultiPartUploadResponse>
  ): MultiPartUploadResponse {
    const result = response.data;
    return result;
  }
}
