import { DateIosString } from "./time";
import {
  Limit,
  BaseCriteria,
  PaginatedTableOptions,
  PaginationType,
  BaseTableCellModel,
} from "../model/table";
import UserBadge from "../components/base/avatar/UserBadge";

export const sortable = true;

export const getBaseCriteria = (limit: Limit = 25): BaseCriteria => ({
  filterBy: { search: "" },
  limit,
  page: 0,
  orderBy: "",
  desc: false,
});

export const setOrderByCriteria = (
  _orderBy: string,
  criteria: BaseCriteria,
  setCriteria: React.Dispatch<React.SetStateAction<BaseCriteria>>
): void => {
  let { orderBy, desc } = { ...criteria };
  if (orderBy === _orderBy) {
    if (desc) {
      orderBy = "";
      desc = false;
    } else {
      desc = true;
    }
  } else {
    orderBy = _orderBy;
    desc = false;
  }
  setCriteria(_criteria => ({
    ..._criteria,
    orderBy,
    desc,
    page: 0,
  }));
};

export const paginationTableOptions: PaginatedTableOptions = {
  pagination: PaginationType.LoadMore,
  initLimit: 10,
};

export const sortValueDate = (date?: DateIosString): number => {
  return date ? +new Date(date) : Infinity;
};

export const userBadgeCell = (
  fullName?: string,
  maxWidth?: string
): BaseTableCellModel => {
  return {
    node: fullName ? (
      <UserBadge fullName={fullName} maxWidth={maxWidth} className="d-flex" />
    ) : (
      "-"
    ),
    className: "no-wrap",
    stringValue: fullName,
  };
};
