import { AxiosResponse } from "axios";
import { StaticAssetUrlResponse } from "../../model/static-assets";
import BaseAuthorizedRequest from "../requests/base-authorized-request";

export default class GeneratePreSignedUploadLinkRequest extends BaseAuthorizedRequest<
  Array<StaticAssetUrlResponse>,
  Array<StaticAssetUrlResponse>
> {
  constructor(
    filesNames: Array<string>,
    uploadId?: string,
    chunkNumber?: number,
    fileSize?: number,
    fileMD5?: string
  ) {
    let payload: Record<string, string | number | Array<string> | undefined> = {
      files_names: filesNames,
    };
    if (uploadId && chunkNumber) {
      payload = {
        ...payload,
        upload_id: uploadId,
        part_number: chunkNumber,
        file_size: fileSize,
        file_md5: fileMD5,
      };
    }
    super("PUT", `/static/url/upload`, {
      payload,
    });
  }

  processResponse(
    response: AxiosResponse<Array<StaticAssetUrlResponse>>
  ): Array<StaticAssetUrlResponse> {
    const result = response.data;
    return result;
  }
}
