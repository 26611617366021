import { FC } from "react";
export interface TabContentProps {
  content: JSX.Element;
}

const TabContent: FC<TabContentProps> = ({ content }) => {
  return <section className="d-block">{content}</section>;
};

export default TabContent;
