import { BaseUser, User } from "./auth";

export interface IndustrySector {
  id: number;
  sector_ids: Array<number>;
  sector_name: string;
}

export enum QuestionResponseType {
  MULTIPLE_CHOICES = "multiple_choices",
  FREE_TEXT = "free_text",
  CHECKBOX = "checkbox",
  SHORT_ANSWER = "short_answer",
  PERCENTAGE = "percentage",
  SHORT_ANSWER_CURRENCY = "short_answer_currency",
}

export enum QuestionDependencyStatus {
  DISABLED = "disabled",
  ENABLED = "enabled",
  IRRELEVANT = "irrelevant",
  NOT_DEPENDENT = "not_dependent",
}

export interface CommonBaseQuestionnaire<T extends BaseUser> {
  id: string;
  name: string;
  description?: string;
  version: number;
  industry_sectors: Array<IndustrySector>;
  section_count?: number;
  question_count?: number;
  created_at: string;
  created_by: T;
  updated_at: string;
  updated_by: T;
  active: boolean;
  deleted: boolean;
}
export interface BaseQuestionnaire extends CommonBaseQuestionnaire<User> {}

export interface CommonQuestionnaire<T extends BaseUser>
  extends CommonBaseQuestionnaire<T> {
  sections: Array<CommonQuestionnaireSection<T>>;
}
export interface Questionnaire extends CommonQuestionnaire<User> {}

export interface BaseQuestionnaireSection {
  name: string;
  description: string;
  industry_sectors: Array<IndustrySector>;
}

export interface CommonQuestionnaireSection<T extends BaseUser>
  extends BaseQuestionnaireSection {
  id: string;
  version: number;
  section_number: number;
  deleted: boolean;
  questions: Array<CommonQuestion<T>>;
  total_questions: number;
  created_at: string;
  created_by: T;
  updated_at: string;
  updated_by: T;
}

export interface QuestionnaireSection
  extends CommonQuestionnaireSection<User> {}

export interface Answer {
  id?: string;
  value: Array<string>;
  is_other?: boolean;
}

export interface QuestionnaireResponseAnswer extends Answer {
  question_id: string;
  question_version: number;
  will_not_answer?: boolean;
  reason_to_not_answer?: string;
  possible_answer_ids?: Array<string>;
  answered_by?: string;
}

export interface QuestionnaireAnswers {
  response_id: string;
  last_update?: string;
  last_updater?: string;
  answers: Array<QuestionnaireResponseAnswer>;
}

export interface DependentQuestion {
  id: string;
  question_id: string;
  question_version: number;
  dependency_answers_ids: string[];
}

export interface CommonQuestion<T extends BaseUser> {
  id: string;
  version: number;
  question: string;
  inner_note: string;
  response_type: QuestionResponseType;
  possible_answers: Array<Answer>;
  question_number: number;
  required: boolean;
  active: boolean;
  deleted: boolean;
  cat_id: string;
  cat_name: string;
  sub_category: string;
  cap_id: string;
  cap_name: string;
  mitre_id: string;
  mitre_tech: string;
  assessment_type: string;
  created_at: string;
  created_by: T;
  updated_at: string;
  updated_by: T;
  validation_type: QuestionValidationType;
  sub_validations?: Array<QuestionSubValidation>;
  depends_on_question?: DependentQuestion;
}

export interface Question extends CommonQuestion<User> {}

export interface QuestionnaireInstanceInformation {
  id: string;
  questionnaire_id: string;
  questionnaire_version?: number;
  sectors: Array<number>;
  engagement_id: string;
  submitted_at?: string;
}

export interface CreateQuestionnaireInstanceResponse {
  response_id: string;
  questionnaire_id: string;
  questionnaire_version: number;
  engagement_id: string;
}

export enum QuestionValidationType {
  any_type = "any_type",
  numeric = "numeric",
  sub_validation = "sub_validation",
}

export enum QuestionSubValidationTypes {
  greater_or_equal = "ge",
  lesser_or_equal = "le",
}

export interface QuestionSubValidation {
  validation_type: QuestionSubValidationTypes;
  related_question_id: string;
  related_question_number: number;
}
