import { FC } from "react";
import { useTranslation } from "react-i18next";
import { ExternalLink } from "visible-ui";

const PoweredBy: FC = () => {
  const { t } = useTranslation();
  return (
    <ExternalLink
      linkAddress="https://www.bitsight.com"
      txt={t("layout.poweredBy")}
      className="text-gray-600"
    />
    /* TODO: use switch case with 8 images for each locale */
  );
};

export default PoweredBy;
