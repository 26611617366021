import { FC, useState } from "react";
import { ModalProps } from "react-bootstrap";
import { TriggerElement } from "../../../model/base";
import ModalWithX from "./ModalWithX";

interface ModalWithTriggerProps extends ModalProps {
  triggerElement: TriggerElement;
  children: (show: boolean, close: () => void) => React.ReactNode;
  id: string;
}

const ModalWithTrigger: FC<ModalWithTriggerProps> = ({
  triggerElement,
  children,
  ...props
}) => {
  const [show, setShow] = useState(false);
  const close = () => setShow(false);
  const open = () => setShow(true);
  return (
    <>
      {triggerElement(open, show)}
      <ModalWithX show={show} onHide={close} {...props}>
        {children(show, close)}
      </ModalWithX>
    </>
  );
};

export default ModalWithTrigger;
