import { QuestionResponseType } from "visible-ui";
import { SurveyUser } from "./user";

export enum QuestionDependencyStatus {
  DISABLED = "disabled",
  ENABLED = "enabled",
  IRRELEVANT = "irrelevant",
  NOT_DEPENDENT = "not_dependent",
}

export enum QuestionValidationType {
  any_type = "any_type",
  numeric = "numeric",
  sub_validation = "sub_validation",
}

export enum AnswerStatus {
  CantAnswer = "cant_answer",
  Answered = "answered",
  Empty = "empty",
}

export interface IndustrySector {
  id: number;
  sector_ids: Array<number>;
  sector_name: string;
}

export enum QuestionSubValidationTypes {
  greater_or_equal = "ge",
  lesser_or_equal = "le",
}

export interface QuestionSubValidation {
  validation_type: QuestionSubValidationTypes;
  related_question_id: string;
  related_question_number: number;
}

export interface SurveyBaseAnswer {
  id?: string;
  value: Array<string>;
  is_other?: boolean;
}

export interface SurveyAnswer extends SurveyBaseAnswer {
  question_id: string;
  question_version: number;
  will_not_answer?: boolean;
  reason_to_not_answer?: string;
  possible_answer_ids?: Array<string>;
  answered_by?: string;
  currency_code?: string;
}

export interface SurveyAnswers {
  response_id: string;
  last_update?: string;
  last_updater?: string;
  answers: Array<SurveyAnswer>;
}

export interface Survey {
  id: string;
  name: string;
  description?: string;
  version: number;
  industry_sectors: Array<IndustrySector>;
  section_count?: number;
  question_count?: number;
  created_at: string;
  created_by: SurveyUser;
  updated_at: string;
  updated_by: SurveyUser;
  active: boolean;
  deleted: boolean;
  submitted: boolean;
  ended: boolean;
  sections: Array<SurveySection>;
}

export interface DependentQuestion {
  id: string;
  question_id: string;
  question_version: number;
  dependency_answers_ids: string[];
}

export interface SurveyQuestion {
  id: string;
  version: number;
  question: string;
  inner_note: string;
  response_type: QuestionResponseType;
  possible_answers: Array<SurveyBaseAnswer>;
  question_number: number;
  required: boolean;
  active: boolean;
  deleted: boolean;
  cat_id: string;
  cat_name: string;
  sub_category: string;
  cap_id: string;
  cap_name: string;
  mitre_id: string;
  mitre_tech: string;
  assessment_type: string;
  created_at: string;
  created_by: SurveyUser;
  updated_at: string;
  updated_by: SurveyUser;
  validation_type: QuestionValidationType;
  sub_validations?: Array<QuestionSubValidation>;
  depends_on_question?: DependentQuestion;
  question_absolute_number: number;
}
export interface SurveySection {
  name: string;
  description: string;
  industry_sectors: Array<IndustrySector>;
  id: string;
  version: number;
  section_number: number;
  deleted: boolean;
  questions: Array<SurveyQuestion>;
  total_questions: number;
  created_at: string;
  created_by: SurveyUser;
  updated_at: string;
  updated_by: SurveyUser;
}
