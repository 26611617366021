import { FC, useEffect, useState } from "react";
import { Button, Modal } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import { useRecoilStateLoadable } from "recoil";
import styled from "styled-components";

import { BtnSpinnerAutoLoading, Loading, toastError } from "visible-ui";

import MeClient from "../../communication/clients/me-client";
import { SurveyUser } from "../../model/user";
import { myInfoAtom } from "../../state/atoms/me-atoms";
import ParticipantAgreementContent from "./ParticipantAgreementContent";
import { useAuth } from "../../hooks/auth-hooks";

const ModalStyled = styled(Modal)`
  .modal-dialog-centered {
    max-width: 100%;
  }
  .modal-content {
    overflow: hidden;
    width: auto;
    margin: 0 auto;
  }
`;

const ModalWrapperStyled = styled.section`
  width: 800px;
  max-height: 470px;
  padding: 0;
  background: linear-gradient(#fff 30%, #ffffff00),
    linear-gradient(#ffffff00, #fff 70%) 0 100%,
    linear-gradient(to top, #fff 0%, var(--grey-4) 100%),
    linear-gradient(to bottom, #fff, var(--grey-2)) 0 100%;
  background-repeat: no-repeat;
  background-size: 100% 100px, 100% 66px, 100% 33px, 100% 33px;
  background-attachment: local, local, scroll, scroll;
`;

const ParticipantAgreementModal: FC = () => {
  const { t } = useTranslation();
  const { getAccessToken, logout } = useAuth();
  const [myInfoLoadable, setMyInfoLoadable] =
    useRecoilStateLoadable(myInfoAtom);

  const [myInfo, setMyInfo] = useState<SurveyUser | null>(null);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    setLoading(myInfoLoadable.state === "loading");
    if (myInfoLoadable.state === "hasValue") {
      const _myInfo = myInfoLoadable.getValue();
      _myInfo && setMyInfo(_myInfo);
    }
  }, [myInfoLoadable]);

  return loading ? (
    <div className="center-container">
      <Loading variant="spinner" />
    </div>
  ) : (
    <ModalStyled
      centered
      id="privacy-agree-modal"
      // Keep the precondition, don't use *** !myInfo?.privacy_agreed ***!
      show={myInfo && !myInfo.privacy_agreed}
      className="p-0 w-100"
    >
      <section className="p-5 border-bottom ">
        <div className="fs-2 fw-bold mb-3">
          {t("participantAgreement.title")}
        </div>
        <div className="text-gray-700">
          {t("participantAgreement.subtitle")}
        </div>
      </section>
      <ModalWrapperStyled className="overflow-scroll position-relative pt-5 px-5 pb-2">
        <ParticipantAgreementContent />
      </ModalWrapperStyled>
      <section className="p-5 d-flex align-items-center justify-content-center gap-3">
        <Button variant="link" size="lg" onClick={logout}>
          {t("participantAgreement.disagree")}
        </Button>
        <BtnSpinnerAutoLoading
          size="lg"
          handleClick={async (onStart, onDone) => {
            if (!myInfo) {
              toastError(t("errorMessages.userDetailsMissing"));
              return;
            }
            onStart();
            try {
              const accessToken = await getAccessToken();
              const myInfoToUpdate = { ...myInfo, privacy_agreed: true };
              const res = await MeClient.Instance.updateMyInfo(
                accessToken,
                myInfoToUpdate
              );
              if (res?.success) {
                setMyInfo(myInfoToUpdate);
                setMyInfoLoadable(myInfoToUpdate);
              } else {
                toastError(
                  res?.error_message || t("errorMessage.agreePrivacy")
                );
              }
            } catch (error) {
              console.error(error);
              toastError(t("errorMessage.agreePrivacy"));
            }
            onDone();
          }}
          variant="primary"
          loadMsg={t("participantAgreement.agreeing")}
        >
          {t("participantAgreement.agree")}
        </BtnSpinnerAutoLoading>
      </section>
    </ModalStyled>
  );
};

export default ParticipantAgreementModal;
