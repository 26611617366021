import TextInput, { TextInputProps } from "./TextInput";
import { forwardRef, useEffect, useState } from "react";

export interface TextInputErrMsgManagementProps
  extends Omit<TextInputProps, "onError"> {
  setValidation: (valid: boolean) => void;
  validation?: (value: string) => boolean;
  onError?: (isError: boolean) => void;
  invalidErrorMessage?: string;
  emptyErrorMessage?: string;
}

enum ErrorMap {
  Empty,
  Invalid,
  NoError,
}

const TextInputErrMsgManagement = forwardRef<
  HTMLInputElement,
  TextInputErrMsgManagementProps
>(
  (
    {
      validation,
      setValidation,
      onError,
      onChange,
      invalidErrorMessage,
      emptyErrorMessage = "Required",
      errorMessage,
      value,
      ...props
    },
    ref?
  ) => {
    const [_errorMessage, _setErrorMessage] = useState(errorMessage || "");

    const [errMode, setErrMode] = useState(
      errorMessage ? ErrorMap.Invalid : ErrorMap.NoError
    );

    const switchErrorMessage = (currValue: string) => {
      const _errMode = !currValue
        ? ErrorMap.Empty
        : validation && !validation(currValue)
        ? ErrorMap.Invalid
        : ErrorMap.NoError;
      setErrMode(_errMode);
      setValidation(_errMode === ErrorMap.NoError);
    };

    useEffect(() => {
      switch (errMode) {
        case ErrorMap.Empty:
          _setErrorMessage(emptyErrorMessage);
          break;
        case ErrorMap.Invalid:
          _setErrorMessage(errorMessage || invalidErrorMessage || "");
          break;
        case ErrorMap.NoError:
          _setErrorMessage(errorMessage || "");
          break;
      }
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [errMode, invalidErrorMessage, errorMessage, _setErrorMessage]);

    useEffect(() => {
      onError && onError(!!_errorMessage);
    }, [_errorMessage, onError]);

    // Calling switchErrorMessage on-init
    useEffect(() => {
      value && switchErrorMessage(value);
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
      const currValue = e.target.value;
      _errorMessage && switchErrorMessage(currValue);
      onChange && onChange(e);
    };

    return (
      <TextInput
        {...props}
        ref={ref}
        errorMessage={_errorMessage}
        value={value}
        invalid={!!_errorMessage}
        onChange={handleChange}
        onBlur={e => {
          switchErrorMessage(e.target.value);
        }}
      />
    );
  }
);

export default TextInputErrMsgManagement;
