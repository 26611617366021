import { FC } from "react";
import { Image } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import {useRecoilValue} from 'recoil';
import { ExternalLink, CircleIconButtonTooltip } from "visible-ui";
import { myInfoAtom } from "../../state/atoms/me-atoms";


const ContactUs: FC = () => {
  const { t } = useTranslation();
  const myInfo = useRecoilValue(myInfoAtom)


  return (
    <>
      <CircleIconButtonTooltip
        offsetX={95}
        closeOnScroll
        userLocale={myInfo?.locale}
        icon={
          <Image
            className="opacity-75"
            src={`${process.env.PUBLIC_URL}assets/img/chat-message.svg`}
            alt="Contact Us"
          />
        }
      >
        <section className="vr-card d-flex flex-column position-relative overflow-hidden">
          <section className="d-flex align-items-center gap-3 p-4 border-bottom">
            <Image src={`${process.env.PUBLIC_URL}assets/img/email.svg`} />
            {t("contact.contactUs")}
          </section>
          <section className="d-flex flex-column p-4 gap-1">
            <span className="text-gray-700">{t("contact.emailUsAt")}</span>
            <ExternalLink
              linkAddress="support@mail.cybersurvey.moodys.com"
              mailto
            />
          </section>
        </section>
      </CircleIconButtonTooltip>
    </>
  );
};

export default ContactUs;
