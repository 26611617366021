import { ErrorInfo, Component, ReactNode } from "react";
import { Container } from "react-bootstrap";
import BaseComponentProps from "../../../base-component-props";
import ErrorPage from "../../layout/errors/ErrorPage";

export interface ErrorBoundaryProps extends BaseComponentProps {
  errorPage?: ReactNode;
  logoutCallback?: () => void;
}

export interface ErrorBoundaryState {
  hasError: boolean;
  children?: React.ReactNode;
}

// TODO: extend this component to accept fallback component
class ErrorBoundary extends Component<ErrorBoundaryProps, ErrorBoundaryState> {
  constructor(props: ErrorBoundaryProps) {
    super(props);
    this.state = { hasError: false };
  }

  static getDerivedStateFromError(): { hasError: boolean } {
    // Update state so the next render will show the fallback UI.
    return { hasError: true };
  }

  componentDidCatch(error: Error, errorInfo: ErrorInfo): void {
    // You can also log the error to an error reporting service
    console.error("ErrorBoundary caught an error", error, errorInfo);
  }

  render(): ReactNode {
    if (this.state.hasError) {
      // You can render any custom fallback UI
      return (
        <Container fluid="lg">
          {this.props.errorPage || (
            <ErrorPage
              className="vh-100"
              errorMsg="We encountered an unexpected error."
              imageUrl={
                process.env.PUBLIC_URL + "/assets/img/tasks/no-tasks-illu.svg"
              }
              logoutCallback={this.props.logoutCallback}
            />
          )}
        </Container>
      );
    }

    return this.props.children;
  }
}

export default ErrorBoundary;
