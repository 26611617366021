import { FC } from "react";
import { BaseComponentProps } from "../../..";

const CloudSaved: FC<BaseComponentProps> = ({ style }) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="19"
    height="13"
    viewBox="0 0 19 13"
    className="my-auto"
    style={style}
    data-testid="cloud-saved"
  >
    <g fill="none" fillRule="evenodd">
      <g>
        <path
          fill="#F9FAFC"
          d="M0 0H1440V739H0z"
          transform="translate(-1072 -514)"
        />
        <g fillRule="nonzero">
          <path
            fill="#FFF"
            d="M0 0h1190v60H0V0z"
            transform="translate(-1072 -514) translate(0 491) matrix(-1 0 0 1 1190 0)"
          />
          <g fill="#6E84A3">
            <g>
              <g>
                <path
                  d="M10.55.37c-1.654.07-3.14.946-4.015 2.303l-.067.107-.033-.01c-.244-.066-.497-.101-.754-.101l-.167.005c-1.218.07-2.247.905-2.584 2.061l-.07.031C1.444 5.41.502 6.823.502 8.42c0 2.216 1.8 4.013 4.02 4.015h10.731c2.057-.012 3.717-1.676 3.717-3.726l-.004-.186c-.085-1.72-1.337-3.133-2.983-3.466l-.198-.035-.018-.194c-.29-2.52-2.42-4.461-5.002-4.461l-.217.004zm.216 1.093c2.176 0 3.94 1.76 3.94 3.93l-.034.684.574.002c1.453.004 2.628 1.18 2.628 2.629 0 1.446-1.172 2.62-2.623 2.628l-10.728.001C2.908 11.335 1.6 10.03 1.6 8.42c0-1.256.804-2.357 1.978-2.759l.308-.106.055-.321c.143-.842.875-1.467 1.74-1.467.277 0 .546.065.792.19l.5.25.241-.504c.65-1.357 2.021-2.24 3.552-2.24z"
                  transform="translate(-1072 -514) translate(0 491) translate(873 11) translate(199 9) translate(0 3)"
                />
              </g>
            </g>
          </g>
        </g>
      </g>
    </g>
  </svg>
);

export default CloudSaved;
