import { FC } from "react";

import { Form } from "react-bootstrap";
import PI from "react-phone-input-2";
import "react-phone-input-2/lib/bootstrap.css";
// @ts-expect-error FIXME: Property 'defaultProps' does not exist
const ReactPhoneInput = PI.default ? PI.default : PI;

export interface VisiblePhoneInputProps {
  placeholder?: string;
  phoneNumber?: string;
  invalid?: boolean;
  errorMessage?: string;
  country?: string;
  inputStyle?: React.CSSProperties;
  containerStyle?: React.CSSProperties;
  onChange: (value: string) => void;
}

const VisiblePhoneInput: FC<VisiblePhoneInputProps> = ({
  inputStyle,
  containerStyle,
  placeholder,
  phoneNumber,
  errorMessage,
  country = "us",
  onChange,
}) => {
  const addPlusOnChange = (value: string) => {
    onChange(`+${value}`);
  };
  return (
    <Form.Group>
      <ReactPhoneInput
        country={country}
        value={phoneNumber}
        placeholder={placeholder}
        onChange={addPlusOnChange}
        inputStyle={inputStyle}
        inputClass="w-100"
        containerStyle={containerStyle}
      />
      {errorMessage && (
        <small className="form-text small invalid-feedback form-text">
          {errorMessage}
        </small>
      )}
    </Form.Group>
  );
};

export default VisiblePhoneInput;
