import {
  ArcElement,
  BarController,
  BarElement,
  BubbleController,
  CategoryScale,
  Chart as ChartJS,
  Decimation,
  DoughnutController,
  Filler,
  Legend,
  LineController,
  LineElement,
  LinearScale,
  LogarithmicScale,
  PieController,
  PointElement,
  PolarAreaController,
  RadarController,
  RadialLinearScale,
  ScatterController,
  TimeScale,
  TimeSeriesScale,
  Title,
  Tooltip,
} from "chart.js";
import zoomPlugin from "chartjs-plugin-zoom";

ChartJS.register(
  ArcElement,
  BarController,
  BarElement,
  BubbleController,
  CategoryScale,
  Decimation,
  DoughnutController,
  Filler,
  Legend,
  LinearScale,
  LineController,
  LineElement,
  LogarithmicScale,
  PieController,
  PointElement,
  PolarAreaController,
  RadarController,
  RadialLinearScale,
  ScatterController,
  TimeScale,
  TimeSeriesScale,
  Title,
  Tooltip,
  zoomPlugin
);

const style = getComputedStyle(document.body);

export const CHART_COLORS = {
  multi_dataset: {
    0: style.getPropertyValue("--user-1"),
    1: style.getPropertyValue("--user-2"),
    2: style.getPropertyValue("--user-3"),
    3: style.getPropertyValue("--user-4"),
    4: style.getPropertyValue("--user-5"),
    5: style.getPropertyValue("--user-6"),
    6: style.getPropertyValue("--user-7"),
    7: style.getPropertyValue("--user-8"),
  },
  gray: {
    300: style.getPropertyValue("--bs-chart-gray-300"),
    600: style.getPropertyValue("--bs-chart-gray-600"),
    700: style.getPropertyValue("--bs-chart-gray-700"),
    800: style.getPropertyValue("--bs-chart-gray-800"),
    900: style.getPropertyValue("--bs-chart-gray-900"),
  },
  primary: {
    100: style.getPropertyValue("--bs-chart-primary-100"),
    300: style.getPropertyValue("--bs-chart-primary-300"),
    700: style.getPropertyValue("--bs-chart-primary-700"),
  },
  black: style.getPropertyValue("--bs-dark"),
  white: style.getPropertyValue("--bs-white"),
  transparent: "transparent",
};

const fonts = {
  base: "Cerebri Sans",
};

export function globalOptions() {
  ChartJS.defaults.responsive = true;

  // Default
  ChartJS.defaults.color = style.getPropertyValue("--bs-chart-default-color");
  ChartJS.defaults.font.family = fonts.base;
  ChartJS.defaults.font.size = 13;

  // Layout
  ChartJS.defaults.layout.padding = 0;

  // Interaction
  ChartJS.defaults.interaction.intersect = false;
  ChartJS.defaults.interaction.mode = "nearest";
  ChartJS.defaults.interaction.axis = "x";

  // Legend
  ChartJS.defaults.plugins.legend.display = false;

  // Zoom
  if (ChartJS.defaults.plugins.zoom.zoom) {
    ChartJS.defaults.plugins.zoom.zoom.mode = "xy";
    if (ChartJS.defaults.plugins.zoom.zoom.wheel) {
      ChartJS.defaults.plugins.zoom.zoom.wheel.enabled = true;
      ChartJS.defaults.plugins.zoom.zoom.wheel.modifierKey = "ctrl";
    }
    if (ChartJS.defaults.plugins.zoom.zoom.pinch) {
      ChartJS.defaults.plugins.zoom.zoom.pinch.enabled = true;
    }
  }

  // Pan
  if (ChartJS.defaults.plugins.zoom.pan) {
    ChartJS.defaults.plugins.zoom.pan.enabled = true;
    ChartJS.defaults.plugins.zoom.pan.mode = "xy";
  }

  // Point
  ChartJS.defaults.elements.point.radius = 0;
  ChartJS.defaults.elements.point.backgroundColor = CHART_COLORS.primary[700];

  // Line
  ChartJS.defaults.elements.line.tension = 0.4;
  ChartJS.defaults.elements.line.borderWidth = 3;
  ChartJS.defaults.elements.line.borderColor = CHART_COLORS.primary[700];
  ChartJS.defaults.elements.line.backgroundColor = CHART_COLORS.transparent;
  ChartJS.defaults.elements.line.borderCapStyle = "round";

  // Bar
  ChartJS.defaults.elements.bar.backgroundColor = CHART_COLORS.primary[700];
  ChartJS.defaults.elements.bar.borderWidth = 0;
  ChartJS.defaults.elements.bar.borderRadius = 10;
  ChartJS.defaults.elements.bar.borderSkipped = false;
  ChartJS.defaults.datasets.bar.maxBarThickness = 10;

  // Arc
  ChartJS.defaults.elements.arc.backgroundColor = CHART_COLORS.primary[700];
  ChartJS.defaults.elements.arc.borderColor = style.getPropertyValue(
    "--bs-chart-arc-border-color"
  );
  ChartJS.defaults.elements.arc.borderWidth = 4;
  ChartJS.defaults.elements.arc.hoverBorderColor = style.getPropertyValue(
    "--bs-chart-arc-hover-border-color"
  );

  // Tooltips
  ChartJS.defaults.plugins.tooltip.enabled = true;
  ChartJS.defaults.plugins.tooltip.padding = 12;
  ChartJS.defaults.plugins.tooltip.caretPadding = 8;

  ChartJS.defaults.plugins.tooltip.bodyFont = {
    weight: "bolder",
    size: 14,
  };

  // Doughnut
  //@ts-expect-error should exist
  ChartJS.defaults.datasets.doughnut.cutout = "83%";

  // yAxis
  ChartJS.defaults.scales.linear.grid = {
    tickBorderDash: [2],
    tickBorderDashOffset: 2,
    color: style.getPropertyValue("--bs-chart-grid-line-color"),
    drawTicks: false,
  };

  //@ts-expect-error should exist
  ChartJS.defaults.scales.linear.ticks.beginAtZero = true;

  // xAxis
  ChartJS.defaults.scales.category.grid = {
    ...ChartJS.defaults.scales.category.grid,
    drawOnChartArea: false,
    drawTicks: false,
  };

  ChartJS.defaults.scales.category.ticks.padding = 20;
}
