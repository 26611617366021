import { FC, useState } from "react";
import styled from "styled-components";
import {
  ModalWithTrigger,
  TriggerElement,
  UserPermissions,
  UserRow,
} from "visible-ui";
import { Button } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import { useRecoilValue } from "recoil";
import { myInfoAtom } from "../../state/atoms/me-atoms";
import ShareSurveyUserList from "./ShareSurveyUserList";
import { SurveyUser } from "../../model/user";
import ShareSurveyForm from "./ShareSurveyForm";
import { allOrganizationUsersAtom } from "../../state/atoms/survey-users-atoms";

const ModalWrapperStyled = styled.section`
  width: 750px;
  max-height: 700px;
  padding: 0;
  .asset-list-container {
    max-height: 500px;
    overflow-x: hidden;
  }
`;

interface ShareSurveyModalProps {
  triggerElement: TriggerElement;
}

const ShareSurveyModal: FC<ShareSurveyModalProps> = ({ triggerElement }) => {
  const { t } = useTranslation();
  const myInfo = useRecoilValue(myInfoAtom);
  const allUsers = useRecoilValue(allOrganizationUsersAtom);
  const [currentUser, setCurrentUser] = useState<SurveyUser | null>(null);

  if (!myInfo) {
    throw new Error("my info is null");
  }

  const getEmptyUser = (): SurveyUser => {
    return {
      ...myInfo,
      id: "",
      name: "",
      email: "",
      job_title: "",
      privacy_agreed: false,
      sub_permissions: [],
    };
  };

  const ownerUser: SurveyUser | undefined = allUsers.find((user) => {
    return user.owner === true
  })

  return (
    <ModalWithTrigger
      id="survey-share-modal"
      className="p-0 w-100"
      triggerElement={triggerElement}
    >
      {_ => (
        <>
          <section className="d-flex align-items-center justify-content-between ps-4 pe-6 pt-3 mt-1 pb-3 border-bottom">
            <span className="fs-2 fw-bold my-2">
              {t("shareSurvey.shareSurvey")}
            </span>
          </section>
          <ModalWrapperStyled className="overflow-scroll position-relative">
            {currentUser ? (
              <ShareSurveyForm
                onCancel={() => setCurrentUser(null)}
                currentUser={currentUser}
              />
            ) : (
              <section className="p-4">
                <section>
                  <section className="d-flex align-items-center justify-content-between">
                    <b>{t("shareSurvey.sharedWith")}</b>
                    <Button onClick={() => setCurrentUser(getEmptyUser())}>
                      {t("shareSurvey.addUser")}
                    </Button>
                  </section>
                  <ShareSurveyUserList setCurrentUser={setCurrentUser} />
                </section>
                {ownerUser && (
                  <section className="mt-4">
                    <b>{t("shareSurvey.surveyOrganizationAdmins")}</b>
                    <UserRow
                      user={{
                        super_mode: true,
                        permissions: [UserPermissions.champion],
                        full_name: ownerUser.name,
                        disabled: false,
                        ...ownerUser,
                      }}
                      idx={0}
                      role={
                        <span>{ownerUser?.owner || t("shareSurvey.owner")}</span>
                      }
                    />
                  </section>
                )}
              </section>
            )}
          </ModalWrapperStyled>
        </>
      )}
    </ModalWithTrigger>
  );
};

export default ShareSurveyModal;
