import { FC } from "react";
import { Button } from "react-bootstrap";
import {
  Breakpoint,
  breakpoints,
  useBreakpoint,
} from "../../../utils/responsive";
import Tooltip from "../tooltip/Tooltip";
import CloudCompleted from "./cloud-saved-big-green";
import CloudSaved from "./cloud-saved-small";

interface CloudSaveBadgeResponsiveProps {
  cloudIcon: React.ReactNode;
  cloudLabel?: string | JSX.Element;
  disabled?: boolean;
  fullShowBreakpoint: Breakpoint;
  color?: string;
}

const CloudSaveBadgeResponsive: FC<CloudSaveBadgeResponsiveProps> = ({
  cloudIcon,
  cloudLabel,
  disabled,
  fullShowBreakpoint,
  color = "var(--bs-gray-500)",
}) => {
  const breakpoint = useBreakpoint();

  return breakpoint > breakpoints[fullShowBreakpoint] ? (
    <Button
      disabled={disabled}
      variant="link"
      className="d-flex align-items-center gap-3"
      style={{ color }}
    >
      {cloudIcon}
      <span className="label-trigger">{cloudLabel}</span>
    </Button>
  ) : (
    <Tooltip dark trigger={<Button variant="link">{cloudIcon}</Button>}>
      <span className="px-4 py-2">{cloudLabel}</span>
    </Tooltip>
  );
};

export enum CloudSaveBadgeState {
  Idle = "idle",
  Saving = "saving",
  Completed = "completed",
}

export interface CloudSaveBadgeProps {
  state: CloudSaveBadgeState;
  className?: string;
  completedLabel?: string | JSX.Element;
  savedLabel?: string | JSX.Element;
  iconOpacity?: number;
  fullShowBreakpoint?: Breakpoint;
  color?: string;
}

const CloudSaveBadge: FC<CloudSaveBadgeProps> = ({
  state,
  completedLabel,
  savedLabel,
  iconOpacity = 0.5,
  fullShowBreakpoint = Breakpoint.xxl,
  color = "var(--bs-gray-500)",
}) => {
  return state === CloudSaveBadgeState.Completed ? (
    <CloudSaveBadgeResponsive
      fullShowBreakpoint={fullShowBreakpoint}
      cloudIcon={<CloudCompleted />}
      cloudLabel={completedLabel}
      color={color}
    />
  ) : (
    <CloudSaveBadgeResponsive
      fullShowBreakpoint={fullShowBreakpoint}
      disabled
      cloudIcon={<CloudSaved style={{ opacity: iconOpacity }} />}
      cloudLabel={
        state === CloudSaveBadgeState.Saving ? "Saving..." : savedLabel
      }
      color={color}
    />
  );
};

export default CloudSaveBadge;
