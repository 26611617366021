import { createRef, FC, Fragment } from "react";
import { useRecoilValue } from "recoil";
import { useTranslation } from "react-i18next";

import {
  FormWithIndex,
  StyleFormWithIndexProps,
  EllipsisCard,
  QuestionnaireResponseIndex,
} from "visible-ui";
import {
  surveyAnswersMap,
  currentSurveyAtom,
} from "../../state/atoms/survey-responses-atoms";
import SurveyResponseSection from "./SurveyResponseSection";
import SurveyResponseBanner from "./SurveyResponseBanner";
import { activeKeyAtom } from "../../state/atoms/atoms";
import { QuestionDependencyStatus, SurveySection } from "../../model/survey";
import { SurveyUser } from "../../model/user";
import { flatQuestionsSurveySelector } from "../../state/selectors/survey-selector";
import { getQuestionDependencyStatus } from "./survey-utils";

const SurveyResponseForm: FC = () => {
  const surveyResponseData = useRecoilValue(currentSurveyAtom);
  const { t } = useTranslation();
  if (!surveyResponseData) {
    throw Error(`Failed to get current survey`);
  }

  const { submitted } = surveyResponseData;

  const flatQuestions = useRecoilValue(flatQuestionsSurveySelector);
  const { answersMap } = useRecoilValue(surveyAnswersMap);

  const flatQuestionsFiltered = flatQuestions.filter(question => {
    const { status } = getQuestionDependencyStatus(
      question,
      flatQuestions,
      answersMap
    );
    return status !== QuestionDependencyStatus.IRRELEVANT;
  });

  const flatQuestionsFilteredIds = flatQuestionsFiltered.map(({ id }) => id);

  const refs = flatQuestions.reduce(
    (acc: Record<string, React.RefObject<HTMLDivElement>>, question) => {
      acc[question.id] = createRef();
      return acc;
    },
    {}
  );

  const isAllCompleted =
    flatQuestionsFiltered.length ===
    Array.from(answersMap.values()).filter(
      _ =>
        flatQuestionsFilteredIds.includes(_.question_id) &&
        (_.value.length > 0 || !!_.reason_to_not_answer)
    ).length;

  const isSingleSection = surveyResponseData?.sections.length === 1;

  const styleFormWithIndex: StyleFormWithIndexProps = {
    indexSection: { width: "275px" },
    formSection: { maxWidth: "65vw" },
    topSticky: isAllCompleted ? "210px" : "110px",
    container: { marginTop: isAllCompleted ? "96px" : "48px" },
  };

  const activeKey = useRecoilValue(activeKeyAtom);

  return (
    <>
      <SurveyResponseBanner open={isAllCompleted} />

      <FormWithIndex
        disabled={submitted}
        index={
          <QuestionnaireResponseIndex<SurveyUser>
            questionnaire={surveyResponseData}
            refs={refs}
            hideTitle={isSingleSection}
            maxHeightBottomMargin={isAllCompleted ? 450 : 160}
            answersMap={answersMap}
            activeKey={activeKey}
          />
        }
        form={
          <>
            {surveyResponseData?.sections.map(
              (section: SurveySection, idx, sections) => {
                return section.questions.length ? (
                  <Fragment key={`survey-response-section-${section.id}`}>
                    <SurveyResponseSection
                      section={section}
                      refs={refs}
                      hideTitle={isSingleSection}
                    />
                  </Fragment>
                ) : // Checks if there is an "EllipsisCard" in the previous section
                // and if so don't show the current "EllipsisCard"
                idx > 0 ? (
                  !!sections[idx - 1].questions.length &&
                  sections[idx - 1].questions[
                    sections[idx - 1].questions.length - 1
                  ].question_number ===
                    sections[idx - 1].total_questions - 1 && (
                    <EllipsisCard
                      key={`survey-response-ellipsis-${section.section_number}-${idx}`}
                      className="mb-5 mt-n5"
                    />
                  )
                ) : null;
              }
            )}
            <h3 className="text-center mb-7">
              {t("generalSurvey.endOfSurvey")}
            </h3>
          </>
        }
        styleFormWithIndex={styleFormWithIndex}
      />
    </>
  );
};

export default SurveyResponseForm;
