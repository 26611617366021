export const EditorMixin = `
    color: var(--grey-7);
    table {
        tr:first-child {
            background-color: var(--grey-1);
            color: var(--grey-7);
        }
        td, th {
            border: 0;
            border-top:1px solid var(--grey-2);
            vertical-align: top;
            horizontal-align: center;
            min-width: 2em;
            padding: 0.4em;
        }
    }
    li {
        margin-block-end: 0.7rem;
    }
    p {
        margin-block-end: 2.25rem;
    }
    strong {
        color: var(--vr-dark-blue);
    }
`
