import BaseClient from "./base-client";
import GetUserInfoRequest from "../auth/get-user-info-request";
import UpdateUserMetadataRequest from "../auth/update-user-metadata-request";
import GetUsersOtpRequest from "../auth/get-users-otp-request";
import UpdateOtpUserRequest from "../auth/update-otp-user-request";
import RegisterOtpUserRequest from "../auth/register-otp-user-request";
import DeactivateUserRequest from "../auth/deactivate-user-request";
import {
  GenericResponse,
  ShareResourcesRequest as ShareResourcesRequestModel,
  User,
  UserMetadata,
} from "../../model";
import ShareResourcesRequest from "../auth/share-resources-request";

export default class UsersClient extends BaseClient {
  protected static instance: UsersClient;

  protected constructor() {
    super("/api/v1/users");
  }

  public static get Instance(): UsersClient {
    return this.instance || (this.instance = new UsersClient());
  }

  public async getUserInfo(): Promise<User | null> {
    return this.doRequest(new GetUserInfoRequest());
  }

  public async getUsersOtp(
    fullData: boolean,
    organizationId?: string
  ): Promise<Array<User> | null> {
    return this.doRequest(new GetUsersOtpRequest(fullData, organizationId));
  }

  public async updateUserMetadata(
    metadata: UserMetadata
  ): Promise<GenericResponse | null> {
    return this.doRequest(new UpdateUserMetadataRequest(metadata));
  }

  public async registerUser(user: User): Promise<User | null> {
    return this.doRequest(new RegisterOtpUserRequest(user));
  }

  public async updateUser(
    user: User,
    currentChampionId?: string
  ): Promise<GenericResponse | null> {
    return this.doRequest(new UpdateOtpUserRequest(user, currentChampionId));
  }

  public async deactivateUser(
    targetUser: User,
    organizationId: string
  ): Promise<GenericResponse | null> {
    return this.doRequest(
      new DeactivateUserRequest(targetUser, organizationId)
    );
  }

  public async shareResources(
    shareResourcesRequest: ShareResourcesRequestModel
  ): Promise<GenericResponse | null> {
    return this.doRequest(new ShareResourcesRequest(shareResourcesRequest));
  }
}
