import BaseRequest from "../requests/base-request";
import { AxiosResponse } from "axios";
import { Tokens, TokensResponse } from "../../model/auth";

export default class RefreshOtpTokenRequest extends BaseRequest<
  TokensResponse,
  TokensResponse
> {
  constructor(tokens: Tokens) {
    super("POST", `/refresh-otp-token/`, { payload: tokens });
  }

  processResponse(response: AxiosResponse<TokensResponse>): TokensResponse {
    const result = response.data;
    return result;
  }
}
