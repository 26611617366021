import { FC, useState } from "react";
import { TooltipProps } from "./Tooltip";
import PopupCustomArrow from "./PopupCustomArrow";
interface CustomClickPopupProps
  extends Omit<
    TooltipProps,
    "onOpen" | "onClose" | "open" | "on" | "children"
  > {
  children(closePopup: () => void): JSX.Element;
  onToggle?: (open: boolean) => void;
}

const CustomClickPopup: FC<CustomClickPopupProps> = ({
  children,
  onToggle,
  ...props
}) => {
  const [open, setOpen] = useState(false);

  return (
    <PopupCustomArrow
      {...props}
      open={open}
      on="click"
      onOpen={() => {
        setOpen(true);
        onToggle && onToggle(true);
      }}
      onClose={() => {
        setOpen(false);
        onToggle && onToggle(false);
      }}
      offsetY={10}
    >
      {children(() => setOpen(false))}
    </PopupCustomArrow>
  );
};

export default CustomClickPopup;
