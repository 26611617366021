import { atom, AtomEffect, selector } from "recoil";

import MeClient from "../../communication/clients/me-client";
import { SurveyUser } from "../../model/user";
import { localStorageWithTransformationEffect } from "../effects/local-storage-effect";
import { useAuth } from "../../hooks/auth-hooks";

export const myInfoAtom = atom<SurveyUser | null>({
  key: "myInfoAtom",
  default: selector({
    key: "myInfoAtom/default",
    get: async () => {
      try {
        // eslint-disable-next-line react-hooks/rules-of-hooks
        const { getAccessToken } = useAuth();
        const accessToken = await getAccessToken();
        return await MeClient.Instance.getMyInfo(accessToken);
      } catch (error) {
        console.error(`error while trying to fetch my info`, error);
        return null;
      }
    },
  }),
  effects: [
    localStorageWithTransformationEffect(
      (object: unknown) =>
        JSON.stringify((object as SurveyUser | null)?.locale),
      null
    )("locale") as AtomEffect<SurveyUser | null>,
  ],
});
