import axios, { AxiosInstance, AxiosError } from "axios";
import { toastWarning } from "../../utils/toaster";
import { eventEmitter } from "../../utils/event-emitter";
import BaseRequest from "../requests/base-request";

export default class BaseClient {
  protected axiosInstance: AxiosInstance;

  protected constructor(baseUrl: string) {
    this.axiosInstance = axios.create({
      baseURL: baseUrl,
    });
    this.setExpiredTokenInterceptor();
    this.setReadOnlyModeInterceptor();
  }

  protected async doRequest<S, T>(
    request: BaseRequest<S, T>
  ): Promise<T | null> {
    try {
      const response = await this.axiosInstance.request(
        await request.toRequest()
      );

      return request.processResponse(response);
    } catch (ex) {
      this.handleError(ex as AxiosError);
    }
    return Promise.resolve(null);
  }

  protected handleError(error: AxiosError): void {
    if (error.response) {
      // The request was made and the server responded with a status code
      // that falls out of the range of 2xx
      console.group("HTTP Error");
      console.error(error.response.data);
      console.error(error.response.status);
      console.error(error.response.headers);
      console.groupEnd();
      throw new Error(
        (error.response?.data as any)?.message ||
          (error.response?.data as any)?.detail ||
          "Server encountered an error while processing the request"
      );
    } else if (error.request) {
      // The request was made but no response was received
      // `error.request` is an instance of XMLHttpRequest in the browser and an instance of
      // http.ClientRequest in node.js
      console.error(error.request);
      throw new Error("No response was received");
    } else {
      // Something happened in setting up the request that triggered an Error
      console.error(error.message);
      throw new Error("Unknown error");
    }
  }

  private setExpiredTokenInterceptor() {
    this.axiosInstance.interceptors.response.use(
      response => {
        return response;
      },
      error => {
        if (
          error.response.data.message === "Unknown or invalid refresh token." &&
          error.response.data.code === "invalid_grant"
        ) {
          eventEmitter.emit("REFRESH_TOKEN_EXPIRED");
        }
        return Promise.reject(error);
      }
    );
  }

  private setReadOnlyModeInterceptor() {
    this.axiosInstance.interceptors.response.use(
      response => {
        return response;
      },
      error => {
        if (
          error.response.data.detail === "Read only mode" &&
          error.response.status === 403
        ) {
          toastWarning(`Operation failed: ${error.response.data.detail}`);
          return Promise.resolve(error);
        }
        return Promise.reject(error);
      }
    );
  }
}
