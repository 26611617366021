import { FC, useEffect } from "react";
import { useRecoilCallback, useRecoilState, useRecoilValue } from "recoil";
import { Form } from "react-bootstrap";
import { t } from "i18next";
import { NumericFormat } from "react-number-format";
import styled from "styled-components";

import {
  DEFAULT_FORMAT_LOCALE,
  NumberParser,
  QuestionResponseType,
} from "visible-ui";

import {
  invalidAnswersAtomFamily,
  surveyAnswersMap,
} from "../../state/atoms/survey-responses-atoms";
import {
  SurveyQuestion,
  QuestionValidationType,
  QuestionSubValidationTypes,
  SurveyAnswer,
} from "../../model/survey";
import CurrencySelector from "./CurrencySelector";

const NUMBER_REGEX = /^\s*[+-]?(\d+|\.\d+|\d+\.\d+|\d+\.)(e[+-]?\d+)?\s*$/;

const StyledSection = styled.section<{ $isSuffix: boolean }>`
  width: 100%;
  max-width: ${({ $isSuffix }) => ($isSuffix ? "270px" : "")}; ;
`;

interface FreeTextSurveyResponseQuestionProps {
  question: SurveyQuestion;
  answerValue: string[] | undefined;
  onFreeTextChange?: (newValue: string, isInvalid: boolean) => void;
  onCurrencyChange: (newValue: string) => void;
  disabled?: boolean;
  currentAnswer?: SurveyAnswer;
}

const FreeTextSurveyResponseQuestion: FC<
  FreeTextSurveyResponseQuestionProps
> = ({
  question,
  answerValue,
  onFreeTextChange,
  disabled,
  currentAnswer,
  onCurrencyChange,
}) => {
  // deconstructing question
  const {
    id: questionId,
    validation_type,
    response_type,
    question_number,
    sub_validations,
  } = question;

  const [{ valid, message: errorMessage }, setInvalidAnswer] = useRecoilState(
    invalidAnswersAtomFamily(questionId)
  );
  const { answersMap } = useRecoilValue(surveyAnswersMap);

  // check if the answer is valid on page load
  useEffect(() => {
    if (answerValue && answerValue.length > 0) {
      validateInput(answerValue[0]);
    }
  }, []);

  const validateSubValidation = useRecoilCallback(
    ({ set }) =>
      (answer: string) => {
        let errorsMap: Record<string, Array<string>> = {};
        sub_validations?.forEach(subValidation => {
          const relatedAnswer = answersMap?.get(
            subValidation.related_question_id
          );
          if (relatedAnswer) {
            switch (subValidation.validation_type) {
              case QuestionSubValidationTypes.greater_or_equal:
                if (
                  relatedAnswer.value &&
                  relatedAnswer.value[0] &&
                  Number(answer) < Number(relatedAnswer.value)
                ) {
                  errorsMap[questionId] = [
                    ...(errorsMap[questionId] || []),
                    t("validationMessage.smaller", {
                      questionNumber: question_number + 1,
                    }),
                  ];
                  errorsMap[questionId] = [
                    ...(errorsMap[subValidation.related_question_id] || []),
                    t("validationMessage.larger", {
                      questionNumber: subValidation.related_question_number + 1,
                    }),
                  ];
                }
                break;
              case QuestionSubValidationTypes.lesser_or_equal:
                if (
                  relatedAnswer.value &&
                  relatedAnswer.value[0] &&
                  Number(answer) > Number(relatedAnswer.value)
                ) {
                  errorsMap[questionId] = [
                    ...(errorsMap[questionId] || []),
                    `Your answer must be larger than your answer to question ${
                      question_number + 1
                    }`,
                  ];
                  errorsMap[questionId] = [
                    ...(errorsMap[subValidation.related_question_id] || []),
                    t("validationMessage.smaller", {
                      questionNumber: subValidation.related_question_number + 1,
                    }),
                  ];
                }
                break;
            }
          }
        });
        if (Object.entries(errorsMap).length > 0) {
          for (const [questionId, errors] of Object.entries(errorsMap)) {
            set(invalidAnswersAtomFamily(questionId), {
              valid: errors.length === 0,
              message: errors.join(",") || "",
            });
          }
        } else {
          set(invalidAnswersAtomFamily(questionId), {
            valid: true,
            message: "",
          });
          sub_validations?.forEach(_ =>
            set(invalidAnswersAtomFamily(_.related_question_id), {
              valid: true,
              message: "",
            })
          );
        }
      },
    [answersMap, sub_validations]
  );

  const validateInput = (input: string) => {
    const parser = new NumberParser(DEFAULT_FORMAT_LOCALE);
    switch (validation_type) {
      case QuestionValidationType.numeric:
        if (
          !NUMBER_REGEX.test(parser.parse(input).toString()) &&
          !!parser.parse(input)
        ) {
          setInvalidAnswer({ valid: false, message: "fillSurvey.mustNumber" });
        } else {
          setInvalidAnswer({ valid: true, message: "" });
        }
        break;
      case QuestionValidationType.sub_validation:
        validateSubValidation(input);
        break;
    }
  };

  const isSuffix =
    response_type === QuestionResponseType.PERCENTAGE ||
    response_type === QuestionResponseType.SHORT_ANSWER_CURRENCY;

  const maxLength = response_type === QuestionResponseType.PERCENTAGE ? 7 : 1000

  return (
    <section
      key={`question-response-answer-ft-${questionId}`}
      className="d-flex align-items-start gap-2" // use align-items-start to align the currency selector up when has error message
    >
      <StyledSection className="flex-row" $isSuffix={isSuffix}>
        {question.response_type === QuestionResponseType.PERCENTAGE ||
        question.validation_type === QuestionValidationType.numeric ? (
          <NumericFormat
            value={
              answerValue && answerValue.length && !disabled
                ? answerValue[0]
                : ""
            }
            allowLeadingZeros={false}
            thousandSeparator=","
            maxLength={maxLength}
            customInput={Form.Control}
            className="resize-none mt-0"
            onChange={({
              currentTarget: { value },
            }: React.ChangeEvent<HTMLInputElement>) => {
              validateInput(value);
              onFreeTextChange && onFreeTextChange(value, !valid);
            }}
            isInvalid={!valid && !disabled}
            data-testid={`question-response-answer-ft-${question.id}`}
            disabled={disabled}
          />
        ) : (
          <Form.Control
            type="text"
            as={
              question.response_type === QuestionResponseType.FREE_TEXT
                ? "textarea"
                : undefined
            }
            rows={4}
            className="resize-none mt-0"
            value={!disabled ? answerValue : ""}
            onChange={({ currentTarget: { value } }) => {
              validateInput(value);
              onFreeTextChange && onFreeTextChange(value, !valid);
            }}
            isInvalid={!valid && !disabled}
            data-testid={`question-response-answer-ft-${question.id}`}
            disabled={disabled}
          />
        )}

        <Form.Control.Feedback
          type="invalid"
          data-testid={`free-text-question-invalid-feedback-${questionId}`}
        >
          <>{(t && t(errorMessage)) || "Must be a number"}</>
        </Form.Control.Feedback>
      </StyledSection>
      {response_type === QuestionResponseType.PERCENTAGE && (
        <span
          className="d-flex align-items-center"
          style={{
            height: "40.5px", // same as the height of the input
          }}
        >
          %
        </span>
      )}
      {response_type === QuestionResponseType.SHORT_ANSWER_CURRENCY && (
        <CurrencySelector
          questionId={questionId}
          onCurrencyChange={onCurrencyChange}
          style={{ width: "176px" }}
          className={"cursor-pointer"}
        />
      )}
    </section>
  );
};

export default FreeTextSurveyResponseQuestion;
