import { SurveyUser } from "../model/user";
import { myInfoAtom } from "../state/atoms/me-atoms";
import { useStateLoadable } from "./use-state-loadable";

export const useMe = () => {
  const {
    error,
    loading,
    setState: setMe,
    state: me,
  } = useStateLoadable<SurveyUser>(myInfoAtom);

  return {
    error,
    loading,
    me,
    setMe,
  };
};
