import ContentLoader from "react-content-loader";

const TextInputLoader = () => (
  <ContentLoader
    speed={1.3}
    width="100%"
    height={40.5}
    backgroundColor="var(--grey-2)"
    foregroundColor="var(--grey-1)"
  >
    <rect x="0" ry="0.375rem" rx="0.375rem" width="100%" height="100%" />
  </ContentLoader>
);

export default TextInputLoader;
