import { selector } from "recoil";
import { SurveyUser } from "../../model/user";
import { myInfoAtom } from "../atoms/me-atoms";
import { allOrganizationUsersAtom } from "../atoms/survey-users-atoms";


export const allOrganizationUsersWithoutMeSelector = selector<SurveyUser[]>({
  key: "allOrganizationUsersWithoutMeSelector",
  get: async ({ get }) => {
    const myInfo = get(myInfoAtom);
    return get(allOrganizationUsersAtom).filter(({ id }) => id !== myInfo?.id);
  },
});
