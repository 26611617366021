import { FC } from "react";
import styled from "styled-components";

const CircleIconButtonStyled = styled.span`
  transition: background-color 0.3s, opacity 0.3s;
  cursor: pointer;
  border-radius: 50%;
  /* Todo: get by size */
  height: 42px;
  width: 42px;
  opacity: 0.8;
  &:hover,
  &.active {
    opacity: 1;
    background-color: var(--grey-2);
  }
`;

interface CircleIconButtonProps {
  icon: React.ReactNode;
  active?: boolean;
}

const CircleIconButton: FC<CircleIconButtonProps> = ({
  icon,
  active = false,
}) => {
  return (
    <CircleIconButtonStyled
      className={`d-flex align-items-center justify-content-center ${
        active ? "active" : ""
      }`}
    >
      {icon}
    </CircleIconButtonStyled>
  );
};

export default CircleIconButton;
