export enum CyberRating {
  CR1 = "1",
  CR2 = "2",
  CR3 = "3",
  CR4 = "4",
  CR5 = "5",
  CR6 = "6",
  CR7 = "7",
  CR8 = "8",
}
export interface EngagementRating {
  engagement_id: string;
  rating: CyberRating;
  attacker_score: number;
  risk_management_score: number;
  governance_score: number;
}
