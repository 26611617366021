export enum LocaleCodes {
  en = "en",
  zh = "zh",
  ja = "ja",
  ko = "ko",
  fr = "fr",
  es = "es",
  pt = "pt",
  ar = "ar",
}
