import { FC } from "react";
import BaseComponentProps from "../../../base-component-props";

interface ExternalLinkProps extends BaseComponentProps {
  linkAddress: string;
  prefixUrl?: string;
  mailto?: boolean;
  txt?: string;
}

const ExternalLink: FC<ExternalLinkProps> = ({
  linkAddress,
  mailto = false,
  txt,
  className,
  prefixUrl = "",
}) => {
  return (
    <a
      target="_blank"
      rel="noreferrer"
      href={`${mailto ? "mailto:" : ""}${prefixUrl}${linkAddress}`}
      className={className}
    >
      {txt || linkAddress}
    </a>
  );
};

export default ExternalLink;
