import { AxiosResponse } from "axios";
import { GenericResponse } from "../../model/base";
import BaseAuthorizedRequest from "../requests/base-authorized-request";

export default class AbortMultiPartUploadRequest extends BaseAuthorizedRequest<
  GenericResponse,
  GenericResponse
> {
  constructor(uploadId: string, fileName: string) {
    super("PUT", `/static/url/multi_part/abort`, {
      payload: {
        upload_id: uploadId,
        file_name: fileName,
      },
    });
  }

  processResponse(response: AxiosResponse<GenericResponse>): GenericResponse {
    const result = response.data;
    return result;
  }
}
