import { BaseClient, GenericResponse } from "visible-ui";
import { ShareSurveyResponse, SurveyUser } from "../../model/user";
import DeactivateUser from "../survey-users/deactivate-user";
import GetAllOrganizationUsers from "../survey-users/get-all-organization-users";
import PostShareSurvey from "../survey-users/post-share-survey";
import PutShareSurvey from "../survey-users/put-share-survey";

export default class SurveyUsersClient extends BaseClient {
  protected static instance: SurveyUsersClient;

  protected constructor() {
    super("/api/v1/survey/users");
  }

  public static get Instance() {
    return this.instance || (this.instance = new SurveyUsersClient());
  }

  public async getAllOrganizationUsers(
    accessToken: string
  ): Promise<SurveyUser[] | null> {
    return this.doRequest(new GetAllOrganizationUsers(accessToken));
  }

  public async shareSurvey(
    accessToken: string,
    user: SurveyUser,
    personalMessage?: string
  ): Promise<ShareSurveyResponse | null> {
    return await this.doRequest(
      new PostShareSurvey(accessToken, user, personalMessage)
    );
  }

  public async updateSharedUser(
    accessToken: string,
    user: SurveyUser
  ): Promise<GenericResponse | null> {
    return await this.doRequest(new PutShareSurvey(accessToken, user));
  }

  public async deactivateUser(
    accessToken: string,
    user: SurveyUser
  ): Promise<GenericResponse | null> {
    return await this.doRequest(new DeactivateUser(accessToken, user));
  }
}
