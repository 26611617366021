export interface BaseOidModel {
  _id?: string;
}
export interface GenericResponse extends BaseOidModel {
  success: boolean;
  error_message?: string;
  error_code?: number;
  exception?: string;
}
export interface GenericUpdateResponse {
  matched_count: number;
  modified_count: number;
  acknowledged: boolean;
}
export interface GenericPaginatedResponse {
  data?: any[];
  total_count: number;
  offset: number;
  limit: number;
}

export interface BackgroundTaskResult<T = any> {
  status: BackgroundTaskStatus;
  data: T;
}

export enum BackgroundTaskStatus {
  PENDING = "PENDING",
  RUNNING = "RUNNING",
  SUCCEEDED = "SUCCEEDED",
  FAILED = "FAILED",
}

export type TriggerElement = (
  handleClickOpen: (event: React.MouseEvent<HTMLElement>) => void,
  active?: boolean
) => JSX.Element;
