import { forwardRef, useEffect, useState } from "react";
import TextInput, { InputType } from "../TextInput/TextInput";
import MultipleChoiceBaseOption, {
  FormCheckInputType,
  MultipleChoiceBaseOptionProps,
  MultipleChoiceViewOnlyOptionProps,
} from "./MultipleChoiceBaseOption";

export interface MultipleChoiceOptionProps
  extends MultipleChoiceBaseOptionProps,
    MultipleChoiceViewOnlyOptionProps {
  groupName?: string;
  name?: string;
  onBlur?: (e: React.FocusEvent<HTMLInputElement>) => void;
  onChange?: (e: React.ChangeEvent<HTMLInputElement>) => void;
  label?: JSX.Element | string;
  type?: FormCheckInputType;
}

const MultipleChoiceOption = forwardRef<
  HTMLInputElement,
  MultipleChoiceOptionProps
>(
  (
    {
      groupName,
      readOnly,
      answers,
      name,
      onBlur,
      checked,
      disabled,
      onChange,
      label,
      type,
      checkClassName,
    },
    ref
  ) => {
    const [answerValue, setAnswerValue] = useState(answers);

    useEffect(() => {
      setAnswerValue(answers);
    }, [answers]);

    return (
      <MultipleChoiceBaseOption
        groupName={groupName}
        readOnly={readOnly}
        checked={checked}
        onChange={onChange}
        type={type}
        checkClassName={checkClassName}
      >
        <div className="d-flex align-items-center gap-3">
          {label}
          <TextInput
            formGroupWrapperClassName="mb-0 w-100"
            style={{ maxWidth: "365px" }}
            size="sm"
            name={name}
            type={InputType.Text}
            ref={ref}
            value={answerValue && answerValue[0]}
            onChange={e => setAnswerValue([e.target.value])}
            onBlur={onBlur}
            readOnly={readOnly}
            disabled={disabled}
            className={label ? "ms-2" : ""}
            maxLength={255}
          />
        </div>
      </MultipleChoiceBaseOption>
    );
  }
);
export default MultipleChoiceOption;
