import { FC } from "react";
import { useTranslation } from "react-i18next";
import {
  FormCheckInputType,
  MultipleChoiceBaseOption,
  MultipleChoiceOption,
} from "visible-ui";
import {
  SurveyAnswer,
  SurveyBaseAnswer,
  SurveyQuestion,
} from "../../model/survey";

interface MultiChoiceSurveyQuestionProps {
  type: FormCheckInputType;
  question: SurveyQuestion;
  groupName: string;
  currentAnswer: SurveyAnswer | undefined;
  checkedIds: Array<string | undefined>;
  otherOptionOnChange?: (
    answerId: string | undefined,
    checked?: boolean
  ) => void;
  otherOptionOnBlur?: (answer: SurveyBaseAnswer, newValue: string) => void;
  onChange?: (answer: SurveyBaseAnswer, checked?: boolean) => void;
  disabled?: boolean;
}

const MultiChoiceSurveyQuestion: FC<MultiChoiceSurveyQuestionProps> = ({
  type,
  question,
  groupName,
  currentAnswer,
  checkedIds,
  otherOptionOnChange,
  otherOptionOnBlur,
  onChange,
  disabled,
}) => {
  const { t } = useTranslation();
  return (
    <div className="d-flex flex-column gap-2">
      {question.possible_answers.map(
        (answer: SurveyBaseAnswer, idx: number) => {
          const checked = checkedIds.includes(answer.id);
          return answer.is_other ? (
            <MultipleChoiceOption
              className="my-auto"
              groupName={groupName}
              key={`question-response-answer-mc-${question.id}-${idx}`}
              answers={
                disabled
                  ? [""]
                  : currentAnswer?.is_other
                  ? currentAnswer?.value || [""]
                  : [""]
              }
              checked={checked && !disabled}
              disabled={!checked || disabled}
              onChange={e =>
                otherOptionOnChange &&
                otherOptionOnChange(answer.id, e.target.checked)
              }
              onBlur={e =>
                otherOptionOnBlur && otherOptionOnBlur(answer, e.target.value)
              }
              label={
                <span className={`no-wrap ${checked ? "fw-bold" : ""}`}>
                  {t("fillSurvey.other")}
                </span>
              }
              type={type}
              checkClassName="me-1"
            />
          ) : (
            <MultipleChoiceBaseOption
              id={`${question.id}-${idx}`}
              key={`question-response-answer-mc-${question.id}-${idx}`}
              checked={checked && !disabled}
              groupName={groupName}
              onChange={e => onChange && onChange(answer, e.target.checked)}
              type={type}
              checkClassName="me-1"
            >
              <span className={checked && !disabled ? "fw-bold" : ""}>
                {answer.value && answer.value[0]}
              </span>
            </MultipleChoiceBaseOption>
          );
        }
      )}
    </div>
  );
};

export default MultiChoiceSurveyQuestion;
