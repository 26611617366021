import { FC, RefObject } from "react";
import { Card } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import styled from "styled-components";
import {
  QuestionDependencyStatus,
  SurveyQuestion,
} from "../../../model/survey";

interface QuestionCardStyledProps {
  $isQuestionAnswered: boolean;
  $disabled: boolean;
}

const QuestionCardStyled = styled(Card)<QuestionCardStyledProps>`
  position: relative;
  opacity: ${({ $disabled }) => ($disabled ? 0.4 : 1)};
  /* A pseudo element to cover the card to stop click events */
  &::after {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: ${({ $disabled }) => ($disabled ? 1 : -1)};
  }
  &::before {
    transition: height 0.3s;
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    height: ${({ $isQuestionAnswered }) =>
      $isQuestionAnswered ? "100%" : "0"};
    width: 5px;
    background-color: var(--bs-success);
    border-radius: 0.5rem 0 0 0.5rem;
  }
`;

interface QuestionCardProps {
  questionDependentOn?: SurveyQuestion;
  isQuestionAnsweredValue?: boolean;
  disabled: boolean;
  children: React.ReactNode;
  questionDependencyStatus: QuestionDependencyStatus;
  cardRef?: RefObject<HTMLDivElement>;
}

const SurveyResponseQuestionCard: FC<QuestionCardProps> = ({
  children,
  disabled,
  questionDependentOn,
  isQuestionAnsweredValue,
  questionDependencyStatus,
  cardRef,
}) => {
  const { t } = useTranslation();

  return (
    <QuestionCardStyled
      title={
        disabled && questionDependentOn
          ? t("generalSurvey.dependentOnTitle", {
              num: questionDependentOn.question_absolute_number + 1,
            })
          : undefined
      }
      $disabled={disabled}
      $isQuestionAnswered={
        (isQuestionAnsweredValue &&
          questionDependencyStatus !== QuestionDependencyStatus.DISABLED) ||
        questionDependencyStatus === QuestionDependencyStatus.IRRELEVANT
      }
      body
      data-testid="question-card"
      ref={cardRef}
      className="scroll-with-topbar-offset"
      children={children}
    />
  );
};

export default SurveyResponseQuestionCard;
