import { FC, useEffect, useState } from "react";
import { BaseTableCellModel } from "../../../../model/table";
import { numberFormatter } from "../../../../utils/formatting-utils";

const BaseTableCell: FC<BaseTableCellModel> = ({
  node,
  style,
  className,
  value,
  elementProps = {},
  Element = "span",
}) => {
  const _elementProps = { className: "text-truncate", ...elementProps };
  const [finalValue, setFinalValue] = useState(value);

  useEffect(() => {
    if (Array.isArray(value)) {
      setFinalValue(value.join(", "));
    } else if (typeof value === "number") {
      setFinalValue(numberFormatter.format(value));
    } else {
      setFinalValue(value);
    }
  }, [value]);

  return (
    <td className={`${className} fs-5 p-3 text-gray-900`} style={style}>
      {node ? node : <Element {..._elementProps}>{finalValue}</Element>}
    </td>
  );
};

export default BaseTableCell;
