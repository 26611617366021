import { FC } from "react";
import styled from "styled-components";

const JsonPreStyled = styled.pre<{ maxHeight?: string }>`
  max-height: ${p => p.maxHeight};
  font-family: CourierNewPSMT;
  /* Todo: add shadow scroll effect */
`;

interface JsonPreProps {
  jsonAsObject?: Record<string, any> | Record<string, any>[];
  maxHeight?: string;
}

export const JsonPre: FC<JsonPreProps> = ({ jsonAsObject, maxHeight }) => {
  return (
    <JsonPreStyled
      data-testid="json-pre"
      className="vr-card p-3 text-gray-900"
      maxHeight={maxHeight}
    >
      {JSON.stringify(jsonAsObject, null, 2)}
    </JsonPreStyled>
  );
};
