import { AxiosResponse } from "axios";
import { User } from "../../model/auth";
import BaseAuthorizedRequest from "../requests/base-authorized-request";

export default class GetUsersOtpRequest extends BaseAuthorizedRequest<
  User[],
  User[]
> {
  constructor(fullData: boolean, organizationId?: string) {
    const parameters = { full_data: fullData };
    super(
      "GET",
      `/all`,
      organizationId
        ? { parameters: { ...parameters, organization_id: organizationId } }
        : { parameters }
    );
  }

  processResponse(response: AxiosResponse<User[]>): User[] {
    const result = response.data;
    return result;
  }
}
