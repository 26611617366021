import { AxiosResponse } from "axios";
import { BaseAuthorizedRequest, GenericResponse } from "visible-ui";
import { SurveyAnswer } from "../../model/survey";

export default class ChangeAnswerableStatus extends BaseAuthorizedRequest<
  GenericResponse,
  GenericResponse
> {
  constructor(reason: SurveyAnswer, accessToken: string) {
    super(
      "PUT",
      `/answers/status`,
      {
        payload: { answers: [reason] },
      },
      accessToken
    );
  }

  processResponse({ data }: AxiosResponse<GenericResponse>): GenericResponse {
    return data;
  }
}
