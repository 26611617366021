import { TaskGroups } from "./tools/base";

export enum TabCollectionSteps {
  FirstStep = "first-step-status",
  SecondStep = "second-step-status",
  ThirdStep = "third-step-status",
}

export enum CollectionType {
  VRCloud = "vr_cloud",
  VRBox = "vr_box",
  FilesAndQuestionnaires = "questionnaires",
}
export interface DynamicCollectionStatusTxt {
  [VRToolStatus.PreInstall]: string;
  [VRToolStatus.Gathering]: string;
  [VRToolStatus.ReadyToConfig]: string;
}

export enum VRToolStatus {
  PreInstall = "pre-install",
  Gathering = "gathering",
  ReadyToConfig = "ready-to-config",
}

export interface CollectionStatusMap {
  [CollectionType.FilesAndQuestionnaires]: TabCollectionSteps;
  [CollectionType.VRCloud]: TabCollectionSteps;
  [CollectionType.VRBox]: TabCollectionSteps;
}

export interface CollectionStatusModel {
  step: TabCollectionSteps;
  title: string;
  subtitle: string;
  btnTxt: string;
  type: CollectionType;
}

export type TaskGroupsTypes = CollectionType.VRBox | CollectionType.VRCloud;

export const TaskGroupsMap: Record<TaskGroupsTypes, TaskGroups[]> = {
  [CollectionType.VRCloud]: [
    TaskGroups.Cloud,
    TaskGroups.Email,
    TaskGroups.EDR,
    TaskGroups.SIEM,
    TaskGroups.CloudIDP,
  ],
  [CollectionType.VRBox]: [
    TaskGroups.DataBase,
    TaskGroups.Linux,
    TaskGroups.Virtualization,
    TaskGroups.Exfil,
    TaskGroups.Windows,
    TaskGroups.Sccm,
    TaskGroups.OnPremIDP,
  ],
};

export const COLLECTOR_GROUPS_TYPE_NAME: Record<TaskGroupsTypes, string> = {
  [CollectionType.VRCloud]: "Cloud-to-Cloud Tools",
  [CollectionType.VRBox]: "On-Prem Tools",
};

export const COLLECTOR_GROUPS_TYPE_BASE_NAME: Record<TaskGroupsTypes, string> =
  {
    [CollectionType.VRCloud]: "Cloud-to-Cloud",
    [CollectionType.VRBox]: "On-Prem",
  };
