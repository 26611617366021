import { FC } from "react";
import { Col } from "react-bootstrap";
import BaseComponentProps from "../../../base-component-props";

export interface CircleButtonProps extends BaseComponentProps {
  color?: string;
  width?: string;
  height?: string;
}

const CircleButton: FC<CircleButtonProps> = ({
  onClick,
  className,
  color = "--bs-blue",
  width = "38px",
  height = "38px",
}) => {
  return (
    <Col
      className="d-flex col-md-1 me-3 rounded-circle align-items-center"
      style={{ background: `var(${color})`, width: width, height: height }}
    >
      <span
        onClick={onClick}
        className={`fe ${className} mr-4 text-white font-size-lg`}
      />
    </Col>
  );
};

export default CircleButton;
