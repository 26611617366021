import { FC, useState } from "react";
import { Nav, NavLinkProps } from "react-bootstrap";
import styled from "styled-components";

const StyledNavItem = styled(Nav.Item)`
  height: 2rem;
`;

const StyledNavLink = styled.div`
  width: 194px;
  padding: 0.25rem 1rem;
  border-radius: 0.5rem;
  color: var(--grey-7);
  transition: background-color 0.15s ease-in-out;
  ${({ $activeTab }: { $activeTab: boolean }) =>
    $activeTab
      ? "background-color: #fff;color: var(--grey-8);font-weight: 600;"
      : ""}
`;

export type NavPillItemProps = NavLinkProps & {
  text: string;
  alerted?: boolean;
  active: boolean;
  customOnClick?: () => void;
};

const NavPillItem: FC<NavPillItemProps> = ({
  text,
  alerted,
  active,
  customOnClick,
}) => {
  const [isAlerted, setIsAlerted] = useState(alerted);

  return (
    <StyledNavItem className="d-flex align-items-center my-auto cursor-pointer">
      <StyledNavLink
        className="text-center"
        $activeTab={active}
        onClick={() => {
          setIsAlerted(false);
          customOnClick && customOnClick();
        }}
      >
        {text}
        {isAlerted && (
          <sup className="fs-2 text-primary" style={{ verticalAlign: "sub" }}>
            •
          </sup>
        )}
      </StyledNavLink>
    </StyledNavItem>
  );
};

export default NavPillItem;
