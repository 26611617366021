import { FC } from "react";
import BaseComponentProps from "../../../base-component-props";

const EllipsisCard: FC<BaseComponentProps> = props => (
  <section
    {...props}
    className={`vr-card-light py-3 d-flex align-items-center justify-content-center gap-2 ${props.className}`}
  >
    {[...Array(3).keys()].map(_ => (
      <span
        key={`ellipsis-circle-${_}`}
        className="rounded-circle bg-dark-soft p-1"
      />
    ))}
  </section>
);

export default EllipsisCard;
