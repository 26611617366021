import { FC } from "react";
import { Row } from "react-bootstrap";
import Avatar from "../base/avatar/Avatar";
import { User } from "../../model/auth";
import BaseBadge from "../base/base-badge/BaseBadge";

interface UserRowProps {
  user: Omit<User, 'metadata' | 'handler'>;
  idx: number;
  role: JSX.Element;
  moreOptions?: JSX.Element;
}

const UserRow: FC<UserRowProps> = ({ user, idx, role, moreOptions }) => {
  const { email, full_name, super_mode, permissions } = user;

  return (
    <section
      data-mabl={`${full_name
        .toLowerCase()
        .split(" ")
        .filter(_ => _)
        .join("_")}_row`}
      data-cy="user-row"
      className={`d-flex align-items-center justify-content-between py-4 ${
        idx ? "border-top" : ""
      }`}
    >
      <section className="d-flex gap-3">
        <Avatar
          className="p-0"
          userInfo={{
            email: email,
            fullName: full_name,
          }}
          colorById={idx}
          vrMode={super_mode}
        />
        <span>
          <Row>
            <span className="fw-bold">
              {full_name}
              {permissions?.includes("client:champion") ? (
                <BaseBadge soft={true} bg="primary" className="ms-3 mb-1">
                  Owner
                </BaseBadge>
              ) : (
                ""
              )}
            </span>
          </Row>
          <Row>
            <span className="text-gray-700">{email}</span>
          </Row>
        </span>
      </section>
      <section className="d-flex align-items-center gap-3">
        {role}
        {moreOptions}
      </section>
    </section>
  );
};

export default UserRow;
