import { FC } from "react";
import { Nav, NavProps } from "react-bootstrap";
import styled from "styled-components";
import BaseComponentProps from "../../../base-component-props";
import NavPillItem, { NavPillItemProps } from "./NavPillItem";

const StickyNav = styled(Nav)`
  top: -60px;
  position: fixed;
  height: 60px;
  width: calc(100% - 67px);
  display: flex;
  justify-content: center;
  align-items: center;
  transition: top 0.3s;
  &.sticky-mode {
    top: 0px;
  }
  > section {
    padding: 6px;
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: var(--grey-3);
    min-width: 100%;
    min-height: 100%;
    border-radius: 0;
  }
`;

export interface NavPillsProps extends BaseComponentProps {
  items: Array<NavPillItemProps>;
  show: boolean;
}

const NavPills: FC<NavPillsProps & NavProps> = ({ show, items }) => {
  return (
    <StickyNav
      variant="pills"
      className={`z-sticky ${show ? "sticky-mode" : ""}`}
    >
      <section>
        {items.map((item, idx) => (
          <NavPillItem
            text={item.text}
            key={`nav-pills-item-${item.text}-${idx}`}
            alerted={item.alerted}
            active={item.active}
            customOnClick={item.customOnClick}
          />
        ))}
      </section>
    </StickyNav>
  );
};

export default NavPills;
