import BaseAuthorizedRequest from "../requests/base-authorized-request";
import { AxiosResponse } from "axios";
import { User } from "../../model/auth";

export default class RegisterOtpUserRequest extends BaseAuthorizedRequest<
  User,
  User
> {
  constructor(user: User, send_email = true) {
    super("POST", `/register-otp-user/`, {
      payload: user,
      parameters: { send_email },
    });
  }

  processResponse(response: AxiosResponse<User>): User {
    return response.data;
  }
}
