import { CSSProperties, Suspense } from "react";
import { Card } from "react-bootstrap";
import Loading from "../../components/base/loading/Loading";

export const withSuspense = (
  component: JSX.Element,
  variant: "spinner" | "vr" = "vr"
): JSX.Element => (
  <Suspense
    fallback={
      <div className="center-container">
        <Loading variant={variant} />
      </div>
    }
  >
    {component}
  </Suspense>
);

export const withCard = (
  component: JSX.Element,
  key?: string,
  style?: CSSProperties,
  ref?: React.RefObject<HTMLDivElement>,
  onClick?: () => void,
  dataTestId?: string
): JSX.Element => (
  <Card
    body
    data-testid={dataTestId || "card"}
    ref={ref}
    className="scroll-with-topbar-offset"
    style={style}
    key={key}
    onClick={() => onClick && onClick()}
  >
    {component}
  </Card>
);

export const withCardSuspense = (
  component: JSX.Element,
  key?: string,
  style?: CSSProperties,
  ref?: React.RefObject<HTMLDivElement>,
  onClick?: () => void
): JSX.Element => withCard(withSuspense(component), key, style, ref, onClick);
