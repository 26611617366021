import { EngagementTaskType } from "./engagement-task";

export enum EngagementProgressState {
  pending = "pending",
  active = "active",
  completed = "completed",
}

export interface EngagementProgressComponent {
  name: string;
  total_items: number;
  completed_items: number;
  processed_items: number;
  type: EngagementTaskType;
}

export interface EngagementProgressStep {
  id: string;
  name: string;
  process_description: string;
  state: EngagementProgressState;
  order: number;
  components: Array<EngagementProgressComponent>;
  start_date: string;
  detailed_status?: string;
}

export interface EngagementProgress {
  engagement_id: string;
  steps: Array<EngagementProgressStep>;
}
