import { GenericResponse } from "./base";

export interface StaticAssetUrlResponse {
  response_url: string;
  file_name?: string;
}

export interface MultiPartUploadResponse {
  upload_id: string;
}

export enum AssetFileType {
  file = "file",
}

export interface BaseAssetFileData {
  name: string;
  size_kb: number;
  comment: string;
  type: AssetFileType;
}

export interface AssetFileCommitResponse extends GenericResponse {
  new_files: Array<AssetFileData>;
  missing_files: Array<string>;
}

export interface AssetFileData extends BaseAssetFileData {
  id: string;
  date: string;
  uploader_name: string;
  archived?: boolean;
  addedLocally?: boolean;
}

export interface countEngagementAssetFilesGroupByArchived {
  active_count: number;
  archived_count: number;
}

export enum BucketType {
  engagement_files = "engagement_files",
  kb = "kb",
  resources = "resources",
}
