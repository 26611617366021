import {BaseTaskDefinition} from "./task-definition";

export const DEFAULT_SAMPLE_SIZE = 50;

export type TaskExecutionParameters =
  | ExecutionParametersAWS
  | ExecutionParametersCrowdStrike
  | ExecutionParametersActiveDirectory
  | ExecutionParametersO365Email
  | MsalExecutionParametersCredential
  | ExecutionParametersSplunk
  | ExecutionParametersOkta
  | ExecutionParametersLinux
  | MateriaWorkflowTaskExecutionParameters
  | null; //  | more other types...

export interface ExecutionParametersCrowdStrike {
  client_id: string;
  client_secret: string;
  base_url: string;
  pagination_limit: number;
}

export interface ExecutionParametersCredentialsAWS {
  eid: string; // "89208f57-c13c-485c-b297-8b01b2a304d9";
  arn: string; // "arn:aws:iam::496304550227:role/VRAuditDevRole";
}

export interface ExecutionParametersAWS {
  credentials: ExecutionParametersCredentialsAWS[];
}

export interface ExecutionParametersActiveDirectory {
  dc_hostname: string;
  username: string;
  password: string;
  use_ldaps?: boolean; // true
  auth_method?: string; // "NTLM"
}

export enum O365EmailType {
  Sender = "SENDER",
  Recipient = "RECIPIENT",
}
export interface ExecutionParametersO365Email {
  sender_credentials: MsalExecutionParametersCredential[];
  receiver_credentials: MsalExecutionParametersCredential[];
}
export interface MsalExecutionParametersCredential {
  address: string;
  access_token: string;
  refresh_token: string;
}

export interface ExecutionParametersSplunk {
  host: string;
  port: string;
  protocol: "http" | "https";
  sample_size: number;
  token?: string;
  username?: string;
  password?: string;
}

export interface ExecutionParametersOkta {
  token: string;
  base_url: string;
  pagination_limit: number;
}

export type ExecutionParametersLinuxGroupType =
  | ExecutionParametersLinuxKeyGroup
  | ExecutionParametersLinuxPasswordGroup;
export interface ExecutionParametersLinuxHost {
  host: string;
  port: string;
}
export interface ExecutionParametersLinuxGroup {
  username: string;
  sudo_password: string;
  hosts: ExecutionParametersLinuxHost[];
}
export interface ExecutionParametersLinuxKeyGroup
  extends ExecutionParametersLinuxGroup {
  private_key: string | ArrayBuffer;
  private_key_passphrase: string;
}
export interface ExecutionParametersLinuxPasswordGroup
  extends ExecutionParametersLinuxGroup {
  password: string;
}
export interface ExecutionParametersLinux {
  key_servers_groups: ExecutionParametersLinuxKeyGroup[];
  password_servers_groups: ExecutionParametersLinuxPasswordGroup[];
}
export interface WorkflowTaskDefinition extends BaseTaskDefinition {
  requires?: [String];
}
export interface MateriaWorkflowCiExecutionParameters {
  collector: Omit<WorkflowTaskDefinition, "_id">;
  artifacts_indexer: Omit<WorkflowTaskDefinition, "_id">;
  artifacts_etl: Omit<WorkflowTaskDefinition, "_id">;
  analyzer: Omit<WorkflowTaskDefinition, "_id">;
}

export interface MateriaWorkflowTaskExecutionParameters {
  workflow: MateriaWorkflowCiExecutionParameters;
}
