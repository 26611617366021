import BaseClient from "./base-client";
import { GenericResponse } from "../../model/base";
import {
  BucketType,
  MultiPartUploadResponse,
  StaticAssetUrlResponse,
} from "../../model/static-assets";
import GenerateMultiPartUploadRequest from "../static-assets/generate-multi-part-upload-request";
import GeneratePreSignedDownloadLinkRequest from "../static-assets/generate-pre-signed-download-link-request";
import GeneratePreSignedUploadLinkRequest from "../static-assets/generate-pre-signed-upload-link-request";
import CompleteMultipartUploadRequest from "../static-assets/complete-multi-part-upload-request";
import { UploadPartResponse } from "../s3-utils";
import AbortMultiPartUploadRequest from "../static-assets/abort-multi-part-upload-request";

export default class AssetFilesClient extends BaseClient {
  protected static instance: AssetFilesClient;

  protected constructor() {
    super("/api/v1/asset_files");
  }

  public static get Instance(): AssetFilesClient {
    return this.instance || (this.instance = new AssetFilesClient());
  }

  public async generatePreSignedUploadLink(
    filesNames: Array<string>,
    uploadId?: string,
    chunkNumber?: number,
    fileSize?: number,
    fileMD5?: string
  ): Promise<StaticAssetUrlResponse[] | null> {
    return this.doRequest(
      new GeneratePreSignedUploadLinkRequest(
        filesNames,
        uploadId,
        chunkNumber,
        fileSize,
        fileMD5
      )
    );
  }

  public async generatePreSignedDownloadLink(
    filesNames: Array<string>,
    bucketType = BucketType.engagement_files
  ): Promise<StaticAssetUrlResponse[] | null> {
    return this.doRequest(
      new GeneratePreSignedDownloadLinkRequest(filesNames, bucketType)
    );
  }

  public async generateMultiPartUpload(
    fileName: string,
    fileType: string
  ): Promise<MultiPartUploadResponse | null> {
    return this.doRequest(
      new GenerateMultiPartUploadRequest(fileName, fileType)
    );
  }

  public async completeMultipartUpload(
    uploadId: string,
    fileName: string,
    parts: Array<UploadPartResponse>
  ): Promise<GenericResponse | null> {
    return this.doRequest(
      new CompleteMultipartUploadRequest(uploadId, fileName, parts)
    );
  }

  public async abortUpload(
    uploadId: string,
    fileName: string
  ): Promise<GenericResponse | null> {
    return this.doRequest(new AbortMultiPartUploadRequest(uploadId, fileName));
  }
}
