import { FC } from "react";
import ContentLoader from "react-content-loader";

interface UserListLoaderProps {
  animation?: boolean;
  noneYetTxt?: string;
}

const UserListLoader: FC<UserListLoaderProps> = ({
  animation = false,
  noneYetTxt = "None yet",
}) => {
  return (
    <section className="position-relative">
      {!animation && (
        <section className="center-container py-4 px-6 bg-light-soft text-gray-800 rounded-2">
          {noneYetTxt}
        </section>
      )}
      {[...Array(2).keys()].map((_, i) => (
        <section
          key={`content-loader-${i}`}
          className={`d-flex align-items-center justify-content-between py-3 ${
            i ? "border-top" : ""
          }`}
        >
          <section className="d-flex align-items-center gap-4">
            <ContentLoader
              speed={animation ? 1.3 : 0}
              width={48}
              height={48}
              backgroundColor="var(--grey-2)"
              foregroundColor="var(--grey-1)"
            >
              <circle cx="24" cy="24" r="24" />
            </ContentLoader>
            <ContentLoader
              speed={0}
              width={138}
              height={9}
              backgroundColor="var(--grey-2)"
              foregroundColor="var(--grey-1)"
            >
              <rect rx="0" ry="0" width="100%" height="100%" />
            </ContentLoader>
          </section>
          <ContentLoader
            speed={0}
            width={138}
            height={9}
            backgroundColor="var(--grey-2)"
            foregroundColor="var(--grey-1)"
          >
            <rect rx="0" ry="0" width="100%" height="100%" />
          </ContentLoader>
        </section>
      ))}
    </section>
  );
};

export default UserListLoader;
