import BaseRequest from "../requests/base-request";
import { AxiosResponse } from "axios";
import { GenericResponse } from "../../model";

export default class SubmitResetPasswordRequest extends BaseRequest<
  GenericResponse,
  GenericResponse
> {
  constructor(email: string) {
    super("POST", `/reset-password/`, { payload: { email } });
  }

  processResponse(response: AxiosResponse<GenericResponse>): GenericResponse {
    const result = { ...response.data, success: response.status === 200 };
    return result;
  }
}
