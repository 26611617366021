import React from "react";
import { createRoot } from "react-dom/client";
import { Auth0Provider } from "@auth0/auth0-react";
import { RecoilRoot } from "recoil";
import { BrowserRouter as Router } from "react-router-dom";
import { withSuspense } from "visible-ui";

import "./i18n/i18n";
import App from "./App";

import "visible-ui/dist/index.css";

const container = document.getElementById("root");
const root = createRoot(container!);

root.render(
  <React.StrictMode>
    <RecoilRoot>
      <Auth0Provider
        domain={process.env.REACT_APP_AUTH0_DOMAIN!}
        clientId={process.env.REACT_APP_AUTH0_CLIENT_ID!}
        audience={process.env.REACT_APP_AUTH0_AUDIENCE!}
        redirectUri={window.location.origin}
        useRefreshTokens
      >
        <Router>{withSuspense(<App />, "spinner")}</Router>
      </Auth0Provider>
    </RecoilRoot>
  </React.StrictMode>
);
