import { TaskExecution } from "./task-execution";
import { DateIosString } from "../../utils/time";
import {
  CollectorTypes,
  TaskDefinitionExecutionBaseModel,
  TaskStatus,
} from "./base";
import { TaskExecutionParameters } from "./execution-parameters";

export interface BaseTaskDefinition extends TaskDefinitionExecutionBaseModel {
  metadata: BaseTaskDefinitionMetadata;
  execution_parameters: TaskExecutionParameters;
  deleted: boolean;
  next_execution_time?: DateIosString; // "2021-10-29T08:32:24.308Z";
  box_id: string | undefined;
  enabled: boolean;
  interval_in_seconds?: number; // 900
}

export interface TaskDefinition extends BaseTaskDefinition {
  creation_time: DateIosString; // 2021-10-27T07:32:12.785Z
  disable_time?: DateIosString;
  update_time?: DateIosString;
  deletion_time?: DateIosString;
  executions_counter: number;
}

export type MandatoryTaskDefinition = Omit<
  BaseTaskDefinition,
  | "metadata"
  | "execution_parameters"
  | "deleted"
  | "next_execution_time"
  | "enabled"
  | "interval_in_seconds"
> &
  Partial<BaseTaskDefinition>;

export interface BaseTaskDefinitionMetadata {
  display_name?: string;
  initialized?: boolean;
  bitsight_entity_id?: string;
}

export interface TaskDefinitionCreationInfo {
  name: CollectorTypes;
  category: string;
  instanceId: string;
  displayName?: string;
  state: boolean;
  autoRun?: number;
  hour?: DateIosString;
  vrBox?: string;
  status?: boolean | TaskStatus;
}

export interface TaskDefinitionWithLastTaskExecution extends TaskDefinition {
  lastTaskExecution: TaskExecution | null
}

export const ONE_TIME_INTERVAL = 3153600000 // 100 years in seconds
