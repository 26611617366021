import BaseRequest, { BaseRequestArgs } from "./base-request";
import { AxiosRequestConfig, Method } from "axios";
import { getAuthToken } from "../../utils/session-persistency";
import { getIsEditModeLocalStorage } from "../../utils/authorizer";

export default abstract class BaseAuthorizedRequest<S, T> extends BaseRequest<
  S,
  T
> {
  private accessToken?: string;

  constructor(
    method: Method,
    url: string,
    options?: BaseRequestArgs,
    accessToken?: string
  ) {
    super(method, url, options);
    this.accessToken = accessToken;
  }
  public async toRequest(): Promise<AxiosRequestConfig> {
    const authToken = this.accessToken || (await getAuthToken());
    const isEdit: boolean = getIsEditModeLocalStorage();

    if (isEdit !== null && isEdit !== undefined) {
      this.headers = { ...this.headers, EditMode: `${isEdit}` };
    }
    if (!authToken) {
      throw Error("No Auth token");
    }

    return {
      method: this.method,
      url: this.requestURL,
      headers: {
        ...this.headers,
        Authorization: `Bearer ${authToken}`,
      },
      params: this.parameters,
      data: this.payload,
      timeout: this.timeout,
      responseType: this.responseType,
      paramsSerializer: { serialize: this.paramsSerializer },
    };
  }
}
