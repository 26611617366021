import { FC } from "react";
import { Scatter } from "react-chartjs-2";
import {
  Chart as ChartJS,
  ChartData,
  ScatterDataPoint,
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend,
  ChartOptions,
} from "chart.js";
import BaseChart from "./BaseChart";

ChartJS.register(
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend
);

export interface ScatterChartProps {
  data: ChartData<"scatter", (number | ScatterDataPoint | null)[], unknown>;
  options?: ChartOptions;
}

const ScatterChart: FC<ScatterChartProps> = ({ data, options }) => {
  return <BaseChart data={data} options={options} ChartType={Scatter} />;
};

export default ScatterChart;
