import { FC, Fragment } from "react";
import { Row, Col, ProgressBar } from "react-bootstrap";
import styled from "styled-components";
import BaseComponentProps from "../../../base-component-props";
import Dot from "../dot/Dot";
import Tooltip from "../tooltip/Tooltip";

const DateElement = styled.span`
  border-bottom: 1px dotted;
  font-weight: ${(props: { isActiveStep: boolean }) =>
    props.isActiveStep ? "600" : ""}
  &:hover {
    border-bottom: 1px solid;
    color: var(--grey-7) !important;
  }
`;

const DEFAULT_TIMELINE_SPACES: Record<number, number> = {
  0: 2,
  1: 5,
  2: 2,
};

export enum ProgressTimelineState {
  pending = "pending",
  active = "active",
  completed = "completed",
}

export interface ProgressTimelineProps extends BaseComponentProps {
  steps: Array<{
    state: ProgressTimelineState;
    space?: number;
    tooltipElement?: JSX.Element;
    startDate: Date;
  }>;
  showDates?: boolean;
  activeStepTotalProgress: number;
  active: boolean;
}

const ProgressTimeline: FC<ProgressTimelineProps> = ({
  steps,
  activeStepTotalProgress,
  active,
  showDates,
  className = "",
}) => {
  const firstRow = steps.map((step, idx) => {
    let result = null;
    const currentSpace = step.space || DEFAULT_TIMELINE_SPACES[idx];
    if (idx !== steps.length - 1) {
      switch (step.state) {
        case ProgressTimelineState.completed:
          result = (
            <Col className="px-0 my-auto" md={currentSpace}>
              <ProgressBar
                className="progress-xs"
                now={100}
                variant="primary"
              />
            </Col>
          );
          break;
        case ProgressTimelineState.active:
          result = (
            <Col className="px-0 my-auto" md={currentSpace}>
              <ProgressBar
                className="progress-xs"
                now={activeStepTotalProgress}
                variant="primary"
              />
            </Col>
          );
          break;
        case ProgressTimelineState.pending:
          result = (
            <Col className="px-0 my-auto" md={currentSpace}>
              <ProgressBar className="progress-xs" now={0} />
            </Col>
          );
          break;
        default:
          break;
      }
    }
    return (
      <Fragment key={`progress-timeline-step-collapsed-${idx}`}>
        <Col md="auto" className="my-auto px-0 d-block">
          <Dot
            color={
              active
                ? step.state === "pending"
                  ? "var(--grey-6)"
                  : "var(--bs-primary)"
                : "var(--grey-3)"
            }
          />
        </Col>
        {result}
      </Fragment>
    );
  });

  const secondRow = steps.map((step, idx) => {
    const currentSpace = step.space || DEFAULT_TIMELINE_SPACES[idx];
    const lastElement = idx === steps.length - 1;
    const isActiveStep = step.state === "active";

    return (
      <Col
        key={`progress-timeline-step-date-${idx}`}
        className={`ps-${lastElement ? 1 : idx + 1} pe-0 my-auto`}
        md={currentSpace}
      >
        <Tooltip
          arrow
          position={["bottom center", "bottom left", "bottom right"]}
          disabled={!active}
          trigger={
            <DateElement
              isActiveStep={isActiveStep}
              className={`fs-5 ${
                isActiveStep ? "text-gray-600" : "text-gray-500"
              } ${lastElement ? "float-end" : ""}`}
            >
              {`${step.startDate.toLocaleString("default", {
                month: "short",
              })} ${step.startDate.getDate()}`}
            </DateElement>
          }
          offsetY={10}
          mouseEnterDelay={0}
        >
          {step.tooltipElement}
        </Tooltip>
      </Col>
    );
  });

  return (
    <div className={className}>
      <Row>{firstRow}</Row>
      {showDates && <Row className="mt-3">{secondRow}</Row>}
    </div>
  );
};

export default ProgressTimeline;
