import { FC } from "react";
import styled from "styled-components";
import { BaseTableRowModel } from "../../../../model/table";
import BaseTableCell from "./BaseTableCell";

const RowStyled = styled.tr`
  background-color: var(--bg-row, #fff);
`;

const BaseTableRow: FC<BaseTableRowModel> = ({
  cells,
  onClick,
  className,
  style,
  multiSelectCell,
}) => {
  return (
    <RowStyled
      style={style}
      className={`fade-in ${onClick ? "clickable" : ""} ${className}`}
      onClick={onClick}
    >
      {multiSelectCell}
      {cells.map((cell, cellIdx) => (
        <BaseTableCell key={`base-table-cell-${cellIdx}`} {...cell} />
      ))}
    </RowStyled>
  );
};

export default BaseTableRow;
