import { FC, FormEvent, useState, useRef, useEffect } from "react";
import styled from "styled-components";
import { Form } from "react-bootstrap";
import TextInput, { InputType } from "./TextInput";
import { KeyboardEventCode, useKeyup } from "../../../utils/hooks/use-keyup";
import BtnSpinnerLoading from "../../base/loading/BtnSpinnerLoading";
import { Edit2 } from "react-feather";

export const INLINE_EDITOR_FORM_TEST_ID = "inline-editor-form";
export const INLINE_EDITOR_TEST_ID = "inline-editor";

const FormStyledDefault = styled(Form)<{ $width: string }>`
  .form-group {
    margin-bottom: 0;
    width: ${p => p.$width};
    animation: max-w-0-100 0.3s ease-in;
  }
`;

const InlineEditorStyled = styled.span`
  transition: color 0.3s;
  cursor: pointer;
  color: var(--grey-7);
  &:hover {
    color: var(--bs-primary);
  }
`;

interface InlineEditorProps {
  disabled?: boolean;
  value: string;
  prefix?: string;
  setValue: (value: string) => void;
  placeholder?: string;
  submit: (onSuccess: () => void, onFailed: () => void) => Promise<void>;
  FormStyled?: any;
  width?: string;
  onSetEdit?: (edit: boolean) => void;
}

const InlineEditor: FC<InlineEditorProps> = ({
  disabled = false,
  value,
  prefix,
  placeholder = "Type here...",
  setValue,
  submit,
  width = "312px",
  onSetEdit,
  FormStyled = FormStyledDefault,
}) => {
  const [initValue, setInitValue] = useState(value);

  const [edit, setEdit] = useState(false);

  useEffect(() => {
    onSetEdit && onSetEdit(edit);
  }, [edit, onSetEdit]);

  // Todo: get this by atom that mange modals opened array
  const [loading, setLoading] = useState(false);
  const [inputBlurTimeout, setInputBlurTimeout] =
    useState<NodeJS.Timeout | null>(null);

  const onBlur = () => {
    setInputBlurTimeout(
      setTimeout(() => {
        setValue(initValue);
        setEdit(false);
      }, 100)
    );
  };

  const onFocus = () => {
    inputBlurTimeout && clearTimeout(inputBlurTimeout);
    setInputBlurTimeout(null);
    setEdit(true);
  };

  const onSubmit = async (ev: FormEvent) => {
    ev.preventDefault();
    setLoading(true);
    await submit(
      () => setInitValue(value),
      () => setValue(initValue)
    );
    setLoading(false);
    setEdit(false);
  };

  const nameInputRef = useRef<HTMLInputElement | null>(null);
  useEffect(() => {
    edit && nameInputRef.current?.focus();
  }, [edit]);

  useKeyup(() => {
    setValue(initValue);
    setEdit(false);
  }, [KeyboardEventCode.ESCAPE]);

  return disabled ? (
    <>
      {prefix}
      {value || placeholder}
    </>
  ) : (
    <>
      {edit ? (
        <FormStyled
          data-testid={INLINE_EDITOR_FORM_TEST_ID}
          onClick={() => nameInputRef.current?.focus()}
          onSubmit={onSubmit}
          className="d-inline-flex align-items-center gap-3"
          $width={width}
        >
          <TextInput
            className="w100"
            type={InputType.Text}
            ref={nameInputRef}
            value={value}
            hint={placeholder}
            onBlur={onBlur}
            onFocus={onFocus}
            onChange={e => setValue(e.target.value)}
            prefix={prefix}
          />
          <BtnSpinnerLoading
            disabled={value === initValue}
            isLoading={loading}
            type="submit"
          >
            Save
          </BtnSpinnerLoading>
        </FormStyled>
      ) : (
        <InlineEditorStyled
          onClick={() => setEdit(true)}
          data-testid={INLINE_EDITOR_TEST_ID}
          className="ms-3 d-inline-flex align-items-center input-min-height"
        >
          {prefix} {value || placeholder}
          <Edit2 data-testid="edit-svg-icon" size="14" className="ms-3" />
        </InlineEditorStyled>
      )}
    </>
  );
};

export default InlineEditor;
