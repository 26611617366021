import React from "react";
import { toast, ToastOptions, ToastPromiseParams } from "react-toastify";

const defaultOptions: ToastOptions = {
  position: "bottom-center",
  hideProgressBar: false,
  closeOnClick: true,
  pauseOnHover: true,
  draggable: true,
  progress: undefined,
  theme: "colored",
};

export const toastError = (
  msg: string,
  options: ToastOptions = {}
): React.ReactText => toast.error(msg, { ...defaultOptions, ...options });
export const toastInfo = (
  msg: string,
  options: ToastOptions = {}
): React.ReactText => toast.info(msg, { ...defaultOptions, ...options });

export const toastSuccess = (
  msg: string,
  options: ToastOptions = {}
): React.ReactText => toast.success(msg, { ...defaultOptions, ...options });

export const toastWarning = (
  msg: string,
  options: ToastOptions = {}
): React.ReactText => toast.warning(msg, { ...defaultOptions, ...options });

export const toastPromise = <T = unknown>(
  messages: ToastPromiseParams,
  promise: Promise<T>,
  options: ToastOptions = {}
): Promise<T> =>
  toast.promise(promise, messages, {
    ...defaultOptions,
    ...options,
  }) as Promise<T>;
