import { TableProps } from "react-bootstrap";
import { CSSProperties } from "styled-components";
import { PageTitleProps } from "../components/typography/PageTitle";
import BaseComponentProps from "../../src/base-component-props";
import { MultiSelectType } from "../components/base/multi-select/use-multi-select";

export type Limit = 6 | 10 | 25 | 50 | 200 | 500 | 1000;

export enum PaginationType {
  Arrows,
  LoadMore,
  // InfiniteScrolling, // Todo: add me
}

export interface BasePagination {
  limit: Limit;
  count: number;
  page: number;
  loading?: boolean;
  setPage: (page: number) => void;
}

export interface PaginatedTableOptions extends BaseTableOptions {
  pagination?: PaginationType;
  enableSelectLimit?: boolean;
}

export interface BaseTableCellModel {
  value?: string | number;
  node?: React.ReactNode;
  stringValue?: string;
  sortValue?: string | number;
  className?: string;
  style?: CSSProperties;
  Element?: React.ElementType;
  elementProps?: { [str: string]: any };
}

export interface BaseTableRowModel extends BaseComponentProps {
  cells: BaseTableCellModel[];
  multiSelectCell?: JSX.Element;
  original_index?: number; // optional
}
export interface SyncMultiSelectTableRowModel extends BaseTableRowModel {
  original_index: number; // required
}

export interface BaseTableHeaderModel {
  title: JSX.Element | string;
  className?: string;
  style?: CSSProperties;
  onSort?: () => void;
  sortable?: boolean;
  active?: boolean;
  desc?: boolean;
}

export interface BaseTableOptions {
  scrollable?: boolean;
  initLimit?: Limit;
}

export interface BaseTableWrapperProps extends BaseComponentProps {
  header: React.ReactNode;
  footer?: React.ReactNode;
  title?: PageTitleProps;
  options?: BaseTableOptions;
  loading?: boolean;
  multiSelectColOffsetWidth?: number;
}

export interface BasePaginatedTableModel extends BaseTableModel {
  headers: BaseTableHeaderModel[];
  tableTitle?: PageTitleProps;
  baseTableWrapperClassName?: string;
  options?: PaginatedTableOptions;
  loading?: boolean;
  indexRowsSelected?: number[]; // row indexes list
  setIndexRowsSelected?: React.Dispatch<React.SetStateAction<number[]>>;
  multiSelect?: MultiSelectType;
  multiSelectDisabled?: boolean;
  filterByOptions?: FilterByRowProps[];
  onFilter?: (filterBy: BaseFilterBy) => void;
}

export type SyncPaginatedTableModel =
  | SyncPaginatedTableModelWithMultiSelect
  | SyncPaginatedTableModelWithoutMultiSelect;

// Enable use multiSelect just if row include original index,
// to keep the original index when sorting,
// filtering or Switching between pages..
export interface SyncPaginatedTableModelWithMultiSelect
  extends Omit<BasePaginatedTableModel, "multiSelect"> {
  multiSelect: MultiSelectType;
  rows: SyncMultiSelectTableRowModel[];
}

export interface SyncPaginatedTableModelWithoutMultiSelect
  extends Omit<BasePaginatedTableModel, "multiSelect"> {}

export interface PaginatedTableModel extends BasePaginatedTableModel {
  setCriteria: (criteria: BaseCriteria) => void;
  criteria: BaseCriteria;
  count: number;
}

export interface BaseFilterBy {
  [str: string]: any;
}

export type ToggleFilter = (
  filterByValue: any,
  filterBYProperty: string
) => void;

export interface FilterByRowProps {
  name: string;
  active: boolean;
  title: string;
  node: (toggleFilter: ToggleFilter, filterBy: BaseFilterBy) => JSX.Element;
}

export interface BaseCriteria {
  filterBy: BaseFilterBy;
  limit: Limit;
  page: number;
  orderBy: string;
  desc: boolean;
}

export interface BaseTableModel extends TableProps {
  headers?: BaseTableHeaderModel[];
  multiSelectHeader?: JSX.Element;
  rows: BaseTableRowModel[];
  onSort?: (idx: number) => void;
}

export interface TablePaginationModel extends BasePagination {
  pagination: PaginationType;
}
