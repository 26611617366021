import { AtomEffect, DefaultValue } from "recoil";

export const localStorageWithTransformationEffect: (
  saveTransformationCallback?: (object: unknown) => string,
  loadTransformationCallback?: ((value: string) => unknown) | null
) => (key: string) => AtomEffect<unknown> =
  (
    saveTransformationCallback = (object: unknown) => JSON.stringify(object),
    loadTransformationCallback = (value: string) => JSON.parse(value)
  ) =>
  (key: string) =>
  ({ setSelf, onSet }) => {
    setSelf(() => {
      const savedValue = localStorage.getItem(key);
      const transformedValue =
        savedValue != null
          ? loadTransformationCallback
            ? loadTransformationCallback(savedValue)
            : new DefaultValue()
          : new DefaultValue(); // Abort initialization if no value was stored
      return transformedValue;
    });

    onSet((newValue: unknown) => {
      if (newValue instanceof DefaultValue) {
        localStorage.removeItem(key);
      } else {
        localStorage.setItem(key, saveTransformationCallback(newValue));
      }
    });
  };

export const localStorageEffect = localStorageWithTransformationEffect();
