import { FC } from "react";
import { toastError, useConfirmationModal } from "visible-ui";
import { useRecoilCallback } from "recoil";
import { Trash2 } from "react-feather";
import { Image } from "react-bootstrap";
import { allOrganizationUsersAtom } from "../../state/atoms/survey-users-atoms";
import SurveyUsersClient from "../../communication/clients/survey-users-client";
import { SurveyUser } from "../../model/user";
import { useTranslation } from "react-i18next";
import { useAuth } from "../../hooks/auth-hooks";

interface DeleteSubPermissionsSurveyUserProps {
  user: SurveyUser;
}

const DeleteSubPermissionsSurveyUser: FC<
  DeleteSubPermissionsSurveyUserProps
> = ({ user }) => {
  const { t } = useTranslation();
  const { getAccessToken } = useAuth();

  const removeUser = useRecoilCallback(({ set }) => async () => {
    try {
      const accessToken = await getAccessToken();

      const res = await SurveyUsersClient.Instance.deactivateUser(
        accessToken,
        user
      );
      if (res?.success) {
        set(
          allOrganizationUsersAtom,
          _users => _users?.filter(({ id }) => user.id !== id) || null
        );
      } else {
        toastError(res?.error_message || t("errorMessage.deleteUser"));
      }
    } catch (error) {
      console.error(error);
      toastError(t("errorMessage.deleteUser"));
    }
  });

  const { trigger, confirmationModal } = useConfirmationModal(
    () => removeUser(),
    {
      title: (
        <section>
          <section className="d-flex justify-content-center">
            <Image
              src={`${process.env.PUBLIC_URL}assets/img/trash.svg`}
              className="mb-6 mt-4"
            />
          </section>
          <div className="fw-bold fs-2 mb-1">{t("deleteUser")}</div>
        </section>
      ),
      subtitle: (
        <div className="text-gray-700 text-start mb-5">
          {t("shareSurvey.userDeletionConfirmationQuestion")}
        </div>
      ),
      confirmBtn: t("shareSurvey.delete"),
      confirmationLoadingMsg: t("shareSurvey.deleting"),
      cancelBtn: t("shareSurvey.cancel"),
      width: "462px",
      maxWidth: "462px",
    }
  );
  return (
    <>
      <Trash2
        size={15}
        className="text-primary cursor-pointer"
        onClick={trigger}
      />
      {confirmationModal}
    </>
  );
};

export default DeleteSubPermissionsSurveyUser;
