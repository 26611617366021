import { forwardRef, useEffect, useState } from "react";
import { Image } from "react-bootstrap";
import BaseComponentProps from "../../../base-component-props";
import { UserInfo } from "../../layout/topbar/UserAvatar/UserAvatar";
import AvatarStyles from "./AvatarStyles.module.css";

export type AvatarSize = "xxl" | "xl" | "lg" | "sm" | "xs";
export type AvatarBackgroundColors =
  | "var(--user-1)"
  | "var(--user-2)"
  | "var(--user-3)"
  | "var(--user-4)"
  | "var(--user-5)"
  | "var(--user-6)"
  | "var(--user-7)"
  | "var(--user-8)"
  | "var(--user-9)"
  | "var(--bs-orange)"
  | "var(--vr-light-green)"
  | "var(--bs-white)";

const numberOfSupportedUserColors = 9;
export interface AvatarProps extends BaseComponentProps {
  size?: AvatarSize;
  userInfo?: UserInfo;
  imageUrl?: string;
  backgroundColor?: AvatarBackgroundColors;
  colorById?: number;
  showFullText?: boolean;
  vrMode?: boolean;
  borderImg?: boolean;
}

const Avatar = forwardRef<HTMLDivElement, AvatarProps>(
  (
    {
      size = "",
      className,
      onClick,
      userInfo,
      imageUrl,
      backgroundColor,
      colorById,
      showFullText,
      vrMode,
      borderImg = true,
    },
    ref
  ) => {
    let initialContent: JSX.Element | null = null;
    let imageContent: JSX.Element | null = null;

    const [mode, setMode] = useState<"initials" | "image">(
      imageUrl ? "image" : "initials"
    );

    if (colorById !== undefined && !backgroundColor) {
      backgroundColor = `var(--user-${
        (colorById % numberOfSupportedUserColors) + 1
      })` as AvatarBackgroundColors;
    }

    if (vrMode) {
      backgroundColor = "var(--bs-white)";
    }

    let initials;
    if (userInfo) {
      const names = userInfo?.fullName || userInfo.email;

      if (userInfo?.fullName) {
        const nameParameters = userInfo?.fullName.trim().split(" ");

        if (nameParameters.length > 1) {
          initials =
            nameParameters[0][0] + nameParameters[nameParameters.length - 1][0];
        }
      }
      const nameToShow = showFullText ? names : names?.slice(0, 2);
      initials = initials || nameToShow;
      initials = initials?.toUpperCase();

      initialContent = (
        <span
          className={`avatar-title rounded-circle ${AvatarStyles.vrAvatar} ${
            vrMode ? "text-gray-900" : ""
          }`}
          style={{
            minWidth: "inherit !important", //  Helps keep the aspect ratio of the avatar
            background: backgroundColor,
            paddingTop: "1px",
            ...(vrMode ? { border: "1px solid var(--grey-6)" } : {}),
          }}
        >
          {initials}
        </span>
      );
    }

    if (imageUrl) {
      imageContent = (
        <Image
          src={imageUrl}
          alt={initials}
          onError={() => setMode("initials")}
          className={`avatar-img rounded-circle ${
            borderImg ? "border border-4 border-card" : ""
          }`}
        />
      );
    }

    useEffect(() => {
      setMode(imageUrl ? "image" : "initials");
    }, [imageUrl, setMode]);

    return (
      <div
        className={`avatar avatar-${size} ${className}`}
        onClick={onClick}
        ref={ref}
        data-testid="top-user-avatar"
      >
        {mode === "image" ? imageContent : initialContent}
      </div>
    );
  }
);

export default Avatar;
