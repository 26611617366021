import { FC } from "react";
import { Outlet } from "react-router-dom";
import { t } from "i18next";

import TopBarLayout from "./TopBarLayout";
import ParticipantAgreementModal from "../privacy-policy/ParticipantAgreementModal";

const MagicLinkLayout: FC = () => {
  return (
    <TopBarLayout
      title={t("layout.cyberSurveyYear", { year: new Date().getFullYear() })}
    >
      <ParticipantAgreementModal />
      <Outlet />
    </TopBarLayout>
  );
};

export default MagicLinkLayout;
