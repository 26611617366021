import { atom, selector } from "recoil";

import SurveyUsersClient from "../../communication/clients/survey-users-client";
import { SurveyUser } from "../../model/user";
import { useAuth } from "../../hooks/auth-hooks";

export const allOrganizationUsersAtom = atom<SurveyUser[]>({
  key: "allOrganizationUsersAtom",
  default: selector({
    key: "allOrganizationUsersAtom/default",
    get: async () => {
      try {
        // eslint-disable-next-line react-hooks/rules-of-hooks
        const { getAccessToken } = useAuth();
        const accessToken = await getAccessToken();
        return (
          (await SurveyUsersClient.Instance.getAllOrganizationUsers(
            accessToken
          )) || []
        );
      } catch (error) {
        console.error(`error while trying to all organization users`, error);
        return [];
      }
    },
  }),
});
