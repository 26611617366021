import { Trans } from "react-i18next";
import { ExternalLink, PrivacyContentContainerStyled } from "visible-ui";

const ParticipantAgreementContent = () => {
  return (
    <PrivacyContentContainerStyled>
      <p>
        <Trans i18nKey="participantAgreement.paragraphOne" />
      </p>
      <p>
        <Trans i18nKey="participantAgreement.paragraphTwo" />{" "}
      </p>
      <p>
        <Trans i18nKey="participantAgreement.paragraphThree" />
        <b className="text-dark">
          <Trans i18nKey="participantAgreement.paragraphThreeBold" />
        </b>
      </p>
      <p>
        <Trans i18nKey="participantAgreement.paragraphFour">
          <span>
            Very limited personal data will be requested as part of the Survey
            (e.g., name, business email address, and titles of individuals at
            your organization completing the Survey), and such information will
            be handled consistent with Moody’s privacy policy located at{" "}
          </span>
          <ExternalLink prefixUrl="https://" linkAddress="www.moodys.com" />
          <span>and BitSight’s privacy policy located at </span>
          <ExternalLink prefixUrl="https://" linkAddress="www.bitsight.com" />.
        </Trans>
      </p>
      <p>
        <Trans i18nKey="participantAgreement.paragraphFive">
          <span>You may access the Survey via </span>
          <ExternalLink
            prefixUrl="https://"
            linkAddress="cybersurvey.moodys.com"
          />
          .
          <span>
            Answering the Survey may require input from your information
            security, finance, and legal teams. Most questions are in a
            multiple-choice format with a free-form text box for any additional
            information you would like to share. We encourage you to answer as
            many questions as possible. You will have 6 weeks to respond to the
            Survey.
          </span>
        </Trans>
      </p>
      <p>
        <Trans i18nKey="participantAgreement.paragraphSix">
          <span>
            We believe the Survey will generate valuable insights and we
            encourage you to participate. Should you have any questions about
            this email or the Survey, please reach out at
          </span>
          <ExternalLink mailto linkAddress="support@mail.cybersurvey.moodys.com" />
          <span>and we’ll be happy to address them.</span>
        </Trans>
      </p>
    </PrivacyContentContainerStyled>
  );
};

export default ParticipantAgreementContent;
