import { AxiosResponse } from "axios";
import { GenericResponse, UserMetadata } from "../../model";
import BaseAuthorizedRequest from "../requests/base-authorized-request";

export default class UpdateUserMetadataRequest extends BaseAuthorizedRequest<
  GenericResponse,
  GenericResponse
> {
  constructor(metadata: UserMetadata) {
    super("PUT", `/metadata/`, { payload: metadata });
  }

  processResponse(response: AxiosResponse<GenericResponse>): GenericResponse {
    return response.data;
  }
}
