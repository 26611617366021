import { FC } from "react";

import Parser from "html-react-parser";
import BaseComponentProps from "../../base-component-props";
import { injectionsMap } from "./ComponentsInjectors";
import styled from "styled-components";
import { EditorMixin } from "./RichEditorHelpers";


const RichTextViewerStyled = styled.div`
    ${EditorMixin}
`;

export interface RichTextViewerProps extends BaseComponentProps {
  content: string;
  s?: HTMLElement;
}

const RichTextViewer: FC<RichTextViewerProps> = ({
  content,
  className,
  style,
}) => {
  return (
    <RichTextViewerStyled className={className} style={style}>
      {Parser(content, {
        replace: domNode => {
          const elem = domNode as any;
          if (elem.attribs && elem.attribs.class) {
            if (elem.attribs.class in injectionsMap) {
              const injector = injectionsMap[elem.attribs.class];
              return injector(elem);
            }
          }
          return undefined;
        },
        trim: true,
      })}
    </RichTextViewerStyled>
  );
};

export default RichTextViewer;
