import { FC, useEffect, useState } from "react";
import { Button, Image } from "react-bootstrap";
import { useRecoilValue } from "recoil";
import styled from "styled-components";
import { useTranslation } from "react-i18next";
import dayjs from "dayjs";

import {
  CloudSaveBadge,
  CloudSaveBadgeState,
  Footer,
  PrivacyPolicyLink,
  TopBar,
} from "visible-ui";

import useTitle from "../../hooks/use-title";
import SurveySubmitModal from "../survey/SurveySubmitModal";
import { useSurveySubmittedOrEnded } from "../../hooks/use-survey-submitted-or-ended";
import PoweredBy from "./PoweredBy";
import LocaleSelect from "../top-bar/LocaleSelect";
import UserInfo from "../top-bar/UserInfo";
import ContactUs from "../top-bar/ContactUs";
import { surveyAnswersMap } from "../../state/atoms/survey-responses-atoms";
import relativeTime from "dayjs/plugin/relativeTime";
import ShareSurveyModal from "../survey/ShareSurveyModal";
import { ReactSVG } from "react-svg";
import { useMe } from "../../hooks/use-me";
import { Link, useLocation } from "react-router-dom";

dayjs.extend(relativeTime);

export const TOP_BAR_HEIGHT = 98;

const LayoutStyled = styled.section`
  min-height: 90vh;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
`;

const TopBarStyled = styled(TopBar)`
  max-height: ${TOP_BAR_HEIGHT}px;
  padding-block: 20px !important;
`;
const LogoStyled = styled(Image)`
  border-inline-end: 1px solid #75787b; // Todo: store it in css var
  padding-inline-end: 22px;
`;

const TitleElementStyled = styled.span`
  color: var(--vr-brand-light-blue);
  margin-inline-start: 22px;
`;

interface TopBarLayoutProps {
  title: string;
  children?: React.ReactNode;
}
const TopBarLayout: FC<TopBarLayoutProps> = ({ title, children }) => {
  const { t } = useTranslation();
  useTitle(title);

  const { lastUpdate, lastUpdater } = useRecoilValue(surveyAnswersMap);
  const { me, loading } = useMe();
  const { pathname } = useLocation();
  const [showTopNav, setShowTopNav] = useState(false)

  const {
    submitted,
    loading: submittedLoading,
    ended,
  } = useSurveySubmittedOrEnded();

  useEffect(() => {
    if(!loading){
      if(!(me?.sub_permissions)?.length && !submittedLoading && !(submitted || ended)){
        setShowTopNav(true)
      } else{
        setShowTopNav(false)
      }
    }
  }, [me, loading, submittedLoading, submitted, ended ])

  return (
    <>
      <TopBarStyled data-testid="top-bar" className="justify-content-between">
        {me && (
          <>
            <Link
              to="/"
              data-testid="top-bar-children"
              className={`d-flex align-items-center ms-6 ${
                pathname === "/" ? "cursor-initial" : "cursor-pointer"
              }`}
            >
              <LogoStyled
                data-testid="top-bar-logo"
                src={process.env.PUBLIC_URL + "assets/img/full-logo.svg"}
              />
              <TitleElementStyled
                data-testid="top-bar-title"
                className="fw-lighter fs-1"
              >
                {title}
              </TitleElementStyled>
            </Link>
            <section
              data-testid="top-bar-children"
              className="d-flex align-items-center gap-4"
            >
              {/* don't render CloudSaveBadge & SurveySubmitModal & SurveySubmitModal if user is not agreed to privacy policy */}
              {me.privacy_agreed && (
                <>
                  {lastUpdate && (
                    <CloudSaveBadge
                      className="text-gray-700"
                      state={CloudSaveBadgeState.Idle}
                      iconOpacity={1}
                      savedLabel={t("generalSurvey.lastSavedTimeAgoByName", {
                        timeAgo: dayjs(lastUpdate).fromNow(true),
                        name: lastUpdater,
                      })}
                      color={"var(--bs-gray-700)"}
                    />
                  )}
                  {showTopNav && (
                    <section className="d-flex align-items-center gap-3">
                      <ShareSurveyModal
                        triggerElement={onClick => (
                          <Button
                            data-testid="top-bar-share-button"
                            className="text-primary bg-white"
                            onClick={onClick}
                          >
                            <span className="d-flex align-items-center gap-2 fw-bold">
                              <ReactSVG
                                src={
                                  process.env.PUBLIC_URL +
                                  "assets/img/user-profile-add-new.svg"
                                }
                                data-testid="top-bar-share-button-icon"
                                style={{ transform: "translateY(-3px)" }}
                              />
                              <span>{t("shareSurvey.share")}</span>
                            </span>
                          </Button>
                        )}
                      />
                      <SurveySubmitModal
                        triggerElement={onClick => (
                          <Button
                            data-testid="top-bar-review-and-submit-button"
                            className="px-4"
                            onClick={onClick}
                          >
                            {t("submitSurvey.reviewAndSubmit")}
                          </Button>
                        )}
                      />
                    </section>
                  )}
                </>
              )}
              <section
                data-testid="top-bar-user-preferences"
                className="d-flex align-items-center gap-1"
              >
                <ContactUs />
                <LocaleSelect />
                <UserInfo />
              </section>
            </section>
          </>
        )}
      </TopBarStyled>
      <LayoutStyled>
        <section>{children}</section>
        <Footer
          elements={[
            <>
              {t("layout.yearMoodysInvestorsServiceInc", {
                year: new Date().getFullYear(),
              })}
            </>,
            <PrivacyPolicyLink
              privacyPolicyTxt={t("participantAgreement.title")}
            />,
            <PoweredBy />,
          ]}
        />
      </LayoutStyled>
    </>
  );
};

export default TopBarLayout;
