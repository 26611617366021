import { BaseCriteria } from "../table";
import { NAReason } from "./na-reason";

export enum SignalType {
  float = "FloatSignalValue",
  string = "StringSignalValue",
  boolean = "BoolSignalValue",
}

export enum SignalModelType {
  CRQL4 = "CRQL4",
  FQ = "FQ",
}

export const signalTypeConvert: Record<SignalType, string> = {
  [SignalType.float]: "Float",
  [SignalType.string]: "Enum",
  [SignalType.boolean]: "Bool",
};

export enum SignalStatusEnum {
  pending = "pending",
  approved = "approved",
}

export interface SignalValue {
  na_reason?: NAReason | null;
  float_value?: number | null;
  string_value?: string | null;
  bool_value?: boolean | null;
}

export interface BaseSignal {
  id: string;
  client_id: string; // uuid model
  signal_id: string; // some_signal_name_id
  family?: string;
  name?: string;
  analyzer?: string;
  possible_enum_values?: Record<string, number> | null;
  minimum_value: number | null;
  maximum_value: number | null;
  signal_type: SignalType;
  signal_value: SignalValue;
  creation_time?: string;
  value_modified_by?: string;
}

export interface SignalMetadata {
  status?: SignalStatusEnum;
  status_modified_by?: string;
  status_modified_on?: string;
  comment?: string;
}
export interface SignalHistoryMetadata extends SignalMetadata {
  value_modified_by?: string;
  value_modified_on?: string;
  value?: SignalValue;
}

export interface Signal extends BaseSignal {
  metadata: SignalMetadata;
}

// TODO: extends from GenericPaginatedResponse
export interface SignalWrapper {
  data: Signal[];
  count?: number;
  offset?: number;
  limit?: number;
}

export interface SignalsCriteria extends BaseCriteria {
  organizationId: string;
}

export interface SignalMetadataHistory {
  signal_id: string;
  history: SignalHistoryMetadata[];
}

export interface UpdateSignalStatusRequestModel {
  signal_internal_id: string;
  status: SignalStatusEnum;
}
export interface UpdateSignalCommentRequestModel {
  organization_id: string;
  signal_id: string;
  comment: string;
}

export enum DatabricksClusterState {
  PENDING = "PENDING",
  RUNNING = "RUNNING",
  RESTARTING = "RESTARTING",
  RESIZING = "RESIZING",
  TERMINATING = "TERMINATING",
  TERMINATED = "TERMINATED",
  ERROR = "ERROR",
  UNKNOWN = "UNKNOWN",
}
