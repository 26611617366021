import { FC } from "react";
import Popup from "reactjs-popup";
import { PopupProps } from "reactjs-popup/dist/types";

export interface TooltipProps extends PopupProps {
  dark?: boolean;
  width?: string;
  minWidth?: string;
}

const Tooltip: FC<TooltipProps> = ({
  on = ["hover", "focus"],
  children,
  dark = false,
  width = "fit-content",
  minWidth = "220px",
  ...props
}) => {
  return (
    <Popup
      {...props}
      contentStyle={{
        backgroundColor: dark ? "#505458" : "",
        color: dark ? "#fff" : "",
        width,
        minWidth,
        ...props.contentStyle,
      }}
      arrowStyle={{
        color: dark ? "#505458" : "#fff",
        ...props.arrowStyle,
      }}
      on={on}
    >
      {children}
    </Popup>
  );
};

export default Tooltip;
