import { AxiosResponse } from "axios";
import { GenericResponse } from "../../model/base";
import BaseAuthorizedRequest from "../requests/base-authorized-request";
import { UploadPartResponse } from "../s3-utils";

export default class CompleteMultiPartUploadRequest extends BaseAuthorizedRequest<
  GenericResponse,
  GenericResponse
> {
  constructor(
    uploadId: string,
    fileName: string,
    parts: Array<UploadPartResponse>
  ) {
    super("PUT", `/static/url/multi_part/complete`, {
      payload: {
        upload_id: uploadId,
        file_name: fileName,
        parts,
      },
    });
  }

  processResponse(response: AxiosResponse<GenericResponse>): GenericResponse {
    const result = response.data;
    return result;
  }
}
