import { QuestionnaireResponseAnswer, QuestionResponseType } from "visible-ui";

import {
  QuestionDependencyStatus,
  SurveyAnswer,
  SurveyQuestion,
} from "../../model/survey";

export const isQuestionAnswered = (
  answer: SurveyAnswer | undefined
): boolean => {
  return (
    (answer?.value && answer.value.length > 0) || !!answer?.will_not_answer
  );
};

export const getQuestionDependencyStatus = (
  currentQuestion: SurveyQuestion | undefined,
  questions: SurveyQuestion[],
  answersMap: Map<string, SurveyAnswer>
) => {
  const questionDependentOn = questions.find(
    q => q.id === currentQuestion?.depends_on_question?.question_id
  );
  const answerQuestionDependentOn = questionDependentOn
    ? answersMap.get(questionDependentOn.id)
    : undefined;
  const isQuestionDependencyAnswered = isQuestionAnswered(
    answerQuestionDependentOn
  );
  const enabled =
    currentQuestion?.depends_on_question?.dependency_answers_ids?.every(
      id => id && answerQuestionDependentOn?.possible_answer_ids?.includes(id)
    );

  let status = QuestionDependencyStatus.NOT_DEPENDENT;

  if (questionDependentOn) {
    if (isQuestionDependencyAnswered) {
      if (enabled) {
        status = QuestionDependencyStatus.ENABLED;
      } else {
        status = QuestionDependencyStatus.IRRELEVANT;
      }
    } else {
      status = QuestionDependencyStatus.DISABLED;
    }
  }

  return { status, questionDependentOn };
};

export const getQuestionPermissionsAsString = (
  allQuestions: SurveyQuestion[],
  subPermissions: string[]
): string =>
  allQuestions
    .reduce((acc, _, i) => {
      const idx = subPermissions?.includes(_.id) ? i : null;
      const accLastIdx = acc.length - 1;
      const follow = acc.length && acc[accLastIdx].includes(i - 1);
      return idx !== null
        ? follow
          ? [...acc.slice(0, accLastIdx), [...acc[accLastIdx], idx]]
          : [...acc, [idx]]
        : acc;
    }, [] as Array<number[]>)
    .map(numbers =>
      numbers.length > 1
        ? `${numbers[0] + 1}-${numbers[numbers.length - 1] + 1}`
        : numbers[0] + 1
    )
    .join(", ");

export const questionTextWithPrefix = ({
  question_absolute_number,
  question,
}: SurveyQuestion): string => {
  return `${question_absolute_number + 1}. ${question}`;
};

export const isFreeTextResponse = (
  question: SurveyQuestion,
  answer: Partial<QuestionnaireResponseAnswer>
) => {
  return (
    question.response_type === QuestionResponseType.FREE_TEXT ||
    question.response_type === QuestionResponseType.SHORT_ANSWER ||
    question.response_type === QuestionResponseType.PERCENTAGE ||
    question.response_type === QuestionResponseType.SHORT_ANSWER_CURRENCY ||
    answer.is_other
  );
};
