import BaseRequest from "../requests/base-request";
import { AxiosResponse } from "axios";
import { GenericResponse } from "../../model";

export default class SendOtpRequest extends BaseRequest<
  GenericResponse,
  GenericResponse
> {
  constructor(user_identifier: string, authMethod: string) {
    super("POST", `/send-otp/`, {
      payload: { user_identifier, auth_method: authMethod },
    });
  }

  processResponse(response: AxiosResponse<GenericResponse>): GenericResponse {
    const result = { ...response.data, success: response.status === 200 };
    return result;
  }
}
