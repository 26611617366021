import { FC } from "react";
import { Col, Container, Row } from "react-bootstrap";
import BaseComponentProps from "../../../base-component-props";

import PageTitle from "../../typography/PageTitle";
export interface FormLayoutProps extends BaseComponentProps {
  formTitle?: string;
  preTitle?: string;
}

const FormLayout: FC<FormLayoutProps> = ({
  formTitle,
  preTitle,
  children,
  ...props
}) => {
  return (
    <Container {...props} fluid="xl">
      <Row className="justify-content-center">
        <Col xs={12} lg={11} xl={10}>
          {formTitle && preTitle && (
            <PageTitle
              title={{ text: formTitle || "" }}
              preTitle={{ text: preTitle || "" }}
            />
          )}
          {children}
        </Col>
      </Row>
    </Container>
  );
};

export default FormLayout;
