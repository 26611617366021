import { FC } from "react";
import { Button } from "react-bootstrap";
import { Collapse } from "react-collapse";
import { Breakpoint } from "../../utils/responsive";
import { QuestionnaireResponseAnswer } from "../../model/questionnaire";
import CloudSaveBadge, {
  CloudSaveBadgeState,
} from "../base/cloud-save-badge/CloudSaveBadge";

export enum AnswerStatus {
  CantAnswer = "cant_answer",
  Answered = "answered",
  Empty = "empty",
}

interface SharedCantAnswerQuestionProps {
  answerStatus: AnswerStatus;
  setAnswerStatus: React.Dispatch<React.SetStateAction<AnswerStatus>>;
  saving: boolean;
  currentAnswer: QuestionnaireResponseAnswer | undefined;
  resetAnswer: () => void;
  cantAnswerSection: React.ReactNode;
  cantWontAnswerTxt?: string;
  clearResponseTxt?: string;
  answeredByTxt?: string;
  savedTxt?: string;
}

const SharedCantAnswerQuestion: FC<SharedCantAnswerQuestionProps> = ({
  answerStatus,
  setAnswerStatus,
  saving,
  currentAnswer,
  resetAnswer,
  cantAnswerSection,
  cantWontAnswerTxt = "Can’t / Won’t Answer",
  clearResponseTxt = "Clear Response",
  answeredByTxt,
  savedTxt = "Saved",
}) => {
  return (
    <section className="d-flex flex-column gap-4">
      <Collapse isOpened>
        {answerStatus === AnswerStatus.CantAnswer && cantAnswerSection}
      </Collapse>
      {answerStatus === AnswerStatus.Empty && (
        <section className="d-flex justify-content-end">
          <Button
            variant="link"
            className="text-primary p-0"
            onClick={() => setAnswerStatus(AnswerStatus.CantAnswer)}
          >
            {cantWontAnswerTxt}
          </Button>
        </section>
      )}
      {answerStatus !== AnswerStatus.Empty && currentAnswer && (
        <section className="d-flex justify-content-end gap-2">
          <CloudSaveBadge
            fullShowBreakpoint={Breakpoint.md}
            state={
              saving ? CloudSaveBadgeState.Saving : CloudSaveBadgeState.Idle
            }
            savedLabel={
              currentAnswer?.answered_by
                ? answeredByTxt
                  ? answeredByTxt
                  : `Answered by ${currentAnswer?.answered_by}`
                : savedTxt
            }
          />
          <Button variant="link" className="p-0" onClick={resetAnswer}>
            {clearResponseTxt}
          </Button>
        </section>
      )}
    </section>
  );
};

export default SharedCantAnswerQuestion;
