import { FC, useState } from "react";
import { Button, Dropdown, Image } from "react-bootstrap";
import { BaseFilterBy, FilterByRowProps } from "../../../../model/table";
import FilterRowPreview from "./FilterRowPreview";
import CustomClickPopup from "../../tooltip/CustomClickPopup";
import styled from "styled-components";

const FilterContainerStyled = styled.section`
  width: 356px;
`;
interface FilterContainerDropdownProps {
  filterByRows: FilterByRowProps[];
  applyFilter: (filterBy: BaseFilterBy) => void;
  filterByProps: BaseFilterBy;
}

const FilterContainerDropdown: FC<FilterContainerDropdownProps> = ({
  filterByRows,
  applyFilter,
  filterByProps,
}) => {
  const [filterBy, setFilterBy] = useState<BaseFilterBy>(filterByProps);
  const toggleFilter = (optionKey: any, name: string) => {
    setFilterBy(_filterBy => ({
      ..._filterBy,
      [name]: _filterBy[name] === optionKey ? "" : optionKey,
    }));
  };

  const active = filterByRows.some(_ => _.active);

  return (
    <CustomClickPopup
      onToggle={open => open && setFilterBy(filterByProps)}
      position="bottom right"
      trigger={
        <Button
          variant={active ? "primary" : "link"}
          size="sm"
          className={`d-flex align-items-center gap-1 px-3 h-100 ${
            active ? "" : "border"
          }`}
        >
          <Image
            src={`${process.env.PUBLIC_URL}assets/img/table/filter-${
              active ? "white" : "black"
            }.svg`}
          />
          Filter
          {active && (
            <span className="p-2 bg-white fs-6 fw-bold btn-sm text-primary py-0 px-1 ms-1">
              {filterByRows.filter(_ => _.active).length}
            </span>
          )}
        </Button>
      }
    >
      {closePopup => (
        <FilterContainerStyled>
          <section className="px-4 py-2 border-bottom d-flex justify-content-between align-items-center">
            <b>Filters</b>
            <Button
              onClick={() => {
                setFilterBy({});
                applyFilter({});
              }}
              className="text-gray-700"
              variant="link"
            >
              Clear filters
            </Button>
          </section>
          <section className="px-4">
            {filterByRows?.map(filterRowPreview => (
              <FilterRowPreview
                key={`filter-row-preview-${filterRowPreview.name}`}
                {...filterRowPreview}
                toggleFilter={toggleFilter}
                filterBy={filterBy}
              />
            ))}
            <Dropdown.Item
              onClick={() => {
                applyFilter(filterBy);
                closePopup();
              }}
              className="w-100 my-3 text-center text-white bg-primary"
            >
              Apply filter
            </Dropdown.Item>
          </section>
        </FilterContainerStyled>
      )}
    </CustomClickPopup>
  );
};

export default FilterContainerDropdown;
