import { FC, useEffect, useState } from "react";
import { X } from "react-feather";
import styled from "styled-components";
import { BaseCriteria } from "../../../model/table";
import MultipleChoiceBaseOption from "../../inputs/multi-choice-option/MultipleChoiceBaseOption";

export type MultiSelectType = (
  multiSelectBaseParams: MultiSelectBaseParams
) => JSX.Element;
export interface MultiSelectBaseParams {
  setRequesting: React.Dispatch<React.SetStateAction<boolean>>;
  indexRowsSelected: number[];
  limit?: number;
  page?: number;
}

export interface useMultiSelectProps extends MultiSelectBaseParams {
  setIndexRowsSelected: React.Dispatch<React.SetStateAction<number[]>>;
  multiSelectWrapper(multiSelect: MultiSelectType): JSX.Element;
  requesting: boolean;
}

export const useMultiSelect = (criteria: BaseCriteria): useMultiSelectProps => {
  const [indexRowsSelected, setIndexRowsSelected] = useState<number[]>([]);
  const [requesting, setRequesting] = useState(false);

  const multiSelectWrapper: (
    multiSelect: MultiSelectType
  ) => JSX.Element = multiSelect => (
    <MultiSelectWrapper
      rowsSelectedCount={indexRowsSelected.length}
      close={() => setIndexRowsSelected([])}
      requesting={requesting}
    >
      {multiSelect({ setRequesting, indexRowsSelected })}
    </MultiSelectWrapper>
  );

  useEffect(() => {
    setIndexRowsSelected([]);
  }, [criteria]);

  useEffect(() => {
    requesting && document.body.classList.add("overflow-hidden");
    return () => {
      document.body.classList.remove("overflow-hidden");
    };
  }, [requesting]);

  return {
    indexRowsSelected,
    setIndexRowsSelected,
    multiSelectWrapper,
    requesting,
    setRequesting,
  };
};

const MultiSelectWrapperStyled = styled.section<{ requesting: boolean }>`
  &:before {
    content: "";
    background: #000;
    position: fixed;
    height: 100%;
    top: 0;
    left: 0;
    transition: opacity 0.3s, width ${p => (p.requesting ? "0s" : "0s 0.3s")};
    opacity: ${p => (p.requesting ? 0.3 : 0)};
    width: ${p => (p.requesting ? "100%" : 0)};
    z-index: ${p => (p.requesting ? "inherit" : "auto")};
  }
  > section {
    min-width: 400px;
    height: 50px;
    padding-inline: 18px;
    border-radius: 10px;
    background-color: var(--dark-purple);
    color: #fff;
  }
  bottom: 50px;
  left: 0;
  margin-left: 250px;
  width: calc(100vw - 250px);
  display: flex;
  justify-content: center;
`;

interface MultiSelectWrapperProps {
  rowsSelectedCount: number;
  close: () => void;
  requesting: boolean;
  children?: React.ReactNode;
}

const MultiSelectWrapper: FC<MultiSelectWrapperProps> = ({
  rowsSelectedCount,
  close,
  requesting,
  children,
}) => {
  return (
    <MultiSelectWrapperStyled
      onClick={ev => ev.stopPropagation()}
      requesting={requesting}
      className="position-fixed hight-0 z-high-modal"
    >
      <section className="fade-in d-flex align-items-center justify-content-between">
        <MultipleChoiceBaseOption
          checked
          readOnly
          type="checkbox"
          className="me-6"
        >
          {rowsSelectedCount} Items
        </MultipleChoiceBaseOption>
        {children}
        <X
          onClick={close}
          size={15}
          className="opacity-25 opacity-hover-100 cursor-pointer ms-4"
        />
      </section>
    </MultiSelectWrapperStyled>
  );
};
