import { FC, useCallback, useState } from "react";
import CircleIconButton from "./CircleIconButton";
import Tooltip, { TooltipProps } from "../tooltip/Tooltip";
import { useScroll } from "../../../utils/hooks/use-scroll";

export enum LocaleCodes {
  en = "en",
  zh = "zh",
  ja = "ja",
  ko = "ko",
  fr = "fr",
  es = "es",
  pt = "pt",
  ar = "ar",
}

interface CircleIconButtonTooltipProps extends TooltipProps {
  icon: React.ReactNode;
  closeOnScroll?: boolean;
  userLocale?: LocaleCodes;

}

const CircleIconButtonTooltip: FC<CircleIconButtonTooltipProps> = ({
  icon,
  closeOnScroll,
  userLocale,
  children,
  ...props
}) => {
  const [open, setOpen] = useState(false);

  const setClosed = useCallback(
    () => (closeOnScroll ? setOpen(false) : void 0),
    [closeOnScroll]
  );

  useScroll(setClosed, document);

  const position = userLocale === 'ar' ? "bottom left" : "bottom right"

  return (
    <>
      <Tooltip
        open={open}
        on="click"
        onOpen={() => setOpen(true)}
        onClose={() => setOpen(false)}
        arrow={false}
        repositionOnResize
        position={position}
        {...props}
        trigger={
          <span>
            <CircleIconButton active={open} icon={icon} />
          </span>
        }
      >
        {children}
      </Tooltip>
    </>
  );
};

export default CircleIconButtonTooltip;
