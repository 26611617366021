import { FC, useEffect, useState } from "react";
import { CardProps } from "react-bootstrap";
import { Layouts, Responsive, WidthProvider } from "react-grid-layout";
import BaseComponentProps from "../../base-component-props";
import Tile, { TileProps } from "./Tile";
import TilesGridDoneFloater from "./TilesGridDoneFloater";
import TileExpended from "./TileExpended";
import styled from "styled-components";

const ResponsiveGridLayout = WidthProvider(Responsive);

export interface TilesGridProps extends BaseComponentProps {
  tiles: Array<
    TileProps & CardProps & { key: string; element: React.ReactNode }
  >;
  layouts: Layouts;
}

const ResponsiveGridLayoutWrapper = styled.section`
  .tile-design > .card {
    transition: box-shadow 0.3s, opacity 0.3s;
    cursor: pointer;
    height: 100%;
    background-image: url("${process.env
      .PUBLIC_URL}assets/img/tiles/placeholder.svg");
    background-repeat: no-repeat;
    background-position: center;
  }
  &:hover {
    .tile-design > .card {
      &:hover {
        box-shadow: 10px 10px 25px 0 rgba(18, 38, 63, 0.1);
      }
      &:not(:hover) {
        opacity: 0.4;
        box-shadow: 0 4px 10px 0 rgba(18, 38, 63, 0.03);
        border: solid 1px var(--grey-3);
      }
    }
  }
`;

const TilesGrid: FC<
  TilesGridProps &
    ReactGridLayout.ResponsiveProps &
    ReactGridLayout.WidthProviderProps
> = ({ tiles, layouts, onLayoutChange, onDragStop, className }) => {
  const [tilesEditMode, setTilesEditMode] = useState(false);
  const [tileIdxActive, setTileIdxActive] = useState<number | null>(null);

  const handleKeyup: (e: KeyboardEvent) => void = e => {
    e.code === "Escape" && setTileIdxActive(null);
  };

  useEffect(() => {
    if (tileIdxActive !== null) {
      document.body.classList.add("overflow-hidden");
      window.addEventListener("keyup", handleKeyup);
    } else {
      window.removeEventListener("keyup", handleKeyup);
    }
    return () => {
      document.body.classList.remove("overflow-hidden");
    };
  }, [tileIdxActive]);

  const reorderTilesHandler = () => setTilesEditMode(true);

  return (
    <ResponsiveGridLayoutWrapper>
      <ResponsiveGridLayout
        autoSize
        isDraggable={tilesEditMode}
        className={`layout ${className}`}
        layouts={layouts}
        breakpoints={{ lg: 1067, md: 960, sm: 768, xs: 480, xxs: 0 }}
        cols={{ lg: 2, md: 2, sm: 2, xs: 1, xxs: 1 }}
        margin={[24, 24]}
        useCSSTransforms={false}
        onLayoutChange={onLayoutChange}
        onDragStop={onDragStop}
        measureBeforeMount
      >
        {tiles.map(({ key, element, ...rest }, i) => (
          <section
            className="tile-design"
            onClick={() => setTileIdxActive(i)}
            key={key}
          >
            <>
              <TileExpended
                closeTile={() => setTileIdxActive(null)}
                isActive={tileIdxActive === i}
              />
              <Tile
                editMode={tilesEditMode}
                reorderTilesHandler={reorderTilesHandler}
                {...rest}
              >
                {element}
              </Tile>
            </>
          </section>
        ))}
      </ResponsiveGridLayout>
      {tilesEditMode && (
        <TilesGridDoneFloater onDone={() => setTilesEditMode(false)} />
      )}
    </ResponsiveGridLayoutWrapper>
  );
};
export default TilesGrid;
