import { useEffect, useState } from "react";

export enum Breakpoint {
  xs = "xs",
  sm = "sm",
  md = "md",
  lg = "lg",
  xl = "xl",
  xxl = "xxl",
}

export const breakpoints: Record<Breakpoint, number> = {
  [Breakpoint.xs]: 0,
  [Breakpoint.sm]: 576,
  [Breakpoint.md]: 768,
  [Breakpoint.lg]: 992,
  [Breakpoint.xl]: 1200,
  [Breakpoint.xxl]: 1400,
};

export const up = (breakpoint: Breakpoint) => {
  return `(min-width: ${breakpoints[breakpoint]}px)`;
};
export const down = (breakpoint: Breakpoint) => {
  return `(max-width: ${breakpoints[breakpoint]}px)`;
};
export const between = (breakpoint1: Breakpoint, breakpoint2: Breakpoint) => {
  return `(min-width: ${breakpoints[breakpoint1]}px) and (max-width: ${breakpoints[breakpoint2]}px)`;
};

// example usage
// import styled from "styled-components";
// const ComponentStyled = styled.div`
//   @media ${up(Breakpoint.md)} {
//     // styles for md and up
//   }
//   @media ${down(Breakpoint.md)} {
//     // styles for md and down
//   }
//   @media ${between(Breakpoint.md, Breakpoint.xl)} {
//     // styles for md and xl
//   }
// `;

export const useBreakpoint = (): number => {
  const getBreakpoint = (): number => {
    const { innerWidth } = window;
    const breakpoint = Object.values(breakpoints).find(_ => _ > innerWidth);
    return breakpoint || innerWidth;
  };

  const [breakpoint, setBreakpoint] = useState<number>(getBreakpoint());

  const handleResize = () => {
    setBreakpoint(getBreakpoint());
  };

  useEffect(() => {
    window.addEventListener("resize", handleResize);

    return () => {
      window.removeEventListener("resize", handleResize);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return breakpoint;
};
