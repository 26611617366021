import { AxiosResponse } from "axios";

import { BaseAuthorizedRequest, GenericResponse } from "visible-ui";
import { SurveyAnswer } from "../../model/survey";

export default class SaveAnswerRequest extends BaseAuthorizedRequest<
  GenericResponse,
  GenericResponse
> {
  constructor(answer: SurveyAnswer, accessToken: string) {
    super(
      "PUT",
      `/answers`,
      {
        payload: answer,
      },
      accessToken
    );
  }

  processResponse(response: AxiosResponse<GenericResponse>): GenericResponse {
    let result = response.data;
    return result;
  }
}
