import { FC } from "react";
import BaseComponentProps from "../../../base-component-props";

import DividerStyles from "./DividerStyles.module.css";

export interface DividerProps extends BaseComponentProps {
  vertical?: boolean;
  height?: number;
  dashed?: boolean;
  overrideClass?: boolean;
}

const Divider: FC<DividerProps> = ({
  vertical,
  height,
  dashed,
  className = "",
  overrideClass = false,
}) => {
  let style = { height: height };
  style = {
    ...style,
    ...(dashed
      ? {
          color: "transparent",
          border: "none",
          backgroundColor: "currentColor",
          borderTop: "1px dashed var(--grey-3)",
        }
      : {}),
  };
  return (
    <hr
      className={
        overrideClass
          ? className
          : `${
              vertical ? DividerStyles.verticalDivider : "navbar-divider my-3"
            } ${className}`
      }
      style={style}
    />
  );
};

export default Divider;
