import { withAuthenticationRequired } from "@auth0/auth0-react";
import { Route } from "react-router-dom";

import { ErrorPage, Loading } from "visible-ui";

import SurveyPage from "../components/survey/SurveyPage";
import "./save-locale";

const TempLoading = () => (
  <div className="center-container">
    <Loading variant="spinner" />
  </div>
);

const ProtectedWildRoute = withAuthenticationRequired(
  () => {
    localStorage.setItem("locale", localStorage.getItem("locale") || "en");
    return (
      <ErrorPage
        errorMsg="We couldn't find this page"
        imageUrl={
          process.env.PUBLIC_URL + "/assets/img/tasks/no-tasks-illu.svg"
        }
      />
    );
  },
  {
    onRedirecting: () => <TempLoading />,
    loginOptions: {
      fragment: `locale=${localStorage.getItem("locale")}`,
    },
  }
);

export const wildCardRoute = (
  <Route path="*" element={<ProtectedWildRoute />} />
);

const ProtectedSurveyPage = withAuthenticationRequired(SurveyPage, {
  onRedirecting: () => <TempLoading />,
  loginOptions: {
    fragment: `locale=${localStorage.getItem("locale")}`,
  },
});

const ProtectedErrorPage = withAuthenticationRequired(
  () => {
    localStorage.setItem("locale", localStorage.getItem("locale") || "en");
    return (
      <ErrorPage
        errorMsg="You do not have access permission for this page"
        imageUrl={
          process.env.PUBLIC_URL + "/assets/img/tasks/no-tasks-illu.svg"
        }
        style={{ height: "calc(100vh - 65px)" }}
      />
    );
  },
  {
    onRedirecting: () => <TempLoading />,
    loginOptions: {
      fragment: `locale=${localStorage.getItem("locale")}`,
    },
  }
);

export const magicRoutes = (
  <>
    <Route path="/" element={<ProtectedSurveyPage />} />
    <Route path="restricted" element={<ProtectedErrorPage />} />
  </>
);
