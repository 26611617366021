import { CSSProperties, forwardRef, useEffect, useState } from "react";
import {
  buildStyles,
  CircularProgressbarWithChildren,
} from "react-circular-progressbar";
import "react-circular-progressbar/dist/styles.css";

export interface RadialProgressProps {
  size: "sm" | "lg";
  innerProgress: number;
  outerProgress?: number;
  double?: boolean;
  onFocus?: (...args: any[]) => any;
  onBlur?: any;
  onMouseOver?: any;
  onMouseOut?: any;
  children?: React.ReactNode;
}

interface DoubleRadialProgressParams {
  style: CSSProperties;
  trailWidth: number;
  outerStrokeWidth: number;
  innerStrokeWidth: number;
  outerWrapperWidth: string;
  innerWrapperWidth: string;
}

const RadialProgress = forwardRef<HTMLDivElement, RadialProgressProps>(
  (
    {
      size,
      double,
      outerProgress = 0,
      innerProgress,
      children,
      ...triggerHandler
    },
    ref
  ) => {
    const [currentOuterProgress, setCurrentOuterProgress] = useState(1);
    const [currentInnerProgress, setCurrentInnerProgress] = useState(0);

    // Needed for animation on mount
    useEffect(() => {
      const timeout = setTimeout(() => {
        const finalInnerProgress = innerProgress;
        let finalOuterProgress = outerProgress;
        if (double && outerProgress < innerProgress) {
          console.warn(
            `Invalid values! inner progress (${outerProgress}) is
          greater than outer progress (${innerProgress})`
          );
          finalOuterProgress = finalInnerProgress;
        }

        setCurrentOuterProgress(finalOuterProgress);
        setCurrentInnerProgress(finalInnerProgress);
      }, 100);

      return () => clearTimeout(timeout);
    }, [outerProgress, innerProgress, double]);

    let params: DoubleRadialProgressParams;
    switch (size) {
      case "lg":
        params = {
          style: { width: "222px" },
          trailWidth: 2,
          innerStrokeWidth: 6,
          outerStrokeWidth: 12,
          outerWrapperWidth: "110%",
          innerWrapperWidth: "95%",
        };
        break;
      case "sm":
      default:
        params = {
          style: { width: "40px" },
          trailWidth: 6,
          innerStrokeWidth: 10,
          outerStrokeWidth: 18,
          outerWrapperWidth: "110%",
          innerWrapperWidth: "90%",
        };
        break;
    }

    return (
      <div
        ref={ref}
        style={params.style}
        {...triggerHandler}
        data-testid="radial-progress-wrapper"
      >
        {/* The trail */}
        <CircularProgressbarWithChildren
          value={0}
          strokeWidth={params.trailWidth}
          styles={buildStyles({
            pathColor: "transparent",
            trailColor: "var(--grey-2)",
          })}
        >
          <div
            style={{ width: params.outerWrapperWidth }}
            data-testid="background-path-wrapper"
          >
            {/* Background path */}
            <CircularProgressbarWithChildren
              value={currentOuterProgress}
              strokeWidth={params.outerStrokeWidth}
              styles={buildStyles({
                // Fix color if used by imperium
                pathColor: double ? "#edf4fe" : "transparent",
                trailColor: "transparent",
                pathTransition:
                  "stroke-dashoffset 1s cubic-bezier(.06,.65,.28,1) 0s",
              })}
            >
              <div
                style={{ width: params.innerWrapperWidth }}
                data-testid="foreground-path-wrapper"
              >
                {/* Foreground path */}
                <CircularProgressbarWithChildren
                  value={currentInnerProgress}
                  strokeWidth={params.innerStrokeWidth}
                  styles={buildStyles({
                    pathColor: double
                      ? "var(--bs-primary)"
                      : "var(--bs-success)",
                    trailColor: "transparent",
                    pathTransition:
                      "stroke-dashoffset 1s cubic-bezier(.06,.65,.28,1) 0s",
                  })}
                >
                  {children}
                </CircularProgressbarWithChildren>
              </div>
            </CircularProgressbarWithChildren>
          </div>
        </CircularProgressbarWithChildren>
      </div>
    );
  }
);

export default RadialProgress;
