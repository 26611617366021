import { FC, Suspense } from "react";
import {
  BtnSpinnerAutoLoading,
  handleClickModel,
  ModalWithTrigger,
  toastError,
  TriggerElement,
} from "visible-ui";
import SurveyResponsesClient from "../../communication/clients/survey-client";
import {
  currentSurveyAtom,
  surveyAnswersMap,
} from "../../state/atoms/survey-responses-atoms";
import { useRecoilCallback, useRecoilValue } from "recoil";
import { useTranslation } from "react-i18next";
import { Button } from "react-bootstrap";
import styled from "styled-components";
import { getQuestionDependencyStatus } from "./survey-utils";
import { flatQuestionsSurveySelector } from "../../state/selectors/survey-selector";
import { QuestionDependencyStatus } from "../../model/survey";
import { useAuth } from "../../hooks/auth-hooks";

const ModalWrapperStyled = styled.section`
  width: 750px;
  max-height: 700px;
  padding: 0;
  background: linear-gradient(#fff 30%, #ffffff00),
    linear-gradient(#ffffff00, #fff 70%) 0 100%,
    linear-gradient(to top, #fff 0%, var(--grey-4) 100%),
    linear-gradient(to bottom, #fff, var(--grey-2)) 0 100%;
  background-repeat: no-repeat;
  background-size: 100% 100px, 100% 66px, 100% 33px, 100% 33px;
  background-attachment: local, local, scroll, scroll;
`;

const SurveyQuestions = () => {
  const currentSurvey = useRecoilValue(currentSurveyAtom);
  const flatQuestions = useRecoilValue(flatQuestionsSurveySelector);
  const { answersMap } = useRecoilValue(surveyAnswersMap);
  const { t } = useTranslation();

  return (
    <ModalWrapperStyled className="overflow-scroll position-relative px-4">
      {currentSurvey?.sections.map(({ id, name, questions }, i, sections) => {
        return (
          <section key={`section-questions-${id}-${i}`}>
            <section className="d-flex align-items-center gap-3 fw-bold my-4 text-gray-900">
              {t("submitSurvey.sectionSerialChar", {
                serialCharInEn: String.fromCharCode(i + 65),
              })}
              : {name}
            </section>
            <section>
              {questions.map(question => {
                const {
                  question_absolute_number,
                  id,
                  question: questionContent,
                } = question;
                const key = `question-${id}-${question_absolute_number}`;
                const answer = answersMap.get(id);

                const { status } = getQuestionDependencyStatus(
                  question,
                  flatQuestions,
                  answersMap
                );

                return (
                  <section
                    className="d-flex gap-2 mt-3 mb-1 text-gray-700"
                    key={key}
                  >
                    <span>{question_absolute_number + 1}.</span>
                    <section>
                      <div>{questionContent}</div>
                      <div className="fw-bold text-gray-900">
                        {status === QuestionDependencyStatus.IRRELEVANT
                          ? t("generalSurvey.irrelevant")
                          : status === QuestionDependencyStatus.DISABLED
                          ? ""
                          : answer?.will_not_answer
                          ? t(`fillSurvey.${answer?.reason_to_not_answer!}`)
                          : answer?.value}
                      </div>
                    </section>
                  </section>
                );
              })}
            </section>
          </section>
        );
      })}
    </ModalWrapperStyled>
  );
};

interface SurveySubmitModalProps {
  triggerElement: TriggerElement;
}

const SurveySubmitModal: FC<SurveySubmitModalProps> = ({ triggerElement }) => {
  const { t } = useTranslation();

  const { getAccessToken } = useAuth();
  const approve: handleClickModel = useRecoilCallback(
    ({ set }) =>
      async (onStart, onDone) => {
        onStart();
        try {
          const accessToken = await getAccessToken();
          const response = await SurveyResponsesClient.Instance.submitSurvey(
            accessToken
          );
          if (response?.success) {
            set(currentSurveyAtom, _ => (_ ? { ..._, submitted: true } : _));
            return;
          } else {
            toastError(
              response?.error_message || t("errorMessage.submitSurvey")
            );
          }
        } catch (e) {
          console.error(e);
          toastError(t("errorMessage.submitSurvey"));
        } finally {
          onDone();
        }
      },
    [getAccessToken, t]
  );

  return (
    <ModalWithTrigger
      triggerElement={triggerElement}
      id="survey-submit-modal"
      className="p-0 w-100"
    >
      {(show, close) => (
        <>
          <section className="p-4 border-bottom fs-2 fw-bold">
            {t("submitSurvey.reviewYourAnswers")}
          </section>
          <Suspense fallback="Loading...">
            {/* TODO: add nice loading */}
            {show && <SurveyQuestions />}
          </Suspense>
          <section className="p-4">
            <BtnSpinnerAutoLoading
              handleClick={approve}
              done={close}
              variant="primary"
              loadMsg={t("submitSurvey.submitting")}
              className="float-end px-5"
            >
              {t("submitSurvey.submit")}
            </BtnSpinnerAutoLoading>
            <Button variant="link" onClick={close} className="float-end me-4">
              {t("submitSurvey.cancel")}
            </Button>
          </section>
        </>
      )}
    </ModalWithTrigger>
  );
};

export default SurveySubmitModal;
