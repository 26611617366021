export function validatePasswordComplexity(password: string): {
  valid: boolean;
  errorMessage: string;
} {
  const passwordComplexityRegex =
    /((?=.*[0-9])(?=.*[a-z])(?=.*[A-Z])(?=.*[!@#$%]).{8,40})/g;

  let errorMessage = "";

  if (!passwordComplexityRegex.test(password)) {
    errorMessage = "Your password didn't match the policy";
  }

  return {
    valid: !errorMessage,
    errorMessage,
  };
}

export function validateEmail(email: string): boolean {
  // eslint-disable-next-line no-useless-escape
  const re = /^[\w-\.+]+@([\w-]+\.)+[\w-]{2,100}$/;
  return re.test(String(email).toLowerCase());
}

export function validatePhone(phone: string): boolean {
  const re = /^\+?[1-9]\d{7,14}$/g; // E.164 standard
  return re.test(phone);
}

export function validateARN(arn: string): boolean {
  const re = new RegExp("arn:aws:iam::\\d{12}:role/");
  return re.test(arn);
}

export function validatePort(port: string): boolean {
  const re =
    /^((6553[0-5])|(655[0-2][0-9])|(65[0-4][0-9]{2})|(6[0-4][0-9]{3})|([1-5][0-9]{4})|([0-5]{0,5})|([0-9]{1,4}))$/gi;
  return re.test(port);
}

export function validateIPOrHostname(ip: string) {
  const re =
    /((^\s*((([0-9]|[1-9][0-9]|1[0-9]{2}|2[0-4][0-9]|25[0-5])\.){3}([0-9]|[1-9][0-9]|1[0-9]{2}|2[0-4][0-9]|25[0-5]))\s*$)|(^\s*((([0-9A-Fa-f]{1,4}:){7}([0-9A-Fa-f]{1,4}|:))|(([0-9A-Fa-f]{1,4}:){6}(:[0-9A-Fa-f]{1,4}|((25[0-5]|2[0-4]\d|1\d\d|[1-9]?\d)(\.(25[0-5]|2[0-4]\d|1\d\d|[1-9]?\d)){3})|:))|(([0-9A-Fa-f]{1,4}:){5}(((:[0-9A-Fa-f]{1,4}){1,2})|:((25[0-5]|2[0-4]\d|1\d\d|[1-9]?\d)(\.(25[0-5]|2[0-4]\d|1\d\d|[1-9]?\d)){3})|:))|(([0-9A-Fa-f]{1,4}:){4}(((:[0-9A-Fa-f]{1,4}){1,3})|((:[0-9A-Fa-f]{1,4})?:((25[0-5]|2[0-4]\d|1\d\d|[1-9]?\d)(\.(25[0-5]|2[0-4]\d|1\d\d|[1-9]?\d)){3}))|:))|(([0-9A-Fa-f]{1,4}:){3}(((:[0-9A-Fa-f]{1,4}){1,4})|((:[0-9A-Fa-f]{1,4}){0,2}:((25[0-5]|2[0-4]\d|1\d\d|[1-9]?\d)(\.(25[0-5]|2[0-4]\d|1\d\d|[1-9]?\d)){3}))|:))|(([0-9A-Fa-f]{1,4}:){2}(((:[0-9A-Fa-f]{1,4}){1,5})|((:[0-9A-Fa-f]{1,4}){0,3}:((25[0-5]|2[0-4]\d|1\d\d|[1-9]?\d)(\.(25[0-5]|2[0-4]\d|1\d\d|[1-9]?\d)){3}))|:))|(([0-9A-Fa-f]{1,4}:){1}(((:[0-9A-Fa-f]{1,4}){1,6})|((:[0-9A-Fa-f]{1,4}){0,4}:((25[0-5]|2[0-4]\d|1\d\d|[1-9]?\d)(\.(25[0-5]|2[0-4]\d|1\d\d|[1-9]?\d)){3}))|:))|(:(((:[0-9A-Fa-f]{1,4}){1,7})|((:[0-9A-Fa-f]{1,4}){0,5}:((25[0-5]|2[0-4]\d|1\d\d|[1-9]?\d)(\.(25[0-5]|2[0-4]\d|1\d\d|[1-9]?\d)){3}))|:)))(%.+)?\s*$))|(^\s*((?=.{1,255}$)(?=.*[A-Za-z].*)[0-9A-Za-z](?:(?:[0-9A-Za-z]|\b-){0,61}[0-9A-Za-z])?(?:\.[0-9A-Za-z](?:(?:[0-9A-Za-z]|\b-){0,61}[0-9A-Za-z])?)*)\s*$)/;
  return re.test(ip);
}

export function validateCrowdStrikeUrl(baseUrl: string): boolean {
  const re =
    /https:\/\/(api)[-a-zA-Z0-9@:%._+~#=]{0,256}(\.crowdstrike\.com$)/gi;
  return re.test(baseUrl);
}

export function ValidateUsernameOrDomain(usernameOrDomain: string): boolean {
  const re =
    /^[a-zA-Z0-9][a-zA-Z0-9-]{1,61}[a-zA-Z0-9](?:\.[a-zA-Z._-]{2,})*\\[a-zA-Z0-9._-]+$/;
  return re.test(usernameOrDomain);
}

export function validateToken(token: string): boolean {
  const re = /^[A-Za-z0-9-_=]+\.[A-Za-z0-9-_=]+\.?[A-Za-z0-9-_.+/=]*$/;
  return re.test(token);
}

export function validateOktaUrl(baseUrl: string): boolean {
  const re = /https:\/\/[-a-zA-Z0-9@:%._+~#=]{1,256}(\.okta.*\.com$)/gi;
  return re.test(baseUrl);
}

export function validatePydanticId(id: string): boolean {
  const re = /^(?=[a-f\d]{24}$)(\d+[a-f]|[a-f]+\d)/i;
  return re.test(id);
}
