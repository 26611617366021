import React, { FC, Fragment } from "react";

export interface FooterProps {
  elements: Array<React.ReactNode>;
}

const Footer: FC<FooterProps> = ({ elements }) => {
  return (
    <span className="text-gray-600 fs-5 m-auto w-fit d-block my-5 no-wrap">
      {elements.map((e, i) => (
        <Fragment key={`footer-element-${i}`}>
          {i > 0 ? " | " : ""}
          {e}
        </Fragment>
      ))}
    </span>
  );
};

export default Footer;
