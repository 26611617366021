import { FC } from "react";
import { Button, Col, Row } from "react-bootstrap";
import styled from "styled-components";

const TilesGridDoneFloaterImpl = styled.div`
  width: 21.5rem;
  border-radius: 6px;
  background-color: var(--vr-dark-blue);
  padding: 0.625rem 1.5rem;
  position: fixed;
  color: #fff;
  z-index: 1040;
  bottom: 1rem;
  left: 50%;
  transform: translateX(-50%);
  animation: fade-in 0.2s;
  margin-left: 33px;
`;

export interface TilesGridDoneFloaterProps {
  onDone?: () => void;
}

const TilesGridDoneFloater: FC<TilesGridDoneFloaterProps> = ({ onDone }) => {
  return (
    <TilesGridDoneFloaterImpl>
      <Row>
        <Col className="my-auto">Drag tiles around to reposition</Col>
        <Col md="auto">
          <Button size="sm" onClick={onDone}>
            Done
          </Button>
        </Col>
      </Row>
    </TilesGridDoneFloaterImpl>
  );
};

export default TilesGridDoneFloater;
