import { BaseUser } from "../model/auth";
import {
  CommonQuestion,
  CommonQuestionnaireSection,
  QuestionDependencyStatus,
  QuestionnaireResponseAnswer,
} from "../model/questionnaire";

export const isQuestionAnswered = (
  answer: QuestionnaireResponseAnswer | undefined
): boolean => {
  return (
    (answer?.value && answer.value.length > 0) || !!answer?.will_not_answer
  );
};

export const getQuestionDependencyStatus = <T extends BaseUser>(
  currentQuestion: CommonQuestion<T> | undefined,
  questions: CommonQuestion<T>[],
  answersMap: Map<string, QuestionnaireResponseAnswer>
) => {
  const questionDependentOn = questions.find(
    q => q.id === currentQuestion?.depends_on_question?.question_id
  );
  const answerQuestionDependentOn = questionDependentOn
    ? answersMap.get(questionDependentOn.id)
    : undefined;
  const isQuestionDependencyAnswered = isQuestionAnswered(
    answerQuestionDependentOn
  );
  const enabled =
    currentQuestion?.depends_on_question?.dependency_answers_ids?.every(
      id => id && answerQuestionDependentOn?.possible_answer_ids?.includes(id)
    );

  let status = QuestionDependencyStatus.NOT_DEPENDENT;

  if (questionDependentOn) {
    if (isQuestionDependencyAnswered) {
      if (enabled) {
        status = QuestionDependencyStatus.ENABLED;
      } else {
        status = QuestionDependencyStatus.IRRELEVANT;
      }
    } else {
      status = QuestionDependencyStatus.DISABLED;
    }
  }

  return { status, questionDependentOn };
};

export const getSectionPrefixIdx = <T extends BaseUser>(
  sections: CommonQuestionnaireSection<T>[],
  idx: number
): number =>
  sections.slice(0, idx).reduce((acc, { total_questions }) => {
    return (acc += total_questions);
  }, 0);

export const getQuestionPermissionsAsString = <T extends BaseUser>(
  allQuestions: CommonQuestion<T>[],
  subPermissions: string[]
): string =>
  allQuestions
    .reduce((acc, _, i) => {
      const idx = subPermissions?.includes(_.id) ? i : null;
      const accLastIdx = acc.length - 1;
      const follow = acc.length && acc[accLastIdx].includes(i - 1);
      return idx !== null
        ? follow
          ? [...acc.slice(0, accLastIdx), [...acc[accLastIdx], idx]]
          : [...acc, [idx]]
        : acc;
    }, [] as Array<number[]>)
    .map(numbers =>
      numbers.length > 1
        ? `${numbers[0] + 1}-${numbers[numbers.length - 1] + 1}`
        : numbers[0] + 1
    )
    .join(", ");
