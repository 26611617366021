import { FC, useEffect, useRef, useState } from "react";
import styled from "styled-components";
import Tooltip from "./Tooltip";
import BaseComponentProps from "../../../base-component-props";
import { isEllipsis } from "../../../utils/helpers";
import { MaxWidthSpan } from "../../../utils/styled-helpers";
import { PopupPosition } from "reactjs-popup/dist/types";

const TooltipStyled = styled.span`
  font-size: 12px;
  background-color: #505458;
  color: #fff;
  padding: 4px 6px;
  border-radius: 4px;
  max-width: 500px;
`;

interface ShortTxtWithTooltipProps extends BaseComponentProps {
  txt: string | JSX.Element | number;
  maxWidth?: string;
  hideTooltip?: boolean;
  position?: PopupPosition | PopupPosition[] | undefined;
}

const ShortTxtWithTooltip: FC<ShortTxtWithTooltipProps> = ({
  txt,
  maxWidth,
  hideTooltip = false,
  className,
  position,
}) => {
  const ref = useRef<HTMLDivElement | null>(null);

  const [disabled, setDisabled] = useState(hideTooltip);

  useEffect(() => {
    if (ref.current) {
      const triggerElement = ref.current.querySelector(".text-truncate");
      triggerElement && setDisabled(!isEllipsis(triggerElement));
    }
  }, [txt, ref]);

  return (
    <span className="d-inherit" ref={ref}>
      <Tooltip
        position={position}
        dark
        offsetY={5}
        disabled={disabled}
        trigger={
          maxWidth ? (
            <MaxWidthSpan
              className={`${className} d-inline-block text-truncate`}
              maxWidth={maxWidth}
            >
              {txt}
            </MaxWidthSpan>
          ) : (
            <span>{txt}</span>
          )
        }
      >
        <TooltipStyled
          className={`${hideTooltip ? "opacity-0" : ""} first-capitalize`}
        >
          {txt}
        </TooltipStyled>
      </Tooltip>
    </span>
  );
};

export default ShortTxtWithTooltip;
