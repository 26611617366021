import { forwardRef } from "react";
import { Card, CardProps, Dropdown } from "react-bootstrap";
import MoreOptions from "../base/more-options/MoreOptions";

export interface TileProps {
  staticTile?: boolean;
  editMode?: boolean;
  reorderTilesHandler?: () => void;
  hideTileHandler?: () => void;
}

const Tile = forwardRef<HTMLDivElement, TileProps & CardProps>(
  (
    {
      children,
      staticTile,
      editMode,
      reorderTilesHandler,
      //hideTileHandler,
      ...rest
    },
    ref
  ) => {
    return (
      <Card
        body
        ref={ref}
        {...rest}
        className={editMode && !staticTile ? "jiggle" : ""}
      >
        {!staticTile && (
          <MoreOptions className="float-end">
            {_ => (
              <>
                <Dropdown.Item
                  onClick={ev => {
                    ev.stopPropagation();
                    reorderTilesHandler && reorderTilesHandler();
                  }}
                  disabled={editMode}
                >
                  Reorder tiles
                </Dropdown.Item>
                {/* <Dropdown.Item
              disabled
              onClick={ev => {
                ev.stopPropagation();
                hideTileHandler && hideTileHandler();
              }}
            >
              Hide tile
            </Dropdown.Item> */}
              </>
            )}
          </MoreOptions>
        )}
        {children}
      </Card>
    );
  }
);

export default Tile;
