import { AxiosResponse } from "axios";
import { GenericResponse, User } from "../../model";
import BaseAuthorizedRequest from "../requests/base-authorized-request";

export default class DeactivateUserRequest extends BaseAuthorizedRequest<
  GenericResponse,
  GenericResponse
> {
  constructor(targetUser: User, organizationId: string) {
    super("DELETE", `/deactivate/`, {
      payload: targetUser,

      parameters: { organization_id: organizationId },
    });
  }

  processResponse(response: AxiosResponse<GenericResponse>): GenericResponse {
    const result = response.data;
    return result;
  }
}
