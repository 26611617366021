import { FC, Fragment, useCallback, useState } from "react";
import { Form } from "react-bootstrap";
import {
  Question,
  QuestionValidationType,
  QuestionResponseType,
  QuestionnaireResponseAnswer,
  QuestionSubValidationTypes,
} from "../../model/questionnaire";

const NUMBER_REGEX = /^\s*[+-]?(\d+|\.\d+|\d+\.\d+|\d+\.)(e[+-]?\d+)?\s*$/;

interface FreeTextQuestionnaireResponseQuestionProps {
  question: Question;
  answerValue: string[] | undefined;
  onChange?: (
    event: React.ChangeEvent<HTMLInputElement>,
    isInvalid?: boolean
  ) => void;
  t?: (key: string) => string;
  answersMap?: Map<string, QuestionnaireResponseAnswer>;
  disabled?: boolean;
}

const FreeTextQuestionnaireResponseQuestion: FC<
  FreeTextQuestionnaireResponseQuestionProps
> = ({ question, answerValue, onChange, t, answersMap, disabled }) => {
  const [isInvalid, setIsInvalid] = useState(false);
  const [errorMessageKey, setErrorMessageKey] = useState("");

  const validateSubValidation = useCallback(
    (answer: string) => {
      const results = question?.sub_validations?.map(subValidation => {
        const relatedAnswer = answersMap?.get(
          subValidation.related_question_id
        );
        if (relatedAnswer) {
          switch (subValidation.validation_type) {
            case QuestionSubValidationTypes.greater_or_equal:
              if (
                relatedAnswer.value &&
                relatedAnswer.value[0] &&
                Number(answer) < Number(relatedAnswer.value)
              ) {
                return {
                  result: false,
                  message: `Your answer must be larger than your answer to question ${
                    subValidation.related_question_number + 1
                  }`,
                };
              }
              break;
            case QuestionSubValidationTypes.lesser_or_equal:
              if (
                relatedAnswer.value &&
                relatedAnswer.value[0] &&
                Number(answer) > Number(relatedAnswer.value)
              ) {
                return {
                  result: false,
                  message: `Your answer must be smaller than your answer to question ${
                    subValidation.related_question_number + 1
                  }`,
                };
              }
              break;
          }
        }
        return { result: true, message: "" };
      });
      return results || [];
    },
    [answersMap, question?.sub_validations]
  );

  const validateInput = (input: string) => {
    let result = true;
    let errors = [];
    switch (question.validation_type) {
      case QuestionValidationType.numeric:
        if (!NUMBER_REGEX.test(input)) {
          setErrorMessageKey("mustNumber");
          result = false;
        }
        break;
      case QuestionValidationType.sub_validation:
        errors = validateSubValidation(input);
        if (errors.some(error => error.result === false)) {
          setErrorMessageKey(errors.map(error => error.message).join(", "));
          result = false;
        }
        break;
    }
    return result;
  };

  return (
    <Fragment key={`question-response-answer-ft-${question.id}`}>
      <Form.Control
        type="text"
        as={
          question.response_type === QuestionResponseType.FREE_TEXT
            ? "textarea"
            : undefined
        }
        rows={4}
        className="resize-none mt-0"
        value={!disabled ? answerValue : ""}
        onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
          const localIsInvalid = !validateInput(e.target.value);
          setIsInvalid(localIsInvalid);
          onChange && onChange(e, localIsInvalid);
        }}
        isInvalid={isInvalid && !disabled}
        data-testid={`question-response-answer-ft-${question.id}`}
        disabled={disabled}
      />
      <Form.Control.Feedback
        type="invalid"
        data-testid={`free-text-question-invalid-feedback-${question.id}`}
      >
        {(t && t(errorMessageKey)) || "Must be a number"}
      </Form.Control.Feedback>
    </Fragment>
  );
};
export default FreeTextQuestionnaireResponseQuestion;
