import { FC } from "react";
import { Nav } from "react-bootstrap";
import { Link } from "react-router-dom";
import styled from "styled-components";
import { User } from "../../../model";
import { Can } from "../../base";

export interface SideMenuItemProps {
  to?: string;
  icon?: JSX.Element;
  title: string | JSX.Element;
  permissions?: Array<string>;
  userInfo?: User | null;
  eventKey?: string;
  disabled?: boolean;
  itemClassName?: string;
  linkClassName?: string;
  onClick?: () => void;
  customOnClickHandler?: boolean;
  minimize?: boolean;
  isLink?: boolean;
  isBtnActive?: boolean;
  featureFlags?: Record<string, string | number | boolean>;
  id?: string;
  children?: React.ReactNode;
}

const BtnNav = styled.button<{ isBtnActive: boolean }>`
  border: none;
  background-color: initial;
  padding .5rem 1.5rem;
  display: flex;
  &:focus {
    outline: none;
    border: none;
  }
  ${p =>
    p.isBtnActive
      ? `
      position: relative;
      &:before {
        position: absolute;
        content: "";
        border-bottom: 0;
        bottom: .5rem;
        left: 0;
        right: auto;
        top: .5rem;
        border-inline-start: 2px solid var(--bs-blue);
      }
      `
      : `
    &:not(:hover) {
      color: var(--grey-7);
    }
  `}
`;

const TitleNavItem = styled.span<{ show: boolean }>`
opacity: 0;
transition opacity .3s;
${p =>
  p.show &&
  `
  opacity: 1;
  `}
`;

const NavLinkOrBtn: FC<SideMenuItemProps> = ({
  itemClassName,
  icon,
  minimize,
  title,
  disabled,
  to = "",
  eventKey,
  onClick,
  linkClassName,
  isLink,
  isBtnActive,
  id,
}) => {
  return (
    <span className={itemClassName} style={{ height: "44px" }}>
      {isLink ? (
        <Nav.Item as="li">
          <Nav.Link
            id={id}
            disabled={disabled}
            as={Link}
            to={to}
            eventKey={eventKey}
            className={linkClassName}
          >
            {icon}
            <TitleNavItem show={!minimize}>{title}</TitleNavItem>
          </Nav.Link>
        </Nav.Item>
      ) : (
        <BtnNav
          id={id}
          isBtnActive={!!isBtnActive}
          disabled={disabled}
          onClick={onClick}
          className={linkClassName}
        >
          {icon}
          <TitleNavItem show={!minimize}>{title}</TitleNavItem>
        </BtnNav>
      )}
    </span>
  );
};

const SideMenuItem: FC<SideMenuItemProps> = ({
  to,
  eventKey,
  icon,
  title,
  userInfo,
  permissions,
  disabled,
  onClick,
  itemClassName,
  linkClassName,
  minimize = false,
  isLink = true,
  isBtnActive,
  id,
}) => {
  return userInfo && permissions ? (
    <Can
      userInfo={userInfo}
      requiredPermissions={permissions}
      yes={() => (
        <NavLinkOrBtn
          disabled={disabled}
          itemClassName={itemClassName}
          to={to}
          eventKey={eventKey}
          onClick={onClick}
          linkClassName={linkClassName}
          icon={icon}
          minimize={minimize}
          title={title}
          isLink={isLink}
          isBtnActive={isBtnActive}
          id={id}
        />
      )}
      no={() => (
        <NavLinkOrBtn
          disabled={true}
          icon={icon}
          minimize={minimize}
          title={title}
          id={id}
        />
      )}
    />
  ) : (
    <NavLinkOrBtn
      disabled={disabled}
      to={to}
      eventKey={eventKey}
      onClick={onClick}
      icon={icon}
      minimize={minimize}
      title={title}
      isLink={isLink}
      isBtnActive={isBtnActive}
    />
  );
};

export default SideMenuItem;
