import { FC, useEffect, useState } from "react";
import {
  Accordion,
  AccordionButton,
  Col,
  ListGroup,
  Row,
} from "react-bootstrap";
import styled from "styled-components";
import BaseComponentProps from "../../../base-component-props";

interface ContextAwareToggleProps {
  title?: string;
  onClick?: () => void;
  isActive: boolean;
  children?: React.ReactNode;
}

const TitleWithChildrenToggle: FC<ContextAwareToggleProps> = ({
  title,
  onClick,
  isActive,
  children,
}) => {
  return (
    <Row onClick={onClick} className="cursor-pointer">
      <Col
        className={`${isActive ? "fw-bold" : "text-primary "} text-truncate`}
      >
        {title}
      </Col>
      <Col sm={5} xl={3} className="my-auto">
        {children}
      </Col>
    </Row>
  );
};

const StyledListGroupItem = styled<any>(ListGroup.Item)`
  border-color: var(--grey-3) var(--grey-3) var(--grey-2) !important;
  padding: 0px !important;
  &:last-child {
    border-bottom-color: var(--grey-3) !important;
  }
`;

const AccordionTitleWrap = styled.section`
  height: var(--accordion-item-title-height, 70px);
  padding: 24px;
`;

const AccordionBodyWrap = styled.section`
  overflow-y: scroll;
  ::-webkit-scrollbar {
    display: none;
  }
  padding: var(--accordion-item-body-padding, 24px);
  background: linear-gradient(#fff 30%, #ffffff00),
    linear-gradient(#ffffff00, #fff 70%) 0 100%,
    linear-gradient(to top, var(--grey-2), var(--grey-2)),
    linear-gradient(to bottom, #fff, var(--grey-2)) 0 100%;
  background-repeat: no-repeat;
  background-size: 100% 0px, 100% 66px, 100% 1px, 100% 33px;
  background-attachment: local, local, scroll, scroll;
`;

export interface AccordionListGroupProps extends BaseComponentProps {
  items: Array<{
    title: string | undefined;
    body: JSX.Element;
    header?: JSX.Element;
  }>;
  activeKey?: string;
}

const AccordionListGroup: FC<AccordionListGroupProps> = ({
  items,
  style,
  className = "",
  activeKey = "0",
}) => {
  const [currentActiveKey, setCurrentActiveKey] = useState(activeKey);

  useEffect(() => {
    setCurrentActiveKey(activeKey);
  }, [activeKey]);

  return (
    <Accordion activeKey={currentActiveKey} className={className} style={style}>
      <ListGroup className="bg-white rounded-3 ">
        {items.map((item, idx) => {
          const key = idx.toString();
          return (
            <StyledListGroupItem
              key={`accordion-list-item-${item.title}-${key}`}
            >
              {item.title && (
                <AccordionTitleWrap>
                  <AccordionButton
                    as={TitleWithChildrenToggle}
                    title={item.title}
                    onClick={() => {
                      setCurrentActiveKey(key);
                    }}
                    isActive={currentActiveKey === key}
                  >
                    {item.header}
                  </AccordionButton>
                </AccordionTitleWrap>
              )}
              <Accordion.Collapse eventKey={key}>
                <AccordionBodyWrap>{item.body}</AccordionBodyWrap>
              </Accordion.Collapse>
            </StyledListGroupItem>
          );
        })}
      </ListGroup>
    </Accordion>
  );
};

export default AccordionListGroup;
