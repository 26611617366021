import { FC } from "react";
import styled from "styled-components";
import BaseComponentProps from "../../../base-component-props";

const CheckedOrNumericCircleStyled = styled.span<{
  circleDiameter: string;
  fontSize: string;
}>`
  img,
  span {
    width: ${p => p.circleDiameter};
    height: ${p => p.circleDiameter};
  }
  span {
    font-size: ${p => p.fontSize};
  }
`;

export const CHECKED_CIRCLE_SVG_TEST_ID = "checked-circle-wrapper";

interface CheckedOrNumericCircleProps extends BaseComponentProps {
  checked: boolean;
  number: number;
  size?: "sm" | "md";
}

const CheckedOrNumericCircle: FC<CheckedOrNumericCircleProps> = ({
  checked,
  number,
  size = "md",
  ...props
}) => {
  return (
    <CheckedOrNumericCircleStyled
      className="d-flex"
      {...props}
      circleDiameter={size === "md" ? "36px" : "19px"}
      fontSize={size === "md" ? "13px" : "10px"}
    >
      {checked ? (
        <svg
          width="19"
          height="19"
          viewBox="0 0 19 19"
          xmlns="http://www.w3.org/2000/svg"
          data-testid={CHECKED_CIRCLE_SVG_TEST_ID}
        >
          <g fillRule="nonzero" fill="none">
            <circle fill="var(--bs-success)" cx="9.5" cy="9.5" r="9.5" />
            <path
              d="M12.13 6.956a.98.98 0 0 1 1.377.005.96.96 0 0 1 .042 1.315l-.047.05-3.816 3.754a.98.98 0 0 1-1.32.047l-.05-.046-1.817-1.783a.96.96 0 0 1-.007-1.365.98.98 0 0 1 1.326-.054l.05.047L9 10.036l3.132-3.08z"
              fill="#FFF"
            />
          </g>
        </svg>
      ) : (
        <span className="d-flex align-items-center justify-content-center text-gray-600 rounded-circle border border-light">
          {number}
        </span>
      )}
    </CheckedOrNumericCircleStyled>
  );
};

export default CheckedOrNumericCircle;
