import { Dispatch, FC, SetStateAction } from "react";
import { useRecoilCallback, useRecoilState } from "recoil";
import { useTranslation } from "react-i18next";
import { Button } from "react-bootstrap";

import {
  GenericResponse,
  CloudSaveBadge,
  Breakpoint,
  CloudSaveBadgeState,
} from "visible-ui";

import {
  invalidAnswersAtomFamily,
  surveyAnswersMap,
} from "../../../state/atoms/survey-responses-atoms";
import { SurveyAnswer, SurveyQuestion, AnswerStatus } from "../../../model/survey";

interface SurveyResponseQuestionControllerProps {
  question: SurveyQuestion;
  answerStatus: AnswerStatus;
  setAnswerStatus: Dispatch<SetStateAction<AnswerStatus>>;
  setCheckedIds: (value: SetStateAction<(string | undefined)[]>) => void;
  handleSubmitQuestion: (
    handler: (answer: SurveyAnswer) => Promise<GenericResponse | null>,
    answer: Partial<SurveyAnswer>,
    selectedOptionsIds: Array<string | undefined>,
    newStatus: AnswerStatus
  ) => Promise<void>;
  isSaving: boolean;
  submitAnswer: (answer: SurveyAnswer) => Promise<GenericResponse | null>;
}

const SurveyResponseQuestionController: FC<
  SurveyResponseQuestionControllerProps
> = ({
  question,
  answerStatus,
  setAnswerStatus,
  setCheckedIds,
  handleSubmitQuestion,
  isSaving,
  submitAnswer,
}) => {
  const { t } = useTranslation();

  const [{ answersMap }] = useRecoilState(surveyAnswersMap);

  const currentAnswer = answersMap.get(question.id);

  const resetAnswer = useRecoilCallback(({ set }) => () => {
    setCheckedIds([]);
    set(invalidAnswersAtomFamily(question.id), { valid: true, message: "" });
    question.sub_validations?.forEach(_ =>
      set(invalidAnswersAtomFamily(_.related_question_id), {
        valid: true,
        message: "",
      })
    );
    handleSubmitQuestion(
      submitAnswer,
      { id: currentAnswer?.id },
      [],
      AnswerStatus.Empty
    );
  });

  return answerStatus === AnswerStatus.Empty ? (
    <section className="d-flex justify-content-end">
      <Button
        variant="link"
        className="text-primary p-0"
        onClick={() => setAnswerStatus(AnswerStatus.CantAnswer)}
      >
        {t("fillSurvey.cantWontAnswer")}
      </Button>
    </section>
  ) : currentAnswer ? (
    <section className="d-flex justify-content-end gap-2">
      <CloudSaveBadge
        fullShowBreakpoint={Breakpoint.md}
        state={isSaving ? CloudSaveBadgeState.Saving : CloudSaveBadgeState.Idle}
        savedLabel={
          currentAnswer?.answered_by
            ? t("fillSurvey.answeredByName", {
                name: currentAnswer.answered_by,
              })
            : t("fillSurvey.saved")
        }
      />
      <Button variant="link" className="p-0" onClick={resetAnswer}>
        {t("fillSurvey.clearResponse")}
      </Button>
    </section>
  ) : (
    <></>
  );
};

export default SurveyResponseQuestionController;
