import { User, UserTutorialStatus } from "../model/auth";
import { toastError, toastSuccess } from "../utils/toaster";
import UsersClient from "../communication/clients/users-client";

export const getUpdatedUsers: (users: User[], userSaved: User) => User[] = (
  users,
  userSaved
) => {
  const idx = users.findIndex(user => user.id === userSaved.id);
  return idx !== -1
    ? [...users.slice(0, idx), userSaved, ...users.slice(idx + 1)]
    : [...users, userSaved];
};

const _updateUser = async (
  user: User,
  currentChampionId?: string
): Promise<User | void> => {
  const res = await UsersClient.Instance.updateUser(user, currentChampionId);
  if (res?.success) {
    toastSuccess(`Updated ${user.full_name} successfully`);
    return user;
  } else {
    toastError(res?.error_message || "Something went wrong when updating user");
  }
};

const _addUser = async (newUser: User): Promise<User | void> => {
  const registeredUser = await UsersClient.Instance.registerUser(newUser);
  if (registeredUser) {
    toastSuccess(`Created new user ${newUser.full_name} successfully`);
    return { ...newUser, id: registeredUser.id, auth0_id: registeredUser.auth0_id };
  } else {
    toastError("Something went wrong when creating new user");
  }
};

export const saveUser = async (
  userToSave: User,
  onSuccess: (userSaved: User) => void,
  currentChampionId?: string
) => {
  const isUpdateUser = !!userToSave.id;
  try {
    const userSaved = isUpdateUser
      ? await _updateUser(userToSave, currentChampionId)
      : await _addUser(userToSave);
    userSaved && onSuccess(userSaved);
  } catch (error) {
    toastError(`Failed to ${isUpdateUser ? "updating" : "register new"} user`);
  }
};

export const getEmptyUser = (
  organization_id: string,
  organization_name: string,
  isVR = false
): User => {
  return {
    full_name: "",
    email: "",
    organization_id,
    organization_name,
    handler: "",
    disabled: false,
    permissions: [],
    metadata: {
      tutorials: {
        cp1_dashboard_banner: UserTutorialStatus.Unseen,
        cp2_dashboard_banner: UserTutorialStatus.Unseen,
      },
    },
    super_mode: isVR,
    roles: [],
    phone: "",
  };
};
