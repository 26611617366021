import { useAuth0 } from "@auth0/auth0-react";

export const useAuth = () => {
  const { getAccessTokenSilently, logout: auth0Logout, user } = useAuth0();

  const getAccessToken = getAccessTokenSilently

  const logout = () => auth0Logout({ returnTo: window.location.origin })

  return {
    getAccessToken, logout, user,
  };
};