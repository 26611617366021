import { FC } from "react";
import { Container } from "react-bootstrap";
import styled, { CSSProperties } from "styled-components";
import BaseComponentProps from "../../../base-component-props";

const IndexContainer = styled.div`
  transition: top 250ms ease-out 0ms;
`;

export interface FormWithIndexProps extends BaseComponentProps {
  index: JSX.Element;
  form: JSX.Element;
  styleFormWithIndex?: StyleFormWithIndexProps;
  disabled?: boolean;
}

export interface StyleFormWithIndexProps {
  container?: CSSProperties;
  indexSection?: CSSProperties;
  formSection?: CSSProperties;
  topSticky?: string;
}

const FormWithIndex: FC<FormWithIndexProps> = ({
  index,
  form,
  className = "",
  styleFormWithIndex,
  disabled,
}) => {
  return (
    <Container
      className={`d-flex ${className} ${disabled ? "vr-disabled" : ""}`}
      style={styleFormWithIndex?.container || { marginTop: "50px" }}
    >
      <div
        className="px-3"
        style={styleFormWithIndex?.indexSection || { minWidth: "275px" }}
      >
        <IndexContainer
          className="position-sticky"
          style={{
            top: styleFormWithIndex?.topSticky || "8.5rem",
          }}
        >
          {index}
        </IndexContainer>
      </div>
      <div
        className="px-3"
        style={styleFormWithIndex?.formSection || { minWidth: "825px" }}
      >
        {form}
      </div>
    </Container>
  );
};

export default FormWithIndex;
