import { FC } from "react";
import styled from "styled-components";
import {
  BaseFilterBy,
  FilterByRowProps,
  ToggleFilter,
} from "../../../../model/table";

const FilterRowPreviewStyled = styled.section`
  min-height: 52px;
`;

interface FilterRowPreviewProps extends FilterByRowProps {
  toggleFilter: ToggleFilter;
  filterBy: BaseFilterBy;
}

const FilterRowPreview: FC<FilterRowPreviewProps> = ({
  node,
  toggleFilter,
  filterBy,
  title,
}) => {
  return (
    <FilterRowPreviewStyled className="d-flex align-items-center justify-content-between">
      <span className="fs-5">{title}</span>
      {node(toggleFilter, filterBy)}
    </FilterRowPreviewStyled>
  );
};

export default FilterRowPreview;
