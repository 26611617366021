import { FC } from "react";
import ArrowsPagination from "./ArrowsPagination";
import { PaginationType, TablePaginationModel } from "../../../../model/table";
import LoadMorePagination from "./LoadMorePagination";

/**
 * @description  Pagination rendering by type
 * @param {PaginationType} pagination
 * @param {number} limit
 * @param {number} page
 * @param {number} count
 * @param {boolean | undefined} loading
 * @param {(page: number) => void} setPage
 */
const TablePaginationMap: FC<TablePaginationModel> = ({
  pagination,
  ...props
}) => {
  switch (pagination) {
    case  PaginationType.LoadMore:
      return <LoadMorePagination {...props} />;
    default:
      return <ArrowsPagination {...props} />;
  }
};

export default TablePaginationMap;
