import { CSSProperties, forwardRef } from "react";
import BaseComponentProps from "../../base-component-props";

interface TitleProps {
  text: JSX.Element | string;
  onClick?: (e: React.MouseEvent<HTMLHeadingElement, MouseEvent>) => void;
  className?: string;
  style?: CSSProperties;
}

export interface PageTitleProps extends BaseComponentProps {
  title: TitleProps;
  preTitle?: TitleProps;
  postTitle?: TitleProps;
  divider?: boolean;
  dividerClassName?: string;
}

const PageTitle = forwardRef<HTMLDivElement, PageTitleProps>(
  (
    { title, preTitle, postTitle, divider, dividerClassName, className = "" },
    ref
  ) => {
    return (
      <div ref={ref} className={`header ${className}`}>
        <div
          className={`${divider ? "header-body" : ""} ${
            dividerClassName || ""
          }`}
          data-testid="page-title-divider"
        >
          {preTitle && (
            <h6
              className={`header-pretitle ${preTitle.className}`}
              onClick={preTitle.onClick}
            >
              {preTitle.text}
            </h6>
          )}
          <h1
            className={`header-title ${title.className}`}
            onClick={title.onClick}
            style={title.style}
          >
            {title.text}
          </h1>
          {postTitle && (
            <p
              className={`mb-5 text-muted ${postTitle.className}`}
              onClick={postTitle.onClick}
            >
              {postTitle.text}
            </p>
          )}
        </div>
      </div>
    );
  }
);

export default PageTitle;
