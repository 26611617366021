import { BaseAuthorizedRequest } from "visible-ui";
import { AxiosResponse } from "axios";
import { SurveyAnswers } from "../../model/survey";

export default class GetLatestAnswersRequest extends BaseAuthorizedRequest<
  SurveyAnswers,
  SurveyAnswers
> {
  constructor(accessToken: string) {
    super("GET", `/answers/`, undefined, accessToken);
  }

  processResponse(response: AxiosResponse<SurveyAnswers>): SurveyAnswers {
    let result = response.data;
    return result;
  }
}
