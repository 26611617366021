import { AxiosResponse } from "axios";
import { BucketType, StaticAssetUrlResponse } from "../../model/static-assets";
import BaseAuthorizedRequest from "../requests/base-authorized-request";

export default class GeneratePreSignedDownloadLinkRequest extends BaseAuthorizedRequest<
  Array<StaticAssetUrlResponse>,
  Array<StaticAssetUrlResponse>
> {
  constructor(
    filesNames: Array<string>,
    bucketType: BucketType = BucketType.engagement_files
  ) {
    super("PUT", `/static/url/download`, {
      payload: { files_names: filesNames },
      parameters: { bucket_type: bucketType },
    });
  }

  processResponse(
    response: AxiosResponse<Array<StaticAssetUrlResponse>>
  ): Array<StaticAssetUrlResponse> {
    const result = response.data;
    return result;
  }
}
