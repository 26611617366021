import { FC, useState } from "react";
import { Button } from "react-bootstrap";
import { union } from "lodash";
import styled from "styled-components";
import { Survey } from "../../model/survey";
import { MultipleChoiceBaseOption } from "visible-ui";
import { useTranslation } from "react-i18next";
import { useRecoilValue } from "recoil";
import { flatQuestionsSurveySelector } from "../../state/selectors/survey-selector";

const SubPermissionsStyled = styled.section`
  max-height: 700px;
  background: linear-gradient(#fff 30%, #ffffff00),
    linear-gradient(#ffffff00, #fff 70%) 0 100%,
    linear-gradient(to top, #fff 0%, var(--grey-4) 100%),
    linear-gradient(to bottom, #fff, var(--grey-4)) 0 88%;
  background-repeat: no-repeat;
  background-size: 100% 100px, 100% 220px, 100% 35px, 100% 33px;
  background-attachment: local, local, scroll, scroll;
`;

interface QuestionsMultiSelectProps {
  subPermissions: string[];
  setSubPermissions: (subPermissions: string[]) => void;
  onClose: () => void;
  questionnaireResponseData: Survey;
}

const QuestionsMultiSelect: FC<QuestionsMultiSelectProps> = ({
  subPermissions,
  setSubPermissions,
  onClose,
  questionnaireResponseData,
}) => {
  const { t } = useTranslation();
  const [_subPermissions, _setSubPermissions] =
    useState<string[]>(subPermissions);
  const flatQuestions = useRecoilValue(flatQuestionsSurveySelector);

  return (
    <SubPermissionsStyled className=" pt-3 w-100 bg-white z-high overflow-scroll position-relative">
      <section className="fade-in d-flex flex-column gap-3 px-4">
        {questionnaireResponseData?.sections.map((section, i, sections) => {
          const { questions, name } = section;
          const isEntireSection = questions.every(_ =>
            _subPermissions.includes(_.id)
          );
          const sectionQuestionsIds = questions.map(_ => _.id);
          return (
            <section key={`sub-permissions-row-${i}`}>
              <section className="d-flex align-items-center gap-3">
                <b>{name}</b>
                <Button
                  className="border-0" //
                  variant="link"
                  onClick={() =>
                    _setSubPermissions(_subPermissions =>
                      isEntireSection
                        ? _subPermissions.filter(
                            subPermission =>
                              !sectionQuestionsIds.includes(subPermission)
                          )
                        : union(_subPermissions, sectionQuestionsIds)
                    )
                  }
                >
                  {isEntireSection
                    ? t("shareSurvey.deselectEntireSection")
                    : t("shareSurvey.selectEntireSection")}
                </Button>
              </section>
              <section>
                {questions.map(question => {
                  const id = `question-${
                    question.question_absolute_number
                  }`;
                  const checked = _subPermissions.includes(question.id);
                  const questionDependentOn = flatQuestions.find(
                    q => q.id === question?.depends_on_question?.question_id
                  );
                  const questionDependentOnChecked =
                    !!questionDependentOn &&
                    _subPermissions.includes(questionDependentOn.id);

                  const disabled =
                    !!questionDependentOn && !questionDependentOnChecked;

                  // filter out questions that are dependent on other questions that are not checked
                  if (disabled && checked) {
                    _setSubPermissions(_subPermissions =>
                      _subPermissions.filter(
                        subPermission => subPermission !== question.id
                      )
                    );
                  }

                  return (
                    <section
                      key={id}
                      title={
                        disabled
                          ? t("generalSurvey.dependentOnTitle", {
                              num: questionDependentOn?.question_absolute_number + 1,
                            })
                          : ""
                      }
                    >
                      <MultipleChoiceBaseOption
                        id={id}
                        className="py-2 my-1"
                        checked={checked}
                        disabled={disabled}
                        type="checkbox"
                        onChange={() =>
                          _setSubPermissions(_subPermissions =>
                            checked
                              ? _subPermissions.filter(_ => _ !== question.id)
                              : [question.id, ..._subPermissions]
                          )
                        }
                      >
                        {question.question_absolute_number + 1}.{" "}
                        {question.question}
                      </MultipleChoiceBaseOption>
                    </section>
                  );
                })}
              </section>
            </section>
          );
        })}
      </section>

      <section className="bg-white p-4 bottom-0 position-sticky d-flex align-items-center justify-content-end gap-3">
        <Button
          type="button"
          variant="link"
          onClick={() => {
            _setSubPermissions(subPermissions);
            onClose();
          }}
        >
          {t("shareSurvey.back")}
        </Button>
        <Button
          type="button"
          onClick={() => {
            setSubPermissions(_subPermissions);
            onClose();
          }}
        >
          {_subPermissions.length
            ? t("shareSurvey.doneNumberSelected", {
                number: _subPermissions.length,
              })
            : t("shareSurvey.doneSelecting")}
        </Button>
      </section>
    </SubPermissionsStyled>
  );
};

export default QuestionsMultiSelect;
