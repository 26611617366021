import { FC } from "react";
import { Spinner } from "react-bootstrap";
import Lottie from "react-lottie-player";
import LoadingAnimation from "../../../lotties/loading_animation.json";

export interface LoadingProps {
  variant?: "spinner" | "vr";
  size?: "sm";
}

const Loading: FC<LoadingProps> = ({ variant = "vr", size }) => {
  return variant === "spinner" ? (
    <Spinner
      animation="border"
      variant="primary"
      size={size}
      data-testid="loading-spinner"
    />
  ) : (
    <Lottie
      loop
      play
      animationData={LoadingAnimation}
      style={{
        width: size === "sm" ? 64 : 128,
        height: size === "sm" ? 64 : 128,
      }}
      title="loading-vr"
    />
  );
};

export default Loading;
