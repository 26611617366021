import { DateIosString } from "../utils/time";
import { EngagementProgressComponent } from "./engagement-progress";

export enum EngagementTaskState {
  locked = "locked",
  active = "active",
  completed = "completed",
  archived = "archived",
}

export enum EngagementTaskType {
  report = "report",
  review_session = "review_session",
  questionnaire = "questionnaire",
  tool = "tool",
  document = "document",
  requested_file = "requested_file",
  ng_tool = "ng_tool",
  ci_tool = "ci_tool",
  requested_file_tool = "requested_file_tool",
}

export interface BaseEngagementTask {
  id: string;
  state: EngagementTaskState;
  priority: number;
}

export interface EngagementTask extends BaseEngagementTask {
  item_id: string;
  engagement_id: string;
  name: string;
  task_type: EngagementTaskType;
  created_at?: DateIosString;
  updated_at?: DateIosString;
}

export interface EngagementTaskMetaData {
  status: EngagementTaskStatus;
}

export interface EngagementTaskEx<MetadataT extends EngagementTaskMetaData>
  extends EngagementTask {
  meta_data: MetadataT;
}

export enum EngagementTaskStatus {
  locked = "Locked",
  awaiting_completion = "Awaiting completion",
  completed = "Completed",
}

export interface QuestionnaireTaskMetaData extends EngagementTaskMetaData {
  status: EngagementTaskStatus;
  progress: Array<EngagementProgressComponent>;
}
