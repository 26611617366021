import { FC } from "react";
import { useInView } from "react-cool-inview";
import "intersection-observer";
import { useRecoilValue, useSetRecoilState } from "recoil";

import { EllipsisCard, PageTitle } from "visible-ui";

import { SurveySection } from "../../model/survey";
import SurveyResponseQuestion from "./survey-response-question/SurveyResponseQuestion";
import { activeKeyAtom } from "../../state/atoms/atoms";
import { flatQuestionsSurveySelector } from "../../state/selectors/survey-selector";

interface SurveyResponseSectionProps {
  section: SurveySection;
  refs?: Record<string, React.RefObject<HTMLDivElement>>;
  hideTitle?: boolean;
}

const SurveyResponseSection: FC<SurveyResponseSectionProps> = ({
  section,
  hideTitle,
  refs = {},
}) => {
  const { observe: lastQuestionObserve } = useInView<HTMLDivElement>({
    threshold: 0.5, // 50% of the element needs to be visible in order to trigger
    onEnter: ({ entry }) => {
      // when element enters the screen set the eventKey
      setActiveKey(section.section_number.toString());
    },
  });

  const { observe: pageTitleObserve } = useInView<HTMLDivElement>({
    threshold: 1, // 100% of the element needs to be visible in order to trigger
    onEnter: ({ entry }) => {
      // when element enters the screen set the eventKey
      setActiveKey(section.section_number.toString());
    },
  });

  const { questions } = section;
  const setActiveKey = useSetRecoilState(activeKeyAtom);
  const flatQuestions = useRecoilValue(flatQuestionsSurveySelector);
  const questionNumbers = questions.map((question) => question.question_number)

  return (
    <div data-testid="survey-response-section" className="mb-6">
      {!hideTitle && (
        <PageTitle
          ref={pageTitleObserve}
          divider
          dividerClassName="pt-0"
          preTitle={{
            text: `section ${String.fromCharCode(section.section_number + 65)}`,
          }}
          title={{ text: section.name }}
        />
      )}
      {questionNumbers.map((number, idx, questionNumbers) => {
        const isPrevQuestion =
          !!idx &&
          !!questions.find(
            ({ question_number }) => question_number === questionNumbers[idx-1]
          );
        const currentQuestion = questions.find(
          ({ question_number }) =>  question_number === number
        );
        const key = `survey-question-${section.id}-${number}`;
        if (currentQuestion) {
          return (
            <SurveyResponseQuestion
              key={key}
              question={currentQuestion}
              cardRef={refs[currentQuestion.id]}
              flatQuestions={flatQuestions}
              ref={number === questionNumbers[questionNumbers.length - 1] ? lastQuestionObserve : null}
            />
          );
        } else if (isPrevQuestion) {
          // Avoid duplicating the Ellipsis Card
          return (
            <EllipsisCard
              data-testid="ellipsis-card"
              className="mb-4"
              key={key}
            />
          );
        } else {
          return null;
        }
      })}
    </div>
  );
};

export default SurveyResponseSection;
