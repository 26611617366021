import { FC } from "react";
import { Button, Image } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import { useRecoilValue } from "recoil";
import { CircleIconButtonTooltip, Divider } from "visible-ui";
import { myInfoAtom } from "../../state/atoms/me-atoms";
import { useAuth } from "../../hooks/auth-hooks";

const UserInfo: FC = () => {
  const { t } = useTranslation();
  const myInfo = useRecoilValue(myInfoAtom);
  const { logout } = useAuth();

  return (
    <>
      <CircleIconButtonTooltip
        offsetX={10}
        closeOnScroll
        userLocale={myInfo?.locale}
        icon={
          <Image
            className="opacity-75"
            src={`${process.env.PUBLIC_URL}assets/img/user-info.svg`}
            alt="User Info"
          />
        }
      >
        <section className="vr-card d-flex flex-column position-relative overflow-hidden">
          <section className="p-4 d-flex flex-column gap-3">
            <span className="text-gray-700 mb-1">
              {myInfo?.organization_name}
            </span>
            <section>
              <div>{myInfo?.name}</div>
              <div className="text-gray-700">{myInfo?.email}</div>
            </section>
          </section>
          <Divider overrideClass className="m-0" />
          <Button
            // use shadow-none to remove the box-shadow on :focus-visible
            className="btn-toolbar capitalize p-4 shadow-none"
            size="lg"
            variant="link"
            onClick={logout}
          >
            {t("layout.logOut")}
          </Button>
        </section>
      </CircleIconButtonTooltip>
    </>
  );
};

export default UserInfo;
