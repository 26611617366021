import { FC } from "react";
import { Button, ButtonProps, Spinner } from "react-bootstrap";
import styled from "styled-components";

const EXTRA_SMALL = "extra-small";

const SpinnerWrapperStyled = styled.span`
  &.${EXTRA_SMALL} {
    .spinner-border {
      width: 9px;
      height: 9px;
      border-width: 0.15em;
    }
  }
`;
export interface BtnSpinnerLoadingProps extends ButtonProps {
  spinnerClassName?: string;
  isLoading: boolean;
  extraSmall?: boolean;
  type?: "button" | "submit" | "reset" | undefined;
}

const BtnSpinnerLoading: FC<BtnSpinnerLoadingProps> = ({
  disabled = false,
  spinnerClassName = "me-2",
  isLoading,
  children,
  extraSmall = true,
  ...props
}) => {
  return (
    <Button disabled={disabled || isLoading} {...props}>
      {isLoading ? (
        <SpinnerWrapperStyled
          className={`d-flex align-items-center justify-content-center ${
            extraSmall ? EXTRA_SMALL : ""
          }`}
        >
          <Spinner
            as="span"
            animation="border"
            size="sm"
            role="status"
            aria-hidden="true"
            className={spinnerClassName}
          />
          {children}
          <span className="visually-hidden">Loading...</span>
        </SpinnerWrapperStyled>
      ) : (
        children
      )}
    </Button>
  );
};

export default BtnSpinnerLoading;
