import { CSSProperties, FC } from "react";
import { Table } from "react-bootstrap";
import styled from "styled-components";
import { BaseTableModel, BaseTableRowModel } from "../../../../model/table";
import BaseTableHeader from "./BaseTableHeader";
import BaseTableRow from "./BaseTableRow";

const TbodyStyled = styled.tbody<{ rowsLength: number }>`
  height: ${p => p.rowsLength * 51 + "px"};
  transition: height 0.2s;
`;

const BaseTable: FC<BaseTableModel> = ({
  headers = null,
  multiSelectHeader,
  rows,
  onSort,
  ...props
}) => {
  return (
    <Table className="m-0" {...props}>
      {headers && (
        <thead>
          <tr style={{ ["--bg-row" as keyof CSSProperties]: "var(--grey-1)" }}>
            {multiSelectHeader}
            {headers?.map((header, i) => (
              <BaseTableHeader
                key={`base-table-header-${header.title}-${i}`}
                onSort={() => onSort && onSort(i)}
                {...header}
              />
            ))}
          </tr>
        </thead>
      )}
      <TbodyStyled rowsLength={rows.length}>
        {rows.map((row: BaseTableRowModel, rowIdx: number) => (
          <BaseTableRow key={`base-table-row-${rowIdx}`} {...row} />
        ))}
      </TbodyStyled>
    </Table>
  );
};

export default BaseTable;
