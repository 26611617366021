import { FC, useState } from "react";
import { Container, Image } from "react-bootstrap";
import styled from "styled-components";
import { Collapse } from "react-collapse";
import { TOP_BAR_HEIGHT } from "../layout/TopBarLayout";
import { useScroll } from "visible-ui";
import { useTranslation } from "react-i18next";
import { useMe } from "../../hooks/use-me";

const Title = styled.b`
  font-size: 22px;
`;

const BannerContainer = styled(Container)<{ $atTop: boolean }>`
  top: ${TOP_BAR_HEIGHT}px;
  margin-block-start: 48px;
  z-index: 1;
  transition: max-width 0.3s, top 0.3s;
  max-width: ${p => (p.$atTop ? "" : "100vw !important")};
  padding-inline: 0 !important;
  .banner-card {
    transition: height 0.3s;
    height: ${p => (p.$atTop ? "128px" : "80px")};
  }
`;

interface SurveyResponseBannerProps {
  open: boolean;
}

const SurveyResponseBanner: FC<SurveyResponseBannerProps> = ({ open }) => {
  const [atTop, setAtTop] = useState(window.scrollY <= TOP_BAR_HEIGHT);
  const { t } = useTranslation();

  const { me } = useMe();

  useScroll(() => {
    if (window.scrollY <= TOP_BAR_HEIGHT) {
      setAtTop(true);
    } else if (window.scrollY > TOP_BAR_HEIGHT + 50) {
      setAtTop(false);
    }
  });

  return (
    <BannerContainer $atTop={atTop} className="position-sticky">
      <Collapse isOpened={open}>
        <section
          className={`banner-card ${
            atTop ? "rounded-2" : ""
          } bg-primary d-flex flex-row align-items-center text-white justify-content-center gap-3`}
        >
          {me?.owner ? (
            <>
              {" "}
              <Image
                className="me-4"
                src={`${process.env.PUBLIC_URL}/assets/img/banner-first-step.svg`}
              />
              <Title className="mx-4">{t("submitSurvey.almostDone")}</Title>
              <span>{t("submitSurvey.selectReviewAndSubmit")}</span>
            </>
          ) : (
            <>
              <Image
                className=""
                src={`${process.env.PUBLIC_URL}/assets/img/cloud-saved-white.svg`}
              />
              <span>
                {t("submitSurvey.allQuestionsAnswered", {
                  ownerName: me?.organization_owner_name,
                })}
              </span>
            </>
          )}
        </section>
      </Collapse>
    </BannerContainer>
  );
};

export default SurveyResponseBanner;
