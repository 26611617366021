import { FC, Fragment } from "react";
import { DropEvent, FileRejection, useDropzone } from "react-dropzone";
import styled from "styled-components";

const DzContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 1.8rem 1rem;
  border-width: 1px;
  border-radius: 0.375rem;
  border-style: dashed;
  color: var(--grey-6);
  outline: none;
  transition: all 0.2s ease-in-out;
  cursor: pointer;
  &:hover {
    border-color: var(--grey-6);
    color: var(--bs-dark);
  }
  ${(props: { isDragActive: boolean }) =>
    props.isDragActive
      ? "border-color: var(--grey-6)"
      : "border-color: var(--grey-4)"}
`;

export interface DragAndDropFilesProps {
  onDrop?: (
    acceptedFiles: Array<File>,
    rejectedFiles: Array<FileRejection>,
    event: DropEvent
  ) => void;
  children(props: File[]): JSX.Element;
}

const DragAndDropFiles: FC<DragAndDropFilesProps> = ({ onDrop, children }) => {
  const {
    acceptedFiles,
    getRootProps,
    getInputProps,
    isDragActive,
    isDragAccept,
    isDragReject,
  } = useDropzone({ onDrop: onDrop });

  return (
    <Fragment>
      <DzContainer
        {...getRootProps({ isDragActive, isDragAccept, isDragReject })}
      >
        <input {...getInputProps()} />
        <span>Drag and drop or click to upload file(s)</span>
      </DzContainer>
      {children(acceptedFiles)}
    </Fragment>
  );
};

export default DragAndDropFiles;
