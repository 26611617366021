import { FC } from "react";
import styled from "styled-components";
import ExternalLink from "../external-link/ExternalLink";


export const PrivacyContentContainerStyled = styled.section`
  max-width: 800px;
  h1,
  h2 {
    font-size: 20px;
    font-weight: bold;
    margin-block: 34px;
  }
  p {
    color: var(--grey-7);
    margin-block-end: 24px;
  }
  li {
    color: var(--grey-7);
    margin-block: 12px;
  }
`;


const PrivacyContent: FC = () => {
    return (
      <PrivacyContentContainerStyled>
        <p>
          This Privacy Policy explains how we collect your Personal Data
          (defined below) on web site(s) of Cyber Assessments, Inc. and its
          wholly owned subsidiaries (together, "VisibleRisk") that post or link
          directly to this Privacy Policy including but not limited to{" "}
          <ExternalLink linkAddress="https://ec.europa.eu/info/law/law-topic/data-protection/international-dimension-data-protection/standard-contractual-clauses-scc_en" />{" "}
          , including any customer portal reached through such site(s)
          (collectively, the "Site") and through offline means, how we use,
          disclose, and protect such data, and the choices you have concerning
          our use of such data. Please read this Privacy Policy carefully.
          VisibleRisk may from time to time update this Privacy Policy. We may
          update this Privacy Policy from time to time. In the event of any
          material change by us to this Privacy Policy, we will notify you by
          email (sent to the email specified in your account) or by means of a
          notice on <ExternalLink linkAddress="http://www.visiblerisk.com/" /> prior to
          the change becoming effective and your continued use of the Sites and
          Services will indicate your acknowledgement of such changes and
          agreement to be bound by the modified Privacy Policy terms. If you
          object to any such changes, you may not continue to access or use the
          Sites or Services. We encourage you to periodically review this page
          for the latest information on our privacy practices.
        </p>
        <p>
          For the purposes of this Privacy Policy, "VisibleRisk," "we," "us,"
          and "our" means VisibleRisk, Inc. and VisibleRisk, Ltd. VisibleRisk
          affiliates means BitSight Technologies, Inc., and its affiliated
          entities.
        </p>
        <p>
          You do not have to provide us with any Personal Data to visit the
          Site. However, if you choose to withhold requested information, you
          may not be able to visit all sections or use all features of the Site.
        </p>
        <p>
          This Privacy Policy shall also apply to any other forms of VisibleRisk
          online activity that reference this Privacy Policy. In such instances,
          the term "Site" shall include the applicable mobile application or
          other VisibleRisk online activity whenever that term is used herein.
          In connection with your use of any VisibleRisk mobile application,
          your Personal Data (and Other Data, as described below) may also be
          collected and used by your mobile device manufacturer, the mobile
          application store from which you obtained the VisibleRisk mobile
          application, your telecommunication provider, or other third parties
          involved in the delivery of the application or data to your mobile
          device, and such collection and use shall be subject to the privacy
          policy of the applicable third party. VisibleRisk is not responsible
          for any third party’s privacy practices.
        </p>
        <h2>Types of Data Collected</h2>
        <p>We gather Personal Data and Other Data (as described below).</p>
        <h2>Collection of Personal Data</h2>
        <p>
          Personal data is information that identifies you or can be used to
          identify or contact you, e.g., your name, email address, address, or
          phone number ("Personal Data"). We may need to collect and process
          Personal Data in order to provide requested information, products or
          services to you (including the Site) or because we are legally
          required to do so.
        </p>
        <ul>
          <li>
            VisibleRisk requests Personal Data from you if the Site requires
            registration, including to establish an online account for
            designated employees of VisibleRisk’s corporate customers
            ("Customers"). The registration process requests that you provide
            Personal Data, such as your name, business email address, business
            address or country/jurisdiction location, business phone number, and
            company affiliation, and agree to the Terms of Use presented at the
            time of registration.
          </li>
          <li>
            VisibleRisk may receive Personal Data from social media platforms.
            For example, if you elect to use your social media credentials to
            log-in to your Site account, certain Personal Data from your social
            media account will be shared with us, which may include Personal
            Data that is part of your social media profile.
          </li>
          <li>
            VisibleRisk may collect Personal Data voluntarily provided by you,
            including in emails, online forms, order and registration forms,
            surveys, promotion participation, online chats, phone calls, and
            through other means.
          </li>
          <li>
            VisibleRisk may request Personal Data from our Customers to comply
            with applicable anti-money laundering laws and other applicable
            laws.
          </li>
          <li>
            You may be able to maintain "accounts" on the Site, where, if you
            are a designated employee of a Customer, you have the option to view
            and download information, updates, software, and analytics relating
            to VisibleRisk’s assessment services for your company, as well as
            update information about the Customer’s organization. To be useful,
            this information must be stored on the Site, and is accessible to
            anyone with whom you share your username and password. For this
            reason, please safeguard your username and password carefully and do
            not disclose them to any other person.
          </li>
          <li>
            VisibleRisk collects information through various other means,
            including searches online, participation at conferences and events,
            individual introductions, from VisibleRisk Customers regarding their
            employees, and from selected third-party business partners. From
            time to time, we may also acquire from third parties certain lists
            containing the names and contact information of individuals who may
            be interested in our products and services.
          </li>
        </ul>
        <h2>Use of Personal Data Collected</h2>
        <p className="fst-italic">
          We use Personal Data for legitimate business purposes, including the
          following:
        </p>
        <ul>
          <li>
            <b>Fulfillment of Requests.</b> VisibleRisk may use Personal Data
            about you to deliver requested products and services to you (and
            your employer, if it is a VisibleRisk Customer), and to provide you
            and our Customers with access to and information about ordered
            products and services. We will engage in these activities to manage
            our contractual relationship with you and/or to fulfil our
            contractual obligations.
          </li>
          <li>
            <b>Marketing Communications.</b> From time to time, we may use
            Personal Data about you to inform you of products, programs,
            services and promotions that we believe may be of interest to you.
            If your employer is a VisibleRisk Customer, we may also inform you
            of such products, programs, services and promotions using Personal
            Data about you that your employer provided to VisibleRisk. We may
            also use Personal Data of media contacts for press release
            distribution or sharing other newsworthy developments related to our
            business activities. If you would prefer that we not send electronic
            marketing messages to you, please see the "Your Choices" section
            below.
          </li>
          <li>
            <b>Other Business Purposes.</b> We may also use Personal Data about
            you for other business purposes, such as data analysis (for example,
            to improve the efficiency of our services), editorial and feedback
            purposes, providing customer support, customizing and improving the
            content and layout of the Site, completing business transactions
            (for example, to confirm content requested is received by
            Customers), development and delivery of marketing and promotion
            plans and materials, statistical analysis of user behavior (for
            example, to understand what parts of the Site are of most interest
            to users), product development, conducting surveys and market
            research, and administering individual accounts.
          </li>
          <li>
            <b>Administrative Communications.</b> VisibleRisk reserves the right
            to use Personal Data to send to you important information regarding
            the Site, products, services, your account status, the Terms of Use,
            this Privacy Policy, or any other policies, agreements or
            transactions relevant to your use of the Site, products and/or
            services. Because this information may be important to your use of
            the Site, products and/or services, you may not opt-out of receiving
            such communications. We will engage in this activity to manage our
            contractual relationship with you and/or to comply with a legal
            obligation.
            <b className="d-block my-3">
              Compliance with Law and Regulation
            </b>{" "}
            {/* FIXME: This is probably a spelling mistake */}
          </li>
          <li>
            <b>Legal Requirements.</b> We may disclose your personal information
            as is in our legitimate interests when such disclosure is necessary
            or advisable, in our sole discretion, to conduct an investigation,
            respond to a third party or law enforcement subpoena or court order,
            bring legal action, prevent harm to others or pursue other relief
            when you or a third party are or may be: violating our terms and
            conditions of use; causing injury or other harm to, or otherwise
            violating our property or other legal rights, or those of other
            users of our Sites and Services or third parties; or violating
            federal, state, local, or other applicable law.
          </li>
          <li>
            Where required by applicable law, we will only use Personal Data
            with your consent.
          </li>
        </ul>
        <h2>Collection and Use of Other Data</h2>
        <p className="fst-italic">
          We and our service providers may also collect and use the following
          types of data, referred to in this Privacy Policy as "Other Data":
        </p>
        <ul>
          <li>
            Monitoring Data. VisibleRisk and its service providers may collect
            and/or track other information such as demographic information,
            domain names, computer type, browser types, screen resolution, and
            other statistical data involving the use of the Site ("Monitoring
            Data"). We use Monitoring Data to help us understand who uses the
            Site and to improve and market it, as well as improve our services
            generally. Unless combined with Personal Data, Monitoring Data does
            not personally identify you or any other user, and we may use it for
            any purpose.
          </li>
          <li>
            Aggregated Data. We may aggregate Personal Data in a manner such
            that the end-product does not personally identify you or any other
            user of the Site, for example, by using information to calculate the
            percentage of our users who have a particular telephone area code.
            Such aggregate information may also be used for any purpose.
          </li>
          <li>
            Cookies. To enhance the Internet experience on the Site, VisibleRisk
            and our service providers may use ‘cookies’ on the Site. Cookies are
            data that a web server transfers to an individual’s computer for
            recordkeeping and other purposes. We use cookies and other
            technologies to facilitate users’ ongoing access to and use of our
            Site. If you do not want information collected through the use of
            cookies, there is a simple procedure in most browsers that allows
            you to automatically decline cookies, or to be given the choice of
            declining or accepting the transfer of a particular cookie, or
            cookies from a particular web site, to your computer. If cookies are
            disabled, however, all features of the Site may not operate as
            intended. Information about disabling cookies can be found on your
            Internet browser provider’s web site.
            <div>
              For more information regarding our use of Cookies, please see our
              Cookie Notice.
            </div>
          </li>
          <li>
            Tools.
            <ul>
              <li>
                We and our service providers may also use various common
                Internet tools such as ‘pixel tags,’ ‘action tags,’ ‘web
                beacons,’ ‘.gif tags,’ ‘JavaScript’ or similar technologies
                (together, "Tools") in connection with Site pages and email
                messages in certain formats to, among other things, track the
                actions of Site users and email recipients, to determine the
                success of marketing campaigns and to compile statistics about
                Site usage and response rates. Tools allow us to count users who
                have visited certain pages of the Site, to deliver services, and
                to help determine the effectiveness of promotional or
                advertising campaigns. When used in email messages in certain
                formats, Tools can tell the sender whether and when the email
                has been opened.
              </li>
              <li>
                VisibleRisk uses cookies and Tools to understand how the Site is
                used and to customize and enhance the Internet experience of
                individual users. When you revisit the Site, VisibleRisk may
                recognize you by a Tool and customize your experience. For
                example, if you have an account on the Site, a cookie and/or
                Tool may be used to save your credentials and avoid having you
                sign in again. We believe cookies and Tools add value to the
                user experience.
              </li>
              <li>
                We may use Google Analytics, which uses cookies and other
                similar technologies, to collect and analyze information about
                Site use and to report on activities and trends. The service may
                also collect information regarding the use of other websites,
                apps and online resources. You can learn about Google’s
                practices by going to{" "}
                <ExternalLink linkAddress="https://policies.google.com/privacy/partners" />
                , and opt out of them by downloading the Google Analytics
                opt-out browser add-on, available at{" "}
                <ExternalLink linkAddress="https://tools.google.com/dlpage/gaoptout" />.
              </li>
              <li>
                We may also use Pendo and/or Sentry to optimize our users’
                experiences. These are developer tools that collect and analyze
                information about activity on the Site to troubleshoot issues
                and improve user experience. For more information, please see
                Pendo and Sentry’s privacy policies at{" "}
                <ExternalLink linkAddress="https://www.pendo.io/legal/privacy-policy/" />{" "}
                and <ExternalLink linkAddress="https://sentry.io/privacy/" />.
              </li>
              <li>
                We do not respond to browser do-not-track signals at this time.
              </li>
            </ul>
          </li>
          <li>
            Verification. We may use third-party services to verify that you are
            a human user (often known as "captchas"). Any information collected
            as part of such verification is subject to the privacy policy of the
            third-party service provider.
          </li>
          <li>
            IP Addresses. When you visit and interact with the Site, we and our
            third-party service providers may collect Internet Protocol (IP)
            addresses. Your IP address is a number that is automatically
            assigned to the computer that you are using by your Internet Service
            Provider (ISP). This number is identified and logged automatically
            in our server log files whenever you visit the Site, along with the
            time(s) of your visit(s) and the page(s) that you visited. We use IP
            addresses to understand how the Site is used by our users, to
            improve the Site and to enhance user experience of the Site. We may
            also derive your approximate location from your IP address.
          </li>
        </ul>
        <p>
          If we are required to treat Other Data as Personal Data under
          applicable law, then we may use and disclose it for the purposes
          described above, as well as for the purposes for which we use and
          disclose Personal Data as detailed in this Privacy Policy.
        </p>
        <h2>Disclosure of Personal Data Collected</h2>
        <p className="fst-italic">
          We may disclose Personal Data for the following purposes:
        </p>
        <ul>
          <li>
            Third-Party Service Providers. We may provide Personal Data to
            third-party service providers, who provide services to us, including
            but not limited to, data analysis, order fulfillment, payment
            processing, and other services.
          </li>
          <li>
            Affiliate Communications. VisibleRisk may transfer Personal Data
            among our affiliates to allow our affiliates to contact you
            regarding products, programs, services and promotions that they
            believe may be of interest to you, and such affiliates may otherwise
            use your Personal Data in accordance with their own posted privacy
            policy. If you would prefer not to receive marketing communications
            from VisibleRisk or our affiliates, please see the{" "}
            <i>"Your Choices"</i> section below. Cyber Assessments, Inc. will be
            the party responsible for jointly used Personal Data.
          </li>
          <li>
            Strategic Partners and Co-Branded Sites. From time to time, we may
            enter into a special relationship with another company that is not
            owned by or affiliated with VisibleRisk to provide or promote joint
            products, services, applications, or features (together, "Jointly
            Offered Items"). These special relationships may include co-branded
            web sites ("co-branded pages"). Any information, including Personal
            Data, that you provide in connection with one of these Jointly
            Offered Items, and/or on one of these co-branded pages, will be
            shared with our third-party partners. You should check these third
            parties’ web sites for information regarding their privacy
            practices. PLEASE NOTE THAT THIS PRIVACY STATEMENT DOES NOT ADDRESS
            THE PRIVACY OR INFORMATION PRACTICES OF ANY THIRD PARTIES. If you do
            not want Personal Data about you shared with such third parties,
            please do not provide Personal Data in connection with the Jointly
            Offered Items and/or co-branded pages.
          </li>
          <li>
            Third-Party Data Providers. If you subscribe to a VisibleRisk
            product or service that contains third-party data, we may be
            required to provide your Personal Data to the third party as part of
            our reporting obligations. We will only do so if a third-party data
            provider conditions your receipt of their data on knowing your
            identity.
          </li>
          <li>
            Conferences and Events. When you attend a conference or event
            organized by VisibleRisk, your name and contact information may be
            shared with other attendees and co-hosts of the conference or event.
          </li>
          <li>
            Promotions. We may share the Personal Data you provide in connection
            with Promotions with third-party sponsors of such Promotions
            (irrespective of whether such Promotions are hosted by us), or
            otherwise in accordance with the rules applicable to those
            Promotions.
          </li>
          <li>
            Assignment. We may disclose or transfer any and all Personal Data
            that we collect to an affiliated entity or a third party (and their
            respective advisors) in the event of any reorganization, merger,
            sale, joint venture, assignment, transfer or other disposition of
            all or any portion of our business, assets or stock (including
            without limitation in connection with any bankruptcy or similar
            proceedings).
          </li>
          <li>
            Law Enforcement; Emergencies; Compliance. We may use and disclose
            Personal Data about you to others as we believe to be appropriate:
            (a) in compliance with laws, rules, or regulations in any country in
            which VisibleRisk operates, including laws outside your country of
            residence; (b) to comply with legal process; (c) to respond to
            requests from government or public authorities, including government
            and public authorities outside your country of residence; (d) to
            enforce our Terms of Use; (e) to protect our operations or those of
            any affiliated entities; (f) to protect the rights, privacy, safety
            or property of VisibleRisk, its affiliated entities, you, or others;
            and (g) to permit us to pursue available remedies or limit the
            damages that we may sustain. For example, we may, to the fullest
            extent the law allows, disclose Personal Data about you to law
            enforcement agencies to assist them in identifying individuals who
            have been or may be engaged in unlawful activities.
          </li>
        </ul>
        <p>
          If you would like to receive more information on the third parties
          with whom we share your Personal Data, please contact us by using the
          information provided in the "Contact Information for VisibleRisk"
          section below.
        </p>
        <h2>Your Choices</h2>
        <ul>
          <li>
            If you no longer want to receive marketing-related emails from us on
            a going-forward basis, you may stop receiving these
            marketing-related emails by sending an e-mail to{" "}
            <ExternalLink mailto linkAddress="unsubscribe@visiblerisk.com" />.
          </li>
          <li>
            If you would prefer that we do not share your Personal Data on a
            going-forward basis with our affiliates or with unaffiliated third
            parties for their marketing purposes, you may cease this sharing by
            sending an e-mail to{" "}
            <ExternalLink linkAddress="unsubscribe@visiblerisk.com" mailto />.
          </li>
        </ul>
        <p>
          We will comply with your request(s) as soon as reasonably practicable
          and in accordance with applicable law. Please note that requesting us
          not to share your Personal Data with affiliates or unaffiliated third
          parties may result in you no longer receiving any marketing emails
          from VisibleRisk. Please also note that if you choose not to receive
          marketing-related messages from us, we may still send you important
          administrative messages, and you cannot elect to stop receiving such
          administrative messages, unless you choose to stop receiving services
          from us.
        </p>
        <p>
          You may also indicate your choices regarding marketing-related emails
          by contacting us via postal mail or email using the information in the{" "}
          <i>"Contact Information for VisibleRisk"</i> section below.
        </p>
        <h2>How you can access, change or suppress your Personal Data</h2>
        <p>
          If you would like to request to review, correct, update, suppress,
          delete or otherwise limit our use of your Personal Data that has been
          previously provided to us, or if you would like to request to receive
          an electronic copy of your Personal Data for purposes of transmitting
          it to another company (to the extent this right to data portability is
          provided to you by applicable law), you may make a request by
          contacting us using the information provided in the{" "}
          <i>"Contact Information for VisibleRisk"</i> section below. We will
          respond to your request consistent with applicable law. For your
          protection, we may only implement requests with respect to the
          Personal Data associated with the particular email address that you
          use to send us your request, and we may need to verify your identity
          before implementing your request. We will try to comply with your
          request as soon as reasonably practicable and consistent with
          applicable law. Please note that we may need to retain certain
          information for recordkeeping purposes and/or to complete any
          transactions that you began prior to requesting the change or
          deletion.
        </p>
        <h2>ExternalLinks</h2>
        <p>
          The Site may contain links to other Internet web sites, including
          social media sites and third-party hosted collaboration tools. These
          linked sites are not under VisibleRisk control. We provide links only
          as a convenience, and VisibleRisk does not endorse or control, and is
          not responsible for, the privacy practices or the content of these
          linked sites. If you provide any Personal Data through any third-party
          web site, or choose to communicate with us using third-party
          collaboration tools or other social media platforms, your transaction
          will occur on that third party’s web site (not the Site) and the
          Personal Data you provide will be collected by, and controlled by the
          privacy policy of, that third party. We recommend that you familiarize
          yourself with the privacy policies and practices of any such third
          parties. PLEASE NOTE THAT THIS PRIVACY POLICY DOES NOT ADDRESS THE
          PRIVACY OR INFORMATION PRACTICES OF ANY THIRD PARTIES, INCLUDING,
          WITHOUT LIMITATION, AFFILIATED ENTITIES THAT DO NOT POST OR LINK
          DIRECTLY TO THIS PRIVACY POLICY.
        </p>
        <h2>Security Measures</h2>
        <p>
          VisibleRisk has established reasonable organizational and technical
          measures to protect Personal Data within our organization from loss,
          misuse, or unauthorized access, disclosure, alteration or destruction.
          Please note that while VisibleRisk has endeavored to create a secure
          and reliable Site for users, no site is 100% secure. If you have
          reason to believe that your interaction with us is no longer secure
          (for example, if you feel that the security of any account you might
          have with us has been compromised), please notify us of the problem
          immediately by contacting us using the information provided below in{" "}
          <i>"Contact Information for VisibleRisk"</i> (note that if you choose
          to notify us via physical mail, this will delay the time it takes for
          us to respond to the problem).
        </p>
        <h2>Data Integrity</h2>
        <p>
          VisibleRisk will use Personal Data only in ways that are compatible
          with the purposes for which it was collected, authorized by this
          Privacy Policy, or authorized by you. VisibleRisk will take reasonable
          steps to ensure that Personal Data is relevant to its intended use,
          and is accurate, complete, and current (as provided by you).
          VisibleRisk depends on you to update or correct your Personal Data
          whenever necessary.
        </p>
        <h2>Retention Period</h2>
        <p>
          We will retain Personal Data about you for as long as needed or
          permitted in light of the purpose(s) for which it was obtained and
          consistent with applicable law. The criteria used to determine our
          retention periods include: (i) the length of time we have an ongoing
          relationship with you; (ii) whether there is a legal obligation to
          which we are subject; and (iii) whether retention is advisable in
          light of our legal position (such as in regard to applicable statutes
          of limitations, litigation or regulatory investigations).
        </p>
        <h2>Cross Border Transfer of Personal Data</h2>
        <p>
          Your Personal Data may be stored and processed in any country where we
          have facilities or in which we engage service providers. In certain
          circumstances, courts, law enforcement agencies, regulatory agencies,
          or security authorities in those other countries may be entitled to
          access your Personal Data.
        </p>
        <p>
          If you are located in the European Economic Area ("EEA"): Some of the
          non-EEA countries are recognized by the European Commission as
          providing an adequate level of data protection according to EEA
          standards (the full list of these countries is available on the EU
          Commission’s adequacy list online at:{" "}
          <ExternalLink linkAddress="https://ec.europa.eu/info/law/law-topic/data-protection/data-transfers-outside-eu/adequacy-protection-personal-data-non-eu-countries_en" />
          ). For transfers from the EEA to countries not considered adequate by
          the European Commission, we have put in place adequate measures, such
          as standard contractual clauses adopted by the European Commission, to
          protect your Personal Data. You may obtain a copy of these measures
          at:{" "}
          <ExternalLink linkAddress="https://ec.europa.eu/info/law/law-topic/data-protection/international-dimension-data-protection/standard-contractual-clauses-scc_en" />
          .
        </p>
        <p>
          If you are located in other countries, we will also obtain appropriate
          legal grounds to perform cross-border transfers of your Personal Data.
        </p>
        <h2>Use of this Site by Minors</h2>
        <p>
          This Site is not directed to individuals under the age of eighteen
          (18), and we request that these individuals do not provide Personal
          Data through this Site.
        </p>
        <h2>Sensitive Personal Data Restriction</h2>
        <p>
          Unless we specifically request for information to comply with
          applicable laws, we ask that you not send us, and you not disclose,
          any sensitive Personal Data (e.g., social security numbers, passport
          numbers, information related to racial or ethnic origin, political
          opinions, religion or other beliefs, health, biometrics or genetic
          characteristics, criminal background, or trade union membership) on or
          through this Site or otherwise to us.
        </p>
        <h2>Contact Information for VisibleRisk</h2>
        <p>
          Questions or concerns regarding VisibleRisk data protection practices
          should be addressed to:
        </p>
        <p>Cyber Assessments, Inc. d/b/a VisibleRisk</p>
        <p>488 Madison Avenue – Suite 1103</p>
        <p>New York, NY 10022</p>
        <p>Attn: General Counsel</p>
        <p>E-mail: privacy@visiblerisk.com</p>
        <p>
          If you choose to contact VisibleRisk via e-mail about this Privacy
          Policy, please mark the subject heading of your e-mail "Privacy
          Inquiry."
        </p>
        <h2>Additional Information for the EEA</h2>
        <p>
          You may lodge a complaint with the data protection authority for your
          country or region or where an alleged infringement of applicable data
          protection law occurs. However, we hope we can solve any queries or
          concerns you may have, so please contact us directly in the first
          instance.
        </p>
        <h2>APEC</h2>
        <p>
          Our privacy practices, described in this Privacy Policy, comply with
          the APEC Cross Border Privacy Rules (CBPR) System and APEC Privacy
          Recognition for Processors (PRP) System. The APEC CBPR and PRP systems
          provides a framework for organizations to ensure protection of
          personal information transferred among participating APEC economies.
          More information about the APEC framework can be found{" "}
          <ExternalLink linkAddress="http://cbprs.org/" txt="here" />.
        </p>
        <section className="d-flex align-items-center gap-4 mt-5">
          <div>
            <a
              href="//privacy.truste.com/privacy-seal/validation?rid=beb62d6a-152f-4075-a3c9-8c02bac4ded1"
              target="_blank"
              rel="noreferrer"
            >
              <img
                style={{ border: "none" }}
                src="//privacy-policy.truste.com/privacy-seal/seal?rid=beb62d6a-152f-4075-a3c9-8c02bac4ded1"
                alt="TRUSTe"
              />
            </a>
          </div>
          <div>
            <a
              href="//privacy.truste.com/privacy-seal/validation?rid=df12acbc-c732-4038-a8ea-844199c69951"
              target="_blank"
              rel="noreferrer"
            >
              <img
                style={{ border: "none" }}
                src="//privacy-policy.truste.com/privacy-seal/seal?rid=df12acbc-c732-4038-a8ea-844199c69951"
                alt="TRUSTe"
              />
            </a>
          </div>
        </section>
      </PrivacyContentContainerStyled>
    );
}

export default PrivacyContent;
