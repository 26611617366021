import { FC } from "react";

import { Loading } from "visible-ui";

import { useSurveySubmittedOrEnded } from "../../hooks/use-survey-submitted-or-ended";
import SurveyResponseForm from "./SurveyResponseForm";
import SurveySubmittedOrEndedScreen from "./SurveySubmittedOrEndedScreen";

const SurveyPage: FC = () => {
  const { loading, submitted, ended } = useSurveySubmittedOrEnded();

  if (loading) {
    return (
      <div className="center-container">
        <Loading variant="spinner" />
      </div>
    );
  }

  if (ended) {
    return <SurveySubmittedOrEndedScreen mode="ended" />;
  }

  if (submitted) {
    return <SurveySubmittedOrEndedScreen mode="submitted" />;
  }

  return <SurveyResponseForm />;
};

export default SurveyPage;
