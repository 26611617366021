import BaseRequest from "../requests/base-request";
import { AxiosResponse } from "axios";
import { Credentials, TokensResponse } from "../../model/auth";

export default class LoginRequest extends BaseRequest<
  TokensResponse,
  TokensResponse
> {
  constructor(credentials: Credentials) {
    super("POST", `/login/`, { payload: credentials });
  }

  processResponse(response: AxiosResponse<TokensResponse>): TokensResponse {
    const result = response.data;
    return result;
  }
}
