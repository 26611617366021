import dayjs from "dayjs";
export type DateIosString = Date | string;

export const UtcTime: (date: DateIosString) => Date = date => {
  const utcTime = new Date(date.toLocaleString("en-US", { timeZone: "UTC" }));
  return new Date(
    Date.UTC(
      utcTime.getFullYear(),
      utcTime.getMonth(),
      utcTime.getDate(),
      utcTime.getHours(),
      utcTime.getMinutes(),
      utcTime.getSeconds()
    )
  );
};

export const addZoneSuffixToTime = (date: DateIosString) =>
  date.toString().toLowerCase().includes("z") ? date : `${date}Z`;

export enum TableDateFormatType {
  Normal,
  Short,
  WithTime,
}

export const tableDateFormat = (
  date?: DateIosString,
  formatType = TableDateFormatType.Normal
): string => {
  let format = "";
  switch (formatType) {
    case TableDateFormatType.Normal:
      format = "MMM DD, YYYY";
      break;
    case TableDateFormatType.Short:
      format = "MMM DD, ‘YY";
      break;
    case TableDateFormatType.WithTime:
      format = "MMM DD, YYYY H:mm A";
      break;
  }
  return date
    ? TableDateFormatType.WithTime &&
      dayjs().diff(UtcTime(date), "seconds") < 44
      ? "Just now"
      : dayjs(UtcTime(date)).format(format)
    : "-";
};

export const isoDate: (timestamp?: number) => string = (
  timestamp = Date.now()
) => {
  return new Date(timestamp).toISOString();
};

export const SECOND = 1000,
  MIN = SECOND * 60,
  HOUR = MIN * 60,
  DAY = HOUR * 24,
  WEEK = DAY * 7,
  MONTH = DAY * 30,
  YEAR = DAY * 365;

enum TimeUnit {
  DAY,
  WEEK,
  MONTH,
  YEAR,
}

const timeUnits: Record<
  TimeUnit,
  { ms: number; unitsName: string; unitName: string }
> = {
  [TimeUnit.DAY]: { ms: DAY, unitsName: "days", unitName: "daily" },
  [TimeUnit.WEEK]: { ms: WEEK, unitsName: "weeks", unitName: "weekly" },
  [TimeUnit.MONTH]: { ms: MONTH, unitsName: "months", unitName: "monthly" },
  [TimeUnit.YEAR]: { ms: YEAR, unitsName: "years", unitName: "annual" },
};

const _round = (timeMS: number, timeUnit: TimeUnit): string => {
  const { ms, unitName, unitsName } = timeUnits[timeUnit];
  const roundTimeUnits = Math.round(timeMS / ms);
  if (roundTimeUnits > 1) {
    return `every ${roundTimeUnits} ${unitsName}`;
  } else {
    return unitName;
  }
};

export const durationHumanize = (
  time: number,
  at: "seconds" | "milliseconds" = "seconds"
): string => {
  const ms = at === "milliseconds" ? time : time * 1000;
  if (ms < WEEK) {
    return _round(ms, TimeUnit.DAY);
  } else if (ms < MONTH) {
    return _round(ms, TimeUnit.WEEK);
  } else if (ms < YEAR) {
    return _round(ms, TimeUnit.MONTH);
  } else {
    return _round(ms, TimeUnit.YEAR);
  }
};

export const timeFormatDaysHoursMins = (time: number) => {
  const ms = time * 1000;
  const days = Math.floor(ms / DAY);
  const hours = Math.floor(ms / HOUR);
  const minutes = Math.floor(ms / MIN);
  return `${days}d, ${hours - days * 24}h, ${minutes - hours * 60}m`;
};
