import { AxiosResponse } from "axios";
import { User } from "../../model/auth";
import BaseAuthorizedRequest from "../requests/base-authorized-request";

export default class GetUserInfoRequest extends BaseAuthorizedRequest<
  User,
  User
> {
  constructor() {
    super("GET", `/my-info`);
  }

  processResponse(response: AxiosResponse<User>): User {
    const result = response.data;
    return result;
  }
}
