import { FC } from "react";
import { Form } from "react-bootstrap";
import styled from "styled-components";
import BaseComponentProps from "../../../base-component-props";

// forcing margin-left to 0 fixes the radio button alignment for RTL languages
const FormCheckStyled = styled(Form.Check)<{
  $readonly: boolean;
  $disabled: boolean;
}>`
  cursor: ${({ $readonly, $disabled }) =>
    $disabled ? "not-allowed" : $readonly ? "initial" : "pointer"};
  .form-check-input {
    &[type="checkbox"] {
      border-radius: 0.215rem;
    }
    &,
    &:focus {
      &:not(:checked) {
        background-color: #fff;
        border: 1.5px solid var(--grey-6);
      }
    }
  }
  .form-check-input,
  .form-check-label {
    cursor: inherit;
    margin-left: 0 !important;
  }
`;

export type FormCheckInputType = "radio" | "checkbox";

export interface MultipleChoiceViewOnlyOptionProps {
  answers: Array<string>;
  type?: FormCheckInputType;
}
export interface MultipleChoiceBaseOptionProps extends BaseComponentProps {
  id?: string;
  groupName?: string;
  defaultChecked?: boolean;
  checked?: boolean;
  readOnly?: boolean;
  disabled?: boolean;
  onChange?: (e: React.ChangeEvent<HTMLInputElement>) => void;
  checkClassName?: string;
  type?: FormCheckInputType;
  gap?: number;
}

export const MultipleChoiceBaseOption: FC<MultipleChoiceBaseOptionProps> = ({
  id,
  groupName,
  defaultChecked,
  checked,
  readOnly,
  disabled,
  gap = 3,
  onChange,
  className = "",
  style,
  checkClassName = "",
  type = "radio",
  children,
}) => {
  return (
    <FormCheckStyled
      $readonly={!!readOnly}
      className={`d-flex align-items-center gap-${gap} form-check ${className}`}
      style={style}
      $disabled={disabled}
    >
      <Form.Check.Input
        id={id}
        name={groupName}
        defaultChecked={defaultChecked}
        checked={checked}
        readOnly={!!readOnly}
        disabled={disabled}
        type={type}
        onChange={onChange}
        className={`mt-0 ${checkClassName}`}
      />
      <Form.Check.Label className="w-100" htmlFor={id}>
        {children}
      </Form.Check.Label>
    </FormCheckStyled>
  );
};

export default MultipleChoiceBaseOption;
