import { AxiosResponse } from "axios";
import BaseAuthorizedRequest from "../requests/base-authorized-request";
import {
  GenericResponse,
  ShareResourcesRequest as ShareResourcesRequestModel,
} from "../../model";

export default class ShareResourcesRequest extends BaseAuthorizedRequest<
  string[],
  GenericResponse
> {
  constructor(shareResourcesRequest: ShareResourcesRequestModel) {
    super("PUT", `/share-resources/`, {
      payload: shareResourcesRequest,
    });
  }

  processResponse(response: AxiosResponse<string[] | null>): GenericResponse {
    const userIds = response.data;
    return userIds
      ? {
          _id: userIds[0],
          success: true,
        }
      : {
          success: false,
          error_code: 403,
          error_message: "Failed to share resources",
        };
  }
}
