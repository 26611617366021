import {
  BaseClient,
  GenericResponse,
} from "visible-ui";
import { SurveyUser } from "../../model/user";
import GetMyInfoRequest from "../me/get-my-info";
import UpdateMyInfoRequest from "../me/update-my-info";

export default class MeClient extends BaseClient {
  protected static instance: MeClient;

  protected constructor() {
    super("/api/v1/survey/me");
  }

  public static get Instance() {
    return this.instance || (this.instance = new MeClient());
  }

  public async getMyInfo(accessToken: string): Promise<SurveyUser | null> {
    return this.doRequest(new GetMyInfoRequest(accessToken));
  }

  public async updateMyInfo(
    accessToken: string,
    myInfoToUpdate: SurveyUser
  ): Promise<GenericResponse | null> {
    return this.doRequest(new UpdateMyInfoRequest(accessToken, myInfoToUpdate));
  }
}
