import { FC } from "react";
import { Modal, ModalProps } from "react-bootstrap";
import { X } from "react-feather";
import styled from "styled-components";

const ModalStyled = styled(Modal)`
  .modal-dialog-centered {
    max-width: 100%;
  }
  .modal-content {
    overflow: hidden;
    width: auto;
    margin: 0 auto;
  }
  .x-icon {
    position: absolute;
    width: 18px;
    top: 24px;
    right: 24px;
    color: var(--grey-7);
    cursor: pointer;
    transition: opacity .3s;
    &:hover {
      opacity .7;
    }
  }
`;

interface ModalWithXProps extends ModalProps {
  show: boolean;
  id: string;
  onHide: () => void;
  backdropClassName?: string;
  className?: string;
}

const ModalWithX: FC<ModalWithXProps> = ({
  show = true,
  children,
  onHide,
  ...props
}) => {
  return (
    <ModalStyled centered show={show} onHide={onHide} {...props}>
      <X className="x-icon z-high" onClick={onHide} />
      {children}
    </ModalStyled>
  );
};

export default ModalWithX;
