import { useEffect } from "react";
import { Navigate, Route, Routes } from "react-router-dom";
import { useRecoilValue } from "recoil";
import dayjs from "dayjs";
import { useTranslation } from "react-i18next";

import { ErrorBoundary, VisibleToastContainer } from "visible-ui";

import { magicRoutes, wildCardRoute } from "./routing/routes";
import MagicLinkLayout from "./components/layout/MagicLinkLayout";
import ParticipantAgreementPage from "./components/privacy-policy/ParticipantAgreementPage";
import { myInfoAtom } from "./state/atoms/me-atoms";
import { LocaleCodes } from "./model/locale";
import ErrorPage from "./components/pages/ErrorPage";
import { useAuth } from "./hooks/auth-hooks";

function App() {
  const myInfo = useRecoilValue(myInfoAtom);
  const { logout } = useAuth();
  const { i18n } = useTranslation();
  document.body.dir = i18n.dir()


  useEffect(() => {
    if (myInfo?.locale) {
      switch (myInfo.locale) {
        case LocaleCodes.es:
          require("dayjs/locale/es");
          break;
        case LocaleCodes.ar:
          require("dayjs/locale/ar");
          break;
        case LocaleCodes.fr:
          require("dayjs/locale/fr");
          break;
        case LocaleCodes.ko:
          require("dayjs/locale/ko");
          break;
        case LocaleCodes.ja:
          require("dayjs/locale/ja");
          break;
        case LocaleCodes.pt:
          require("dayjs/locale/pt");
          break;
        case LocaleCodes.zh:
          require("dayjs/locale/zh");
          break;
      }
      i18n.changeLanguage(myInfo.locale);
      dayjs.locale(myInfo.locale);
    }
  }, [myInfo?.locale]);
  return (
    <ErrorBoundary
      logoutCallback={logout}
      errorPage={<ErrorPage className="vh-100" />}
    >
      <Routes>
        <Route element={<MagicLinkLayout />}>{magicRoutes}</Route>
        <Route path="agreement" element={<ParticipantAgreementPage />} />
        {/* redirect to agreement page if user navigates to privacy page */}
        <Route path="privacy" element={<Navigate to="/agreement" />} />
        {wildCardRoute}
      </Routes>
      <VisibleToastContainer />
    </ErrorBoundary>
  );
}

export default App;
