import { FC, useState, ReactNode, useEffect } from "react";
import ModalExpended from "./ModalExpended";
import BaseComponentProps from "../../../base-component-props";
import { useNavigate, useLocation } from "react-router-dom";
import { useRef } from "react";

export interface ExpandingElementProps extends BaseComponentProps {
  expendedElement: ReactNode;
  activeModalCounter?: number;
  disabled?: boolean;
  hashName?: string;
}

const ExpandingElement: FC<ExpandingElementProps> = ({
  className,
  expendedElement,
  activeModalCounter = 0,
  children,
  disabled = false,
  hashName,
}) => {
  const [expended, setExpended] = useState(false);
  const [time, setTime] = useState(800);

  const handleKeyup = (e: KeyboardEvent) => {
    e.code === "Escape" && onSetExpended(false);
  };

  const navigate = useNavigate();
  const { hash } = useLocation();

  const ref = useRef<HTMLDivElement | null>(null);

  const onSetExpended = (_expended: boolean) => {
    hashName
      ? navigate({ hash: _expended ? hashName : "" })
      : setExpended(_expended);
  };

  useEffect(() => {
    if (hashName) {
      if (hash.includes(hashName)) {
        ref.current && ref.current.scrollIntoView();
        setTimeout(() => {
          setExpended(true);
          setTime(0);
        }, time);
      } else {
        setTime(0);
        setExpended(false);
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [hash, setExpended]);

  useEffect(() => {
    setTimeout(() => {
      if (expended && activeModalCounter === 0) {
        document.body.classList.add("overflow-hidden");
        window.addEventListener("keyup", handleKeyup);
      } else {
        window.removeEventListener("keyup", handleKeyup);
      }
    }, 100);
    return () => {
      window.removeEventListener("keyup", handleKeyup);
      document.body.classList.remove("overflow-hidden");
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [expended, activeModalCounter]);

  return (
    <section
      ref={ref}
      className={`position-relative ${className}`}
      onClick={() => !disabled && onSetExpended(true)}
    >
      <ModalExpended close={() => onSetExpended(false)} expended={expended}>
        {expended && expendedElement}
      </ModalExpended>
      {children}
    </section>
  );
};

export default ExpandingElement;
