import { ProgressBar, Row, Col } from "react-bootstrap";
import styled from "styled-components";
import AccordionListGroup from "../base/accordion-list-group/AccordionListGroup";
import {
  CommonQuestionnaire,
  QuestionDependencyStatus,
  QuestionnaireResponseAnswer,
} from "../../model/questionnaire";
import {
  getQuestionDependencyStatus,
  getSectionPrefixIdx,
  isQuestionAnswered,
} from "../../utils/questionnaire";
import CheckedOrNumericCircle from "../base/numeric-circle/CheckedOrNumericCircle";
import { User, BaseUser } from "../../model/auth";

const IndexQuestionnaireBox = styled.div<{ titlesCount: number }>`
  max-height: ${({ titlesCount }) => `
  calc(var(--accordion-list-group-max-height) -
    ((var(--accordion-item-title-height)) *
    ${titlesCount}) -
    (var(--accordion-item-body-padding) * 2));
`};
  min-height: 80px;
`;

const AccordionListGroupWrap = styled.section<{
  maxHeightBottomMargin: number;
}>`
  top: 0;
  --accordion-list-group-max-height: calc(
    100vh - ${props => props.maxHeightBottomMargin}px
  );
  --accordion-item-title-height: 70px;
  --accordion-item-body-padding: 24px;
  overflow: hidden;
`;
export interface Props<T extends BaseUser> {
  questionnaire: CommonQuestionnaire<T>;
  refs: Record<string, React.RefObject<HTMLDivElement>>;
  hideTitle?: boolean;
  maxHeightBottomMargin?: number;
  answersMap: Map<string, QuestionnaireResponseAnswer>;
  activeKey: string;
}

const QuestionnaireResponseIndex: <T extends BaseUser = User>(
  p: Props<T>
) => React.ReactElement<Props<T>> = ({
  questionnaire,
  hideTitle,
  refs,
  maxHeightBottomMargin = 160,
  answersMap,
  activeKey,
}) => {
  const handleQuestionClick = (id: string) =>
    refs[id].current?.scrollIntoView({
      behavior: "smooth",
      block: "start",
    });

  const flatQuestions = questionnaire?.sections.map(_ => _.questions).flat();

  return (
    <AccordionListGroupWrap maxHeightBottomMargin={maxHeightBottomMargin}>
      <AccordionListGroup
        activeKey={activeKey}
        style={{
          maxHeight: "calc(var(--accordion-list-group-max-height) + 16px)", // 16px is accordion-list-group padding
        }}
        className="sticky-top"
        items={questionnaire.sections
          .filter(_ => _.questions.length)
          .map(({ section_number, name, questions, id }) => {
            // use findIndex for get real index after filter sections..
            const idx = questionnaire.sections.findIndex(_ => _.id === id);
            const prefixIdx = getSectionPrefixIdx(questionnaire.sections, idx);
            return {
              title: hideTitle
                ? undefined
                : `${String.fromCharCode(section_number + 65)}: ${name}`,
              header: (
                <ProgressBar
                  className="progress-sm"
                  now={
                    (questions.filter(q => answersMap.has(q.id)).length /
                      questions.length) *
                    100
                  }
                />
              ),
              body: (
                <IndexQuestionnaireBox
                  className="d-flex flex-column gap-3"
                  titlesCount={hideTitle ? 0 : questionnaire.sections.length}
                >
                  {questions.map((question, idx, { length }) => {
                    const answer = answersMap.get(question.id);

                    const { status: questionDependencyStatus } =
                      getQuestionDependencyStatus(
                        question,
                        flatQuestions,
                        answersMap
                      );

                    // checked is true:
                    // when question is answered and dependency status is not disabled
                    // or when dependency status is irrelevant
                    const checked =
                      (isQuestionAnswered(answer) &&
                        questionDependencyStatus !==
                          QuestionDependencyStatus.DISABLED) ||
                      questionDependencyStatus ===
                        QuestionDependencyStatus.IRRELEVANT;

                    return (
                      <Row
                        key={`questionnaire-index-item-${idx}`}
                        className={`cursor-pointer me-2 align-items-center ${
                          // add padding bottom to last item
                          idx === length - 1 ? "pb-3" : ""
                        }`}
                        onClick={() => handleQuestionClick(question.id)}
                      >
                        <Col md="auto">
                          <CheckedOrNumericCircle
                            checked={checked}
                            number={prefixIdx + question.question_number + 1}
                            size="sm"
                          />
                        </Col>
                        <Col
                          className={
                            "text-truncate p-0 fs-5 " +
                            (checked ? "text-gray-700" : "text-gray-900")
                          }
                          title={question.question}
                        >
                          {question.question}
                        </Col>
                      </Row>
                    );
                  })}
                </IndexQuestionnaireBox>
              ),
            };
          })}
      />
    </AccordionListGroupWrap>
  );
};

export default QuestionnaireResponseIndex;
