import { debounce } from "lodash";
import { FC, useEffect, useMemo, useRef, useState } from "react";
import { TextInput, InputType, MultipleChoiceBaseOption } from "visible-ui";
import { useTranslation } from "react-i18next";

interface SurveyCantAnswerQuestionProps {
  questionId: string;
  handleSubmitCantAnswerResponse: (newValue: string) => Promise<void>;
  reasonToNotAnswer?: string;
}

enum CantAnswer {
  NotKnow = "notKnow",
  DeclineToRespond = "declineRespond",
  NotUnderstandQuestion = "notUnderstandQuestion",
  NotApplicable = "noApplicable",
}

const SurveyCantAnswerQuestion: FC<SurveyCantAnswerQuestionProps> = ({
  questionId,
  handleSubmitCantAnswerResponse,
  reasonToNotAnswer,
}) => {
  const { t } = useTranslation();

  const _setChecked = (_reasonToNotAnswer: string) => {
    switch (_reasonToNotAnswer) {
      case CantAnswer.NotKnow:
      case CantAnswer.DeclineToRespond:
      case CantAnswer.NotUnderstandQuestion:
        setChecked(_reasonToNotAnswer);
        break;
      default: {
        if (_reasonToNotAnswer) {
          setChecked(CantAnswer.NotApplicable);
        }
      }
    }
  };

  const [checked, setChecked] = useState<CantAnswer | null>(null);
  const [value, setValue] = useState(reasonToNotAnswer);

  useEffect(() => {
    reasonToNotAnswer && _setChecked(reasonToNotAnswer);
  }, []);

  const debouncedSubmitCantAnswerResponse = useMemo(
    () => debounce(newValue => handleSubmitCantAnswerResponse(newValue), 400),
    [handleSubmitCantAnswerResponse]
  );
  const onCantAnswerChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { value } = e.currentTarget;
    setValue(value);
    debouncedSubmitCantAnswerResponse(value);
  };

  const notApplicableInputRef = useRef<HTMLInputElement | null>(null);

  return (
    <section className="d-flex flex-column gap-3 border-top pt-4 mt-4">
      {Object.values(CantAnswer).map(
        (cantAnswer: CantAnswer) =>
          cantAnswer !== CantAnswer.NotApplicable && (
            <MultipleChoiceBaseOption
              id={`${cantAnswer}-${questionId}`}
              key={`question-response-cant-answer-${cantAnswer}-${questionId}`}
              checked={checked === cantAnswer}
              groupName={`question-response-cant-answer-${questionId}`}
              onChange={async () => {
                await handleSubmitCantAnswerResponse(cantAnswer);
                _setChecked(cantAnswer);
              }}
              type={"radio"}
              checkClassName="me-1"
            >
              <section className="d-flex align-items-center gap-3">
                <span className={checked === cantAnswer ? "fw-bold" : ""}>
                  {t(`fillSurvey.${cantAnswer}`)}
                </span>
              </section>
            </MultipleChoiceBaseOption>
          )
      )}
      <section className="d-flex align-items0-center gap-3">
        <MultipleChoiceBaseOption
          id={`${CantAnswer.NotApplicable}-${questionId}`}
          key={`question-response-cant-answer-${CantAnswer.NotApplicable}-${questionId}`}
          checked={checked === CantAnswer.NotApplicable}
          groupName={`question-response-cant-answer-${questionId}`}
          onChange={() => {
            setChecked(CantAnswer.NotApplicable);
            // set Timeout for wait to enable input
            // We didn't use useEffect to not run this logic in onMounted
            // eslint-disable-next-line scanjs-rules/call_setTimeout
            setTimeout(() => {
              notApplicableInputRef.current?.focus();
              notApplicableInputRef.current?.select();
            }, 0);
          }}
          type={"radio"}
          checkClassName="me-1"
        >
          <section className="d-flex align-items-center gap-3">
            <span
              className={CantAnswer.NotApplicable === checked ? "fw-bold" : ""}
            >
              {t(`fillSurvey.${CantAnswer.NotApplicable}`)}
            </span>
          </section>
        </MultipleChoiceBaseOption>
        <TextInput
          ref={notApplicableInputRef}
          data-testid="cant-answer-input"
          type={InputType.Text}
          disabled={checked !== CantAnswer.NotApplicable}
          size="sm"
          style={{ width: "365px" }}
          formGroupWrapperClassName="mb-0"
          value={
            checked === CantAnswer.NotApplicable ? (value ? t(value) : "") : ""
          }
          onChange={onCantAnswerChange}
        />
      </section>
    </section>
  );
};

export default SurveyCantAnswerQuestion;
