import { FC } from "react";

const CloudCompleted: FC = () => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="19"
    height="13"
    viewBox="0 0 19 13"
    className="mb-1"
  >
    <g fill="none" fillRule="evenodd">
      <g>
        <path
          fill="#F9FAFC"
          d="M0 0H1440V739H0z"
          transform="translate(-847 -621)"
        />
        <g fillRule="nonzero">
          <path
            fill="#FFF"
            d="M0 0h1190v60H0V0z"
            transform="translate(-847 -621) translate(0 598) matrix(-1 0 0 1 1190 0)"
          />
          <g fill="#00D97E">
            <g>
              <g>
                <path
                  d="M10.263 0c2.583 0 4.712 1.94 5.002 4.461l.018.195.198.034c1.646.333 2.899 1.746 2.983 3.466l.005.186c0 2.05-1.66 3.715-3.718 3.725L4.02 12.07C1.8 12.066 0 10.269 0 8.053 0 6.457.942 5.043 2.357 4.4l.07-.03c.338-1.157 1.366-1.991 2.584-2.062l.167-.005c.258 0 .51.035.754.102l.033.01.067-.108C6.907.95 8.392.075 10.046.005zm1.265 4.244L8.204 7.569 6.63 5.997l-1.24 1.24 2.812 2.811 4.565-4.564-1.24-1.24z"
                  transform="translate(-847 -621) translate(0 598) translate(847.531 11) translate(0 9) translate(0 3)"
                />
              </g>
            </g>
          </g>
        </g>
      </g>
    </g>
  </svg>
);

export default CloudCompleted;
