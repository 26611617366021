import { Dropdown } from "react-bootstrap";
import { ChevronDown } from "react-feather";
import styled from "styled-components";

export const StatusStyled = styled.span`
  &:before {
    display: inline-block;
    margin-inline-end: 8px;
  }
`;

export const CollapseStyled = styled.section<{ open: boolean; count: number }>`
  transition: max-height 0.3s;
  max-height: ${p => (p.open ? 300 + p.count * 110 + "px" : 0)};
  height: 100%;
  overflow: ${p => (p.open ? "initial" : "hidden")};
`;

export const ChevronDownStyled = styled(ChevronDown)`
  color: var(--grey-7);
  transition: transform 0.5s;
  ${({ $open }: { $open: boolean }) => $open && "transform: rotateX(180deg);"}
`;

export const MaxWidthSpan = styled.span<{ maxWidth: string, width?: string }>`
  max-width: ${p => p.maxWidth};
  width: ${p => p.width};
`;

export const PlusStyled = styled.span`
  cursor: pointer;
  position: relative;
  width: 21px;
  height: 21px;
  display: block;
  border-radius: 50%;
  background-color: var(--grey-3);
  &:before {
    content: "+";
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
  }
`;

export const Plus2Styled = styled.span`
  cursor: pointer;
  position: relative;
  border-radius: 6px;
  width: 23px;
  height: 23px;
  display: block;
  background-color: var(--bs-primary);
  &:before {
    content: "+";
    font-size: 21px;
    color: #fff;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
  }
`;

export const ProgressBarLoading = styled.section`
  position: sticky;
  top: 0;
  left: 0;
  width: 100%;
  height: 5px;
  z-index: 2;
  background-color: var(--bs-purple);
  overflow: hidden;
  &:before,
  &:after {
    content: "";
    top: 0;
    position: absolute;
    width: 100%;
    height: 100%;
    background-color: var(--grey-5);
  }
  &:before {
    animation: progress-bar 2s ease-out infinite;
  }
  &:after {
    animation: progress-bar 2s 0.5s ease-out infinite;
  }
`;

// For dev needs
export const PreStyled = styled.pre`
  margin: 20px;
  padding: 20px;
  border-radius: 12px;
  background-color: #000000c0;
  color: #ffffffc0;
`;

export const DropdownMenuStyled = styled(Dropdown.Menu)<{
  $width?: string;
  $arrowX?: string;
}>`
  width: ${p => p.$width || "280px"};
  padding: 33px 24px 24px;
  &[x-placement*="top"] {
    &:after {
      top: 100%;
      border-top: 10px solid white;
      border-bottom: 10px solid transparent;
      animation: slide-in-up 0.3s linear, fade-in 0.5s linear;
    }
  }
  &[x-placement*="bottom"] {
    &:after {
      bottom: 100%;
      border-bottom: 10px solid white;
      border-top: 10px solid transparent;
      animation: slide-in-down 0.3s linear, fade-in 0.5s linear;
    }
  }
  &[x-placement*="start"] {
    &:after {
      right: ${p => p.$arrowX || "80%"};
    }
  }
  &[x-placement*="end"] {
    &:after {
      left: ${p => p.$arrowX || "80%"};
    }
  }
  &:after {
    content: "";
    position: absolute;
    filter: drop-shadow(rgba(0, 0, 0, 1) 0px 0px 0px);
    box-shadow: 0px 0px 0px 0px;
    border-left: 10px solid transparent;
    border-right: 10px solid transparent;
  }
`;

export const BottomPageStyled = styled.section`
  min-height: 92vh;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
`;
