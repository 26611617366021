export const DEFAULT_FORMAT_LOCALE = "en-US";

export class NumberParser {
  private _group: RegExp;
  private _decimal: RegExp;
  private _numeral: RegExp;
  private _index: (d: string) => string | number;

  constructor(locale: string) {
    const format = new Intl.NumberFormat(locale);
    const parts = format.formatToParts(12345.6);
    const numerals = Array.from({ length: 10 }).map((_, i) => format.format(i));
    const index = new Map(numerals.map((d, i) => [d, i]));
    this._group = new RegExp(
      `[${parts.find(d => d.type === "group")?.value}]`,
      "g"
    );
    this._decimal = new RegExp(
      `[${parts.find(d => d.type === "decimal")?.value}]`
    );
    this._numeral = new RegExp(`[${numerals.join("")}]`, "g");
    this._index = d => index.get(d)!;
  }

  parse(formattedNumber: string) {
    formattedNumber = formattedNumber
      .trim()
      .replace(this._group, "")
      .replace(this._decimal, ".")
      .replace(this._numeral, this._index as (d: string) => string);
    return formattedNumber ? +formattedNumber : NaN;
  }
}
