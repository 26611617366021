import BaseRequest from "../requests/base-request";
import { AxiosResponse } from "axios";
import { TokensResponse } from "../../model/auth";
import { AuthMethodEnum } from "../clients/auth-client";

export default class SubmitResetPasswordRequest extends BaseRequest<
  TokensResponse,
  TokensResponse
> {
  constructor(userIdentifier: string, otp: string, authMethod: AuthMethodEnum) {
    super("POST", `/submit-otp/`, {
      payload: {
        user_identifier: userIdentifier,
        otp,
        auth_method: authMethod,
      },
    });
  }

  processResponse(response: AxiosResponse<TokensResponse>): TokensResponse {
    const result = { ...response.data, success: response.status === 200 };
    return result;
  }
}
