import { Card } from "react-bootstrap";
import styled from "styled-components";
import { useInView } from "react-cool-inview";

import {
  QuestionnaireResponseAnswer,
  QuestionDependencyStatus,
  CommonQuestion,
  CommonQuestionnaireSection,
} from "../../model/questionnaire";
import PageTitle from "../typography/PageTitle";
import {
  getQuestionDependencyStatus,
  isQuestionAnswered,
} from "../../utils/questionnaire";
import EllipsisCard from "../base/ellipsis-card/EllipsisCard";
import { BaseUser, User } from "../../model/auth";

const CardStyled = styled(Card)<{
  $isQuestionAnswered: boolean;
  $disabled: boolean;
}>`
  position: relative;
  opacity: ${({ $disabled }) => ($disabled ? 0.4 : 1)};
  --question-answered: ${p => (p.$isQuestionAnswered ? "yes" : "no")};
  /* A pseudo element to cover the card to stop click events */
  &::after {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: ${({ $disabled }) => ($disabled ? 1 : -1)};
  }
  &:before {
    transition: height 0.3s;
    content: "";
    position: absolute;
    top: 0;
    /* Todo: fix at RTL direction */
    left: 0;
    height: ${({ $isQuestionAnswered }) =>
      $isQuestionAnswered ? "100%" : "0"};
    width: 5px;
    background-color: var(--bs-success);
    border-radius: 0.5rem 0 0 0.5rem;
  }
`;

interface Props<T extends BaseUser> {
  section: CommonQuestionnaireSection<T>;
  refs?: Record<string, React.RefObject<HTMLDivElement>>;
  hideTitle?: boolean;
  setActiveKey: React.Dispatch<React.SetStateAction<string>>;
  answersMap: Map<string, QuestionnaireResponseAnswer>;
  children: (
    question: CommonQuestion<T>,
    irrelevant?: boolean
  ) => React.ReactNode;
  flatQuestions: CommonQuestion<T>[];
  dependentOnTxt?: (num: number) => string;
}

const SharedQuestionnaireResponseSection: <T extends BaseUser = User>(
  p: Props<T>
) => React.ReactElement<Props<T>> = ({
  section,
  hideTitle,
  refs = {},
  setActiveKey,
  answersMap,
  children,
  flatQuestions,
  dependentOnTxt,
}) => {
  const { observe } = useInView<HTMLDivElement>({
    threshold: 0.1, // 10% needs to be visible in order to trigger
    onEnter: () => {
      // when element enters the screen set the eventKey
      setActiveKey(section.section_number.toString());
    },
  });
  const { total_questions, questions } = section;

  const getDependentOnTxt = (questionNumber: number) => {
    return dependentOnTxt
      ? dependentOnTxt(questionNumber)
      : `Dependent on ${questionNumber}`;
  };

  return (
    <div ref={observe} className="mb-6">
      {!hideTitle && (
        <PageTitle
          divider
          dividerClassName="pt-0"
          preTitle={{
            text: `section ${String.fromCharCode(section.section_number + 65)}`,
          }}
          title={{ text: section.name }}
        />
      )}
      {[...Array(total_questions).keys()].map((_, idx) => {
        const isPrevQuestion =
          !!idx &&
          !!questions.find(
            ({ question_number }) => question_number === idx - 1
          );
        const question = questions.find(
          ({ question_number }) => question_number === idx
        );
        const key = `questionnaire-response-question-${section.id}-${idx}`;

        const { status: questionDependencyStatus, questionDependentOn } =
          getQuestionDependencyStatus(question, flatQuestions, answersMap);

        if (question) {
          const disabled =
            questionDependencyStatus === QuestionDependencyStatus.DISABLED ||
            questionDependencyStatus === QuestionDependencyStatus.IRRELEVANT;
          const isQuestionAnsweredValue = isQuestionAnswered(
            answersMap.get(question.id)
          );
          const componentId = `question-card-${section.section_number}-${idx}-${
            disabled ? "disabled" : "enabled"
          }-${isQuestionAnsweredValue ? "answered" : "unanswered"}`;
          return (
            <CardStyled
              key={key}
              title={
                disabled && questionDependentOn
                  ? getDependentOnTxt(questionDependentOn.question_number + 1)
                  : undefined
              }
              $disabled={disabled}
              $isQuestionAnswered={
                (isQuestionAnsweredValue &&
                  questionDependencyStatus !==
                    QuestionDependencyStatus.DISABLED) ||
                questionDependencyStatus === QuestionDependencyStatus.IRRELEVANT
              }
              body
              data-testid={componentId}
              id={componentId}
              ref={refs[question.id]}
              className="scroll-with-topbar-offset"
            >
              {children(question, disabled)}
            </CardStyled>
          );
        } else {
          // Avoid duplicating the Ellipsis Card
          return isPrevQuestion && <EllipsisCard className="mb-4" key={key} />;
        }
      })}
    </div>
  );
};

export default SharedQuestionnaireResponseSection;
