import { AxiosResponse } from "axios";
import { BaseAuthorizedRequest, GenericResponse } from "visible-ui";

export default class SubmitSurveyRequest extends BaseAuthorizedRequest<
  GenericResponse,
  GenericResponse
> {
  constructor(accessToken: string) {
    super("PUT", `/submit`, undefined, accessToken);
  }

  processResponse(response: AxiosResponse<GenericResponse>): GenericResponse {
    let result = response.data;
    return result;
  }
}
