import { Dispatch, FC, SetStateAction } from "react";

import { GenericResponse, QuestionResponseType } from "visible-ui";

import FreeTextSurveyResponseQuestion from "../FreeTextSurveyResponseQuestion";
import {
  SurveyAnswer,
  SurveyQuestion,
  AnswerStatus,
} from "../../../model/survey";
import MultiChoiceSurveyQuestion from "../MultiChoiceSurveyQuestion";

interface SurveyResponseQuestionElementProps {
  question: SurveyQuestion;
  freeTextAnswer: string[] | undefined;
  onFreeTextChange: (newValue: string) => void;
  submitAnswer: (answer: SurveyAnswer) => Promise<GenericResponse | null>;
  handleSubmitQuestion: (
    handler: (answer: SurveyAnswer) => Promise<GenericResponse | null>,
    answer: Partial<SurveyAnswer>,
    selectedOptionsIds: Array<string | undefined>,
    newStatus: AnswerStatus
  ) => Promise<void>;
  disabled?: boolean;
  currentAnswer?: SurveyAnswer;
  checkedIds: (string | undefined)[];
  setCheckedIds: Dispatch<SetStateAction<(string | undefined)[]>>;
}

const SurveyResponseQuestionElement: FC<SurveyResponseQuestionElementProps> = ({
  question,
  freeTextAnswer,
  onFreeTextChange,
  submitAnswer,
  handleSubmitQuestion,
  disabled,
  currentAnswer,
  checkedIds,
  setCheckedIds,
}) => {
  const groupName = `question-response-answers-${question.id}`;

  switch (question.response_type) {
    case QuestionResponseType.MULTIPLE_CHOICES:
      return (
        <MultiChoiceSurveyQuestion
          question={question}
          currentAnswer={currentAnswer}
          checkedIds={checkedIds}
          type="radio"
          groupName={groupName}
          otherOptionOnChange={answerId => setCheckedIds([answerId])}
          otherOptionOnBlur={(answer, newValue) => {
            setCheckedIds([answer.id]);
            handleSubmitQuestion(
              submitAnswer,
              { ...answer, value: [newValue] },
              [answer.id],
              AnswerStatus.Answered
            );
          }}
          onChange={answer => {
            setCheckedIds([answer.id]);
            handleSubmitQuestion(
              submitAnswer,
              answer,
              [answer.id],
              AnswerStatus.Answered
            );
          }}
          disabled={disabled}
        />
      );
    case QuestionResponseType.FREE_TEXT:
    case QuestionResponseType.SHORT_ANSWER:
    case QuestionResponseType.SHORT_ANSWER_CURRENCY:
    case QuestionResponseType.PERCENTAGE:
      return (
        <FreeTextSurveyResponseQuestion
          question={question}
          answerValue={freeTextAnswer}
          onFreeTextChange={onFreeTextChange}
          onCurrencyChange={currencyCode => {
            !!freeTextAnswer?.length &&
              freeTextAnswer[0] !== "" &&
              handleSubmitQuestion(
                submitAnswer,
                {
                  ...currentAnswer,
                  currency_code: currencyCode,
                  value: freeTextAnswer,
                },
                [],
                AnswerStatus.Answered
              );
          }}
          disabled={disabled}
          currentAnswer={currentAnswer}
        />
      );
    case QuestionResponseType.CHECKBOX:
      return (
        <MultiChoiceSurveyQuestion
          question={question}
          currentAnswer={currentAnswer}
          checkedIds={checkedIds}
          type="checkbox"
          groupName={groupName}
          otherOptionOnChange={(answerId, checked) => {
            setCheckedIds(ids =>
              checked ? [...ids, answerId] : ids.filter(x => x !== answerId)
            );
          }}
          otherOptionOnBlur={(answer, newValue) => {
            handleSubmitQuestion(
              submitAnswer,
              { ...answer, value: [newValue] },
              checkedIds,
              AnswerStatus.Answered
            );
          }}
          onChange={(answer, checked) => {
            const updatedCheckedIds = checked
              ? [...checkedIds, answer.id]
              : checkedIds.filter(x => x !== answer.id);
            setCheckedIds(updatedCheckedIds as Array<string>);
            handleSubmitQuestion(
              submitAnswer,
              answer,
              updatedCheckedIds,
              AnswerStatus.Answered
            );
          }}
          disabled={disabled}
        />
      );
    default:
      return <></>;
  }
};

export default SurveyResponseQuestionElement;
