import { BaseAuthorizedRequest, GenericResponse } from "visible-ui";
import { AxiosResponse } from "axios";
import { SurveyUser } from "../../model/user";

export default class UpdateMyInfoRequest extends BaseAuthorizedRequest<
  GenericResponse,
  GenericResponse
> {
  constructor(accessToken: string, myInfoToUpdate: SurveyUser) {
    super("PUT", `/`, { payload: myInfoToUpdate }, accessToken);
  }

  processResponse({ data }: AxiosResponse<GenericResponse>): GenericResponse {
    return data;
  }
}
