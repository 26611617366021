import { FC } from "react";
import { Bar } from "react-chartjs-2";
import {
  Chart as ChartJS,
  ChartData,
  Title,
  Tooltip,
  Legend,
  ChartOptions,
} from "chart.js";
import BaseChart from "./BaseChart";

ChartJS.register(
  Title,
  Tooltip,
  Legend
);

export interface BarChartProps {
  data: ChartData;
  options?: ChartOptions;
}

const BarChart: FC<BarChartProps> = ({ data, options }) => {
  return <BaseChart data={data} options={options} ChartType={Bar} />;
};

export default BarChart;
