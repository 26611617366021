import { FC } from "react";
import { Badge, BadgeProps } from "react-bootstrap";

export interface BaseBadgeProps extends BadgeProps {
  soft?: boolean;
}

const BaseBadge: FC<BaseBadgeProps> = ({
  soft,
  className,
  children,
  ...props
}) => {
  const softSuffix = soft ? "-soft" : "";
  let variant = "";
  switch (props.bg) {
    case "primary":
      variant = `bg-primary${softSuffix}`;
      break;
    case "secondary":
      variant = `bg-secondary${softSuffix}`;
      break;
    case "success":
      variant = `bg-success${softSuffix}`;
      break;
    case "info":
      variant = `badge-info${softSuffix}`;
      break;
    case "warning":
      variant = `bg-warning${softSuffix}`;
      break;
    case "danger":
      variant = `bg-danger${softSuffix}`;
      break;
    case "light":
      variant = `badge-light${softSuffix}`;
      break;
    case "dark":
      variant = `badge-dark${softSuffix}`;
      break;
    default:
      break;
  }

  return (
    <Badge
      className={`${variant} ${className}`}
      data-testid="base-badge"
      {...props}
    >
      {children}
    </Badge>
  );
};

export default BaseBadge;
