import {
  ReactNode,
  ChangeEvent,
  CSSProperties,
  forwardRef,
  useState,
} from "react";
import { Form, FormControlProps, FormGroup } from "react-bootstrap";
import styled from "styled-components";
import TextInputLoader from "./TextInputLoader";

export const TEXT_INPUT_DATA_TEST = "text-input";

const InsideInputMessageStyled = styled.span<{
  isFocus: boolean;
  isError: boolean;
  isPrefix: boolean;
}>`
  --border-input-color: ${p =>
    p.isError
      ? "var(--bs-red)"
      : p.isFocus
      ? "var(--bs-primary)"
      : "var(--grey-4)"};
  transition: border-color 0.3s;
  border-top: 1px solid var(--border-input-color);
  border-inline-end: ${p => (p.isPrefix ? "0px" : "1px")} solid
    var(--border-input-color);
  border-inline-start: ${p => (p.isPrefix ? "1px" : "0px")} solid
    var(--border-input-color);
  border-bottom: 1px solid var(--border-input-color);
  white-space: nowrap;
  border-radius: ${p =>
    p.isPrefix ? " 0.375rem 0 0 0.375rem" : " 0 0.375rem 0.375rem 0"};
  background-color: #fff;
  padding-inline-end: ${p => (p.isPrefix ? "0px" : "13px")};
  padding-inline-start: ${p => (p.isPrefix ? "13px" : "0px")};
  transform: translateX(${p => (p.isPrefix ? "4px" : "-4px")});
  display: flex;
  align-items: center;
`;

export enum InputType {
  // Button = "button",
  // Checkbox = "checkbox",
  // Color = "color",
  // Date = "date",
  // DatetimeLocal = "datetime-local",
  Email = "email",
  // File = "file",
  // Hidden = "hidden",
  // Image = "image",
  // Month = "month",
  Number = "number",
  Password = "password",
  // Radio = "radio",
  // Range = "range",
  //  Reset = "reset",
  // Search = "search",
  // Submit = "submit",
  // Tel = "tel",
  Text = "text",
  // Time = "time",
  // Url = "url",
  // Week = "week"
}

const FormControlStyled = styled(Form.Control)`
  &.form-control:disabled {
    background-color: var(--grey-1);
    color: var(--grey-7);
  }
`;

interface TextModel {
  text: string | JSX.Element;
  style?: CSSProperties;
  className?: string;
}

export interface TextInputProps extends Omit<FormControlProps, 'title' | 'prefix'> {
  title?: TextModel;
  description?: TextModel;
  hint?: string;
  type: InputType;
  disabled?: boolean;
  invalid?: boolean;
  required?: boolean;
  errorMessage?: string;
  errorComponent?: ReactNode;
  name?: string;
  size?: "lg" | "sm";
  value?: string;
  maxLength?: number;
  onChange?: (e: ChangeEvent<HTMLInputElement>) => void;
  onBlur?: (e: React.FocusEvent<HTMLInputElement>) => void;
  onFocus?: (e: React.FocusEvent<HTMLInputElement>) => void;
  defaultValue?: string;
  min?: number;
  max?: number;
  className?: string;
  formGroupWrapperClassName?: string;
  style?: CSSProperties;
  maxLengthIndicator?: boolean;
  loading?: boolean;
  prefix?: JSX.Element | string;
  suffix?: JSX.Element | string;
  separatedPrefix?: JSX.Element | string;
  separatedSuffix?: JSX.Element | string;
}

const TextInput = forwardRef<HTMLInputElement, TextInputProps>(
  (
    {
      title,
      description,
      hint,
      disabled,
      invalid,
      errorMessage,
      errorComponent,
      formGroupWrapperClassName,
      maxLengthIndicator,
      maxLength,
      value,
      loading,
      prefix,
      suffix,
      separatedPrefix,
      separatedSuffix,
      onFocus,
      onBlur,
      ...props
    },
    ref?
  ) => {
    const [focus, setFocus] = useState(false);
    return (
      <FormGroup
        className={`form-group d-flex flex-column ${formGroupWrapperClassName}`}
      >
        {title && (
          <Form.Label className={title.className} style={title.style}>
            {title.text}
          </Form.Label>
        )}
        {description && (
          <Form.Label
            className={description.className}
            style={description.style}
          >
            {description.text}
          </Form.Label>
        )}
        {loading ? (
          <TextInputLoader />
        ) : (
          <section className={`position-relative d-flex`}>
            {separatedPrefix}
            {prefix && (
              <InsideInputMessageStyled
                className="input-height"
                isFocus={focus}
                isError={!!invalid}
                isPrefix={true}
              >
                {prefix}
              </InsideInputMessageStyled>
            )}
            <FormControlStyled
              data-cy={TEXT_INPUT_DATA_TEST}
              data-testid={TEXT_INPUT_DATA_TEST}
              onFocus={(ev: React.FocusEvent<HTMLInputElement>) => {
                setFocus(true);
                onFocus && onFocus(ev);
              }}
              onBlur={(ev: React.FocusEvent<HTMLInputElement>) => {
                setFocus(false);
                onBlur && onBlur(ev);
              }}
              {...props}
              isInvalid={invalid}
              placeholder={hint}
              maxLength={maxLength}
              value={value}
              disabled={disabled}
              ref={ref}
            />
            {suffix && (
              <InsideInputMessageStyled
                isFocus={focus}
                isError={!!invalid}
                isPrefix={false}
              >
                {suffix}
              </InsideInputMessageStyled>
            )}
            {separatedSuffix}
          </section>
        )}
        {invalid
          ? errorComponent || (
              <Form.Text className="form-text small invalid-feedback">
                {errorMessage}
              </Form.Text>
            )
          : undefined}
        {!disabled && maxLength && maxLengthIndicator && (
          <Form.Text className="text-gray-700 fs-6 mt-1">
            {maxLength - (value?.length || 0)} letters left
          </Form.Text>
        )}
      </FormGroup>
    );
  }
);

export default TextInput;
