import { BaseClient } from "visible-ui";
import { SurveyAnswer, Survey } from "../../model/survey";

import ChangeAnswerableStatus from "../survey/change-answerable-status-request";
import GetLatestAnswersRequest from "../survey/get-latest-answers";
import GetCurrentSurveyRequest from "../survey/get-response-request";
import SaveAnswerRequest from "../survey/save-answers-request";
import SubmitSurveyRequest from "../survey/submit-request";

export default class SurveyResponsesClient extends BaseClient {
  protected static instance: SurveyResponsesClient;

  protected constructor() {
    super("/api/v1/survey");
  }

  public static get Instance() {
    return this.instance || (this.instance = new SurveyResponsesClient());
  }

  public async getCurrentSurvey(accessToken: string): Promise<Survey | null> {
    return this.doRequest(new GetCurrentSurveyRequest(accessToken));
  }

  public async saveAnswer(answer: SurveyAnswer, accessToken: string) {
    return this.doRequest(new SaveAnswerRequest(answer, accessToken));
  }

  public async getLatestAnswers(accessToken: string) {
    return this.doRequest(new GetLatestAnswersRequest(accessToken));
  }

  public async submitSurvey(accessToken: string) {
    return this.doRequest(new SubmitSurveyRequest(accessToken));
  }

  public async changeAnswerableStatus(
    reason: SurveyAnswer,
    accessToken: string
  ) {
    return this.doRequest(new ChangeAnswerableStatus(reason, accessToken));
  }
}
