import { FC, Suspense, useEffect, useRef, useState } from "react";
import styled from "styled-components";
import ErrorBoundary from "../../error-boundary/ErrorBoundary";
import Loading from "../../loading/Loading";
import PageTitle from "../../../typography/PageTitle";
import { BaseTableWrapperProps } from "../../../../model/table";
import { paginationTableOptions } from "../../../../utils/paginated-table-helpers";
import { ProgressBarLoading } from "../../../../utils/styled-helpers";

const SHADOW_SIZE = "7px";

interface HorizontalScrollPosition {
  atStart: boolean;
  atEnd: boolean;
}

const CardStyled = styled.section<{ $atEnd: boolean }>`
  position: relative;
  &:after {
    content: "";
    height: 100%;
    width: 15px;
    transition: opacity 0.3s;
    opacity: ${p => (p.$atEnd ? 0 : 1)};
    background: linear-gradient(to right, rgba(0, 0, 0, 0), rgba(0, 0, 0, 0.1));
    top: 0;
    position: absolute;
    right: 0;
  }
`;

const TableBodyStyled = styled.section<{
  atStart: boolean;
  multiSelectColOffsetWidth?: number;
}>`
  position: relative;
  overflow-x: auto;
  white-space: nowrap;
  th:nth-child(1),
  td:nth-child(1),
  th:nth-child(2),
  td:nth-child(2) {
    background-color: var(--bg-row, #fff);
    z-index: 1;
    &:after {
      box-shadow: ${SHADOW_SIZE} 0 ${SHADOW_SIZE} -${SHADOW_SIZE} inset var(--grey-5);
      transition: width 0.3s;
      width: ${p => (p.atStart ? "0px" : SHADOW_SIZE)};
      height: 100%;
      position: absolute;
      top: 0;
      right: -${SHADOW_SIZE};
    }
  }
  th:nth-child(1),
  td:nth-child(1) {
    position: sticky;
    left: 0;
    &:after {
      content: ${p => (p.multiSelectColOffsetWidth ? "none" : '""')};
    }
  }
  th:nth-child(2),
  td:nth-child(2) {
    position: ${p => (p.multiSelectColOffsetWidth ? "sticky" : "initial")};
    left: ${p => p.multiSelectColOffsetWidth + "px"};
    &:after {
      content: ${p => (p.multiSelectColOffsetWidth ? '""' : "none")};
    }
  }
  &.scroll-table {
    max-height: 400px;
    table {
      margin-bottom: 0;
    }
    thead {
      position: sticky;
      top: 0;
      transform: translateY(-1px);
      z-index: 2;
    }
    &::-webkit-scrollbar {
      width: 0;
    }
    background: linear-gradient(#fff 30%, #ffffff00),
      linear-gradient(#ffffff00, #fff 70%) 0 100%,
      linear-gradient(to top, #fff 0%, var(--grey-4) 100%),
      linear-gradient(to bottom, #fff, var(--grey-2)) 0 100%;
    background-repeat: no-repeat;
    background-size: 100% 100px, 100% 66px, 100% 85px, 100% 33px;
    background-attachment: local, local, scroll, scroll;
  }
`;

const BaseTableWrapper: FC<BaseTableWrapperProps> = ({
  header,
  children,
  footer,
  className = "",
  title,
  options = paginationTableOptions,
  loading,
  multiSelectColOffsetWidth,
}) => {
  const [horizontalScrollPosition, setHorizontalScrollPosition] =
    useState<HorizontalScrollPosition>({ atStart: true, atEnd: true });

  const ref = useRef<HTMLTableElement | null>(null);

  const onSetHorizontalScrollPosition = (el: HTMLTableElement) => {
    const { scrollLeft, scrollWidth, offsetWidth } = el;
    const atStart = scrollLeft === 0;
    const atEnd = scrollLeft + offsetWidth >= scrollWidth;
    setHorizontalScrollPosition({ atStart, atEnd });
  };

  useEffect(() => {
    ref.current && onSetHorizontalScrollPosition(ref.current);
  }, [ref]);

  return (
    <>
      {title && <PageTitle {...title} />}
      <CardStyled
        className="vr-card overflow-hidden"
        $atEnd={horizontalScrollPosition.atEnd}
      >
        {header && (
          <section className="px-4 py-2 z-high bg-white">{header}</section>
        )}
        <ErrorBoundary>
          <Suspense
            fallback={
              <div style={{ textAlign: "center" }}>
                <Loading size="sm" />
              </div>
            }
          >
            <TableBodyStyled
              ref={ref}
              onScroll={({ target }) =>
                onSetHorizontalScrollPosition(target as HTMLTableElement)
              }
              id="table-body"
              atStart={horizontalScrollPosition.atStart}
              multiSelectColOffsetWidth={multiSelectColOffsetWidth}
              className={`lcd-table-card-body horizontal-scrollbar ${className} ${
                options?.scrollable ? "scroll-table" : ""
              }`}
            >
              {loading && <ProgressBarLoading className="z-high" />}
              {children}
              <section className="position-sticky left-0">{footer}</section>
            </TableBodyStyled>
          </Suspense>
        </ErrorBoundary>
      </CardStyled>
    </>
  );
};

export default BaseTableWrapper;
