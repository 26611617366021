import { FC, useCallback, useEffect, useState } from "react";
import { Button, Form } from "react-bootstrap";
import { useRecoilCallback, useRecoilValue } from "recoil";
import { isEqual } from "lodash";
import { useTranslation } from "react-i18next";

import {
  BtnSpinnerAutoLoading,
  getQuestionPermissionsAsString,
  InputType,
  MultipleChoiceBaseOption,
  replaceOrAddElement,
  TextInput,
  TextInputErrMsgManagement,
  toastError,
  validateEmail,
} from "visible-ui";

import { SurveyUser } from "../../model/user";
import { flatQuestionsSurveySelector } from "../../state/selectors/survey-selector";
import { currentSurveyAtom } from "../../state/atoms/survey-responses-atoms";
import { allOrganizationUsersAtom } from "../../state/atoms/survey-users-atoms";
import SurveyUsersClient from "../../communication/clients/survey-users-client";
import QuestionsMultiSelect from "./QuestionsMultiSelect";
import { useAuth } from "../../hooks/auth-hooks";

interface ShareSurveyFormProps {
  currentUser: SurveyUser;
  onCancel: () => void;
}

const ShareSurveyForm: FC<ShareSurveyFormProps> = ({
  currentUser,
  onCancel,
}) => {
  const { getAccessToken } = useAuth();

  const { t } = useTranslation();
  const currentSurvey = useRecoilValue(currentSurveyAtom);
  const flatQuestions = useRecoilValue(flatQuestionsSurveySelector);

  const [entireQuestionnaire, setEntireQuestionnaire] = useState(
    !currentUser.sub_permissions.length
  );
  const [_currentUser, _setCurrentUser] = useState(currentUser);

  useEffect(() => {
    _setCurrentUser(_ => ({
      ..._,
      sub_permissions: entireQuestionnaire ? [] : currentUser.sub_permissions,
    }));
  }, [entireQuestionnaire]);

  const [subPermissionsShow, setSubPermissionsShow] = useState(false);
  const { name, email, job_title } = _currentUser;
  const allOrganizationUsers = useRecoilValue(allOrganizationUsersAtom);

  const [inputsValidation, setInputsValidation] = useState({
    email: false,
    name: false,
  });
  const [errorMessage, setErrorMessage] = useState("");
  const [personalMessage, setPersonalMessage] = useState("");

  const _validateEmail = (_email: string) => {
    if (!validateEmail(_email)) {
      setErrorMessage(t("validationMessage.emailFollowingFormat"));
      return false;
    } else if (allOrganizationUsers?.find(({ email }) => email === _email)) {
      setErrorMessage(t("validationMessage.emailAlreadyExists"));
      return false;
    }
    setErrorMessage("");
    return true;
  };

  const _addPermission = useRecoilCallback(
    ({ set }) =>
      async (accessToken: string) => {
        const res = await SurveyUsersClient.Instance.shareSurvey(
          accessToken,
          _currentUser,
          personalMessage
        );
        if (res?.success) {
          set(allOrganizationUsersAtom, _users => [
            { ..._currentUser, id: res.user_created_id },
            ..._users,
          ]);
        }
        return res;
      }
  );
  const _editPermission = useRecoilCallback(
    ({ set }) =>
      async (accessToken: string) => {
        const res = await SurveyUsersClient.Instance.updateSharedUser(
          accessToken,
          _currentUser
        );
        if (res?.success) {
          set(allOrganizationUsersAtom, _users =>
            replaceOrAddElement(_users, _currentUser)
          );
        }
        return res;
      }
  );

  const sanitizePersonalMessage = (message:string) => {
    let str;
    if (!message)
      return message;
    else
      str = message.toString();

    return str.replace( /(<([^>]+)>)/ig, '');
  }

  const addOrEditPermission = useCallback(async () => {
    try {
      const accessToken = await getAccessToken();

      const res = _currentUser.id
        ? await _editPermission(accessToken)
        : await _addPermission(accessToken);
      if (!res?.success) {
        toastError(res?.error_message || t("errorMessage.shareSurvey"));
      }
    } catch (error) {
      console.error(error);
      toastError(t("errorMessage.shareSurvey"));
    }
  }, [_currentUser, _addPermission, _editPermission, getAccessToken]);

  return subPermissionsShow ? (
    currentSurvey && (
      <QuestionsMultiSelect
        subPermissions={_currentUser.sub_permissions || []}
        setSubPermissions={_subPermissions =>
          _setCurrentUser(_ => ({ ..._, sub_permissions: _subPermissions }))
        }
        questionnaireResponseData={currentSurvey}
        onClose={() => setSubPermissionsShow(false)}
      />
    )
  ) : (
    <Form className="fade-in p-4">
      <section className="d-grid grid-col-3 gap-3">
        <TextInputErrMsgManagement
          title={{ text: t("shareSurvey.emailAddress"), className: "fw-bold" }}
          hint={"user.name@yourcompany.com"}
          type={InputType.Email}
          disabled={!!currentUser.email}
          value={email}
          onChange={e =>
            _setCurrentUser(params => ({
              ...params,
              email: e.target.value,
            }))
          }
          setValidation={valid =>
            setInputsValidation(validations => ({
              ...validations,
              email: valid,
            }))
          }
          validation={currentUser.email ? () => true : _validateEmail}
          invalidErrorMessage={errorMessage}
          emptyErrorMessage={t("validationMessage.required")}
        />
        <TextInputErrMsgManagement
          title={{ text: t("shareSurvey.fullName"), className: "fw-bold" }}
          type={InputType.Text}
          value={name}
          onChange={e =>
            _setCurrentUser(params => ({
              ...params,
              name: e.target.value,
            }))
          }
          setValidation={valid =>
            setInputsValidation(validations => ({
              ...validations,
              name: valid,
            }))
          }
          emptyErrorMessage={t("validationMessage.required")}
        />
        <TextInput
          title={{
            text: (
              <span>
                <b>{t("shareSurvey.jobTitle")}</b>{" "}
                <span className="text-gray-600">{`(${t(
                  "shareSurvey.optional"
                )})`}</span>
              </span>
            ),
          }}
          type={InputType.Text}
          value={job_title}
          onChange={e =>
            _setCurrentUser(params => ({
              ...params,
              job_title: e.target.value,
            }))
          }
        />
      </section>
      <section>
        {[true, false].map(bool => {
          const id = `${bool ? "entire" : "specific"}-questionnaire`;
          return (
            <span key={id} className="d-flex gap-3">
              <MultipleChoiceBaseOption
                id={id}
                key={id}
                groupName="is entire questionnaire"
                className="py-2 no-wrap"
                checked={entireQuestionnaire === bool}
                onChange={() => setEntireQuestionnaire(bool)}
              >
                {bool
                  ? t("shareSurvey.shareEntireSurvey")
                  : t("shareSurvey.shareSpecificQuestions")}
              </MultipleChoiceBaseOption>
              {!bool &&
                (entireQuestionnaire ? (
                  <Form.Control disabled />
                ) : (
                  <div
                    className="form-control cursor-pointer"
                    onClick={() => setSubPermissionsShow(true)}
                  >
                    {_currentUser.sub_permissions?.length ? (
                      <span className="d-flex align-items-center justify-content-between pe-4">
                        {t("shareSurvey.numberSelected", {
                          number: _currentUser.sub_permissions?.length,
                        })}{" "}
                        {`(
                        ${getQuestionPermissionsAsString(
                          flatQuestions,
                          _currentUser.sub_permissions
                        )}
                        )`}
                        <span className="text-primary">
                          {t("shareSurvey.edit")}
                        </span>
                      </span>
                    ) : (
                      <span className="text-primary">
                        {t("shareSurvey.selectQuestions")}
                      </span>
                    )}
                  </div>
                ))}
            </span>
          );
        })}
      </section>
      {!currentUser.id && (
        <section>
          <Form.Label className="d-flex align-items-center gap-2 mt-4">
            <b> {t("shareSurvey.personalMessage")} </b>
            <span className="text-gray-600">{`(${t(
              "shareSurvey.optional"
            )})`}</span>
          </Form.Label>

          <Form.Control
            as="textarea"
            className="form-control resize-none"
            rows={3}
            maxLength={750}
            placeholder={t("shareSurvey.personalMessagePlaceholder")}
            onChange={e => setPersonalMessage(sanitizePersonalMessage(e.target.value))}
            value={personalMessage}
          />
        </section>
      )}
      <section className="d-flex justify-content-end mt-4">
        <Button type="button" variant="link" onClick={onCancel}>
          {t("shareSurvey.cancel")}
        </Button>
        <BtnSpinnerAutoLoading
          variant="primary"
          type="submit"
          handleClick={async (onStart, onDone) => {
            onStart();
            await addOrEditPermission();
            onDone();
          }}
          done={onCancel}
          disabled={
            !!Object.values(inputsValidation).some(_ => !_) ||
            (!!_currentUser.id && isEqual(_currentUser, currentUser))
          }
          loadMsg={t("shareSurvey.sharing")}
        >
          {t("shareSurvey.share")}
        </BtnSpinnerAutoLoading>
      </section>
    </Form>
  );
};

export default ShareSurveyForm;
