import { FC } from "react";
import { Navbar } from "react-bootstrap";
import BaseComponentProps from "../../../base-component-props";

const TopBar: FC<BaseComponentProps> = (props) => {
  return (
    <Navbar bg="white" sticky="top" {...props} />
  );
};

export default TopBar;
