import { selector, selectorFamily } from "recoil";
import {
  QuestionSubValidationTypes,
  SurveyAnswer,
  SurveyQuestion,
} from "../../model/survey";
import {
  currentSurveyAtom,
  surveyAnswersMap,
} from "../atoms/survey-responses-atoms";

export const flatQuestionsSurveySelector = selector<SurveyQuestion[]>({
  key: "flatQuestionsSurveySelector",
  get: async ({ get }) => {
    const currentSurvey = get(currentSurveyAtom);
    return currentSurvey
      ? currentSurvey.sections.map(_ => _.questions).flat()
      : [];
  },
});

export const questionsSubValidationSelector = selectorFamily<
  any[] | undefined,
  string
>({
  key: "questionsSubValidationSelector",
  get:
    (questionId: string) =>
    async ({ get }) => {
      const allQuestions = get(flatQuestionsSurveySelector);
      const { answersMap } = get(surveyAnswersMap);
      const question = allQuestions.find(_ => _.id === questionId);
      const answer = answersMap.get(questionId);
      const results = question?.sub_validations?.map(subValidation => {
        const relatedAnswer = answersMap.get(subValidation.related_question_id);
        if (relatedAnswer) {
          switch (subValidation.validation_type) {
            case QuestionSubValidationTypes.greater_or_equal:
              if (answer && answer.value <= relatedAnswer.value) {
                return false;
              }
              break;
            case QuestionSubValidationTypes.lesser_or_equal:
              if (answer && answer.value >= relatedAnswer.value) {
                return false;
              }
              break;
          }
        }
        return true;
      });
      return results;
    },
});

export const currentCurrencySelectorFamily = selectorFamily<
  string | undefined,
  string
>({
  key: "currentCurrencySelectorFamily",
  get:
    (questionId: string) =>
    ({ get }) => {
      const answerMapObj = get(surveyAnswersMap);
      return answerMapObj.answersMap.get(questionId)?.currency_code;
    },
  set:
    (questionId: string) =>
    ({ set }, newValue) => {
      set(surveyAnswersMap, (oldValue: any) => {
        const oldAnswer = oldValue.answersMap.get(questionId);
        const newAnswer = {
          ...oldAnswer,
          currency_code: newValue,
        };
        oldValue.answersMap.set(questionId, newAnswer);
        return {
          ...oldValue,
          answersMap: new Map<string, SurveyAnswer>(oldValue.answersMap),
        };
      });
    },
});
