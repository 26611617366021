import { FC, useState } from "react";
import { Button, ModalDialog } from "react-bootstrap";
import { MaxWidthSpan } from "../styled-helpers";
import ModalWithX from "../../components/base/modal/ModalWithX";
import BtnSpinnerAutoLoading from "../../components/base/loading/BtnSpinnerAutoLoading";
import { ButtonVariant } from "react-bootstrap/esm/types";

interface OptionsConfirmationModal {
  title?: string | JSX.Element;
  subtitle?: string | JSX.Element;
  cancelBtn?: string;
  confirmBtn?: string;
  confirmationLoadingMsg?: string;
  confirmBtnVariant?: ButtonVariant;
  width?: string;
  maxWidth?: string;
}

interface DeletingOrganizationConfirmationModalProps {
  show: boolean;
  onHide: () => void;
  onApprove: () => Promise<void>;
  options?: OptionsConfirmationModal;
}

const ConfirmationModal: FC<DeletingOrganizationConfirmationModalProps> = ({
  show,
  onHide,
  onApprove,
  options,
}) => {
  return (
    <ModalWithX
      id="deleting-organization-confirmation-modal"
      backdrop="static"
      backdropClassName="z-back-high-modal"
      className="z-high-modal"
      show={show}
      onHide={onHide}
      centered
      dialogAs={ModalDialog}
    >
      <MaxWidthSpan
        maxWidth={options?.maxWidth || "450px"}
        width={options?.width}
        className="pt-6 pb-4 px-5"
      >
        {options?.title || <h2 className="text-start mt-3">Are you sure?</h2>}
        {options?.subtitle}
        <BtnSpinnerAutoLoading
          variant={options?.confirmBtnVariant || "primary"}
          type="submit"
          handleClick={async (onStart, onDone) => {
            onStart();
            await onApprove();
            onDone();
          }}
          done={onHide}
          className="mt-4 mb-3 float-end"
          loadMsg={options?.confirmationLoadingMsg}
        >
          {options?.confirmBtn || "Approve"}
        </BtnSpinnerAutoLoading>
        <Button
          type="button"
          variant="link"
          onClick={onHide}
          className="mt-4 mb-3 float-end me-4"
        >
          {options?.cancelBtn || "Cancel"}
        </Button>
      </MaxWidthSpan>
    </ModalWithX>
  );
};

export const useConfirmationModal = (
  onApprove: () => Promise<void>,
  options?: OptionsConfirmationModal
) => {
  const [show, setShow] = useState(false);
  const trigger = () => setShow(true);

  return {
    trigger,
    confirmationModal: (
      <ConfirmationModal
        show={show}
        onHide={() => setShow(false)}
        onApprove={onApprove}
        options={options}
      />
    ),
  };
};
