import { DateIosString } from "../utils/time";

export enum BoxStatus {
  ACTIVE = "CONNECTED",
  INACTIVE = "DISCONNECTED",
  INITIAL = "INITIAL",
}

export interface Box {
  _id: string;
  name: string;
  client_id: string;
  salted_api_key?: string;
  creation_date: DateIosString;
  updated_date?: DateIosString;
  active: boolean;
  deactivation_time?: DateIosString;
  last_heartbeat?: DateIosString;
  status: BoxStatus;
  public_key?: string;
  private_key_passphrase?: string;
}
