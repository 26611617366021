import { FC, useEffect, useRef, useState } from "react";
import { Button } from "react-bootstrap";
import styled from "styled-components";
import { useResize } from "../../../utils/hooks/use-resize";
import { useScroll } from "../../../utils/hooks/use-scroll";

const PADDING_TOP_SCREEN_WRAPPER = 62;
const PADDING_BOTTOM_SCREEN_WRAPPER = 41;
const PADDING_INLINE_SCREEN_WRAPPER = 37;

interface PositionTile {
  offsetTop: number;
  offsetLeft: number;
}

const BackDrop = styled.section<{ isActive: boolean }>`
  position: fixed;
  top: 0;
  left: 0;
  background-color: #000000aa;
  opacity: 0;
  width: 100%;
  height: 100%;
  padding: 0 37px;
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  transition: opacity 0.3s 0s, z-index 0s 0.5s;

  > button {
    margin: 10px 0;
    width: 87px;
    font-size: 13px;
    background-color: #fff;
    border: none;
  }
  ${p =>
    p.isActive &&
    `
      transition: opacity 0.3s 0.2s, z-index 0s 0s;
      opacity: 1;
      `}
`;

const ExpendedElementContainer = styled.section<{
  isActive: boolean;
  positionTile: PositionTile | null;
}>`
  height: 100%;
  width: 100%;
  border-radius: 12px;
  top: 0;
  left: 0;
  transition: 0.3s 0.2s;
  > * {
    opacity: 0;
    transition: opacity 0.5s 0s;
  }
  ${p =>
    p.isActive &&
    `
        background-color: #fff;
        top: calc((${
          p.positionTile?.offsetTop
        }px * -1) + ${PADDING_TOP_SCREEN_WRAPPER}px);
        left: calc((${
          p.positionTile?.offsetLeft
        }px * -1) + ${PADDING_INLINE_SCREEN_WRAPPER}px);
        transition: all 0.3s 0.2s, background-color 0s 0.7s;
        width: calc(100vw - ${PADDING_INLINE_SCREEN_WRAPPER * 2}px);
        height: calc(100vh - ${
          PADDING_TOP_SCREEN_WRAPPER + PADDING_BOTTOM_SCREEN_WRAPPER
        }px);
        > *, &:before  {
          opacity: 1;
          transition: opacity .5s 0.3s;
        }
    `}
  position: absolute;
  overflow-x: hidden;
`;

export interface ModalExpendedProps {
  close: () => void;
  expended: boolean;
  children?: React.ReactNode;
}

const ModalExpended: FC<ModalExpendedProps> = ({
  close,
  expended,
  children,
}) => {
  const mainTileRef = useRef<HTMLElement | null>(null);
  const [positionTile, setPositionTile] = useState<PositionTile | null>(null);

  const positionTileHandle = () => {
    if (mainTileRef.current) {
      expended && mainTileRef.current.scrollTo({ top: 0 });
      const { parentElement } = mainTileRef.current;
      if (parentElement) {
        setPositionTile({
          offsetTop: parentElement.offsetTop - window.scrollY,
          offsetLeft: parentElement.offsetLeft,
        });
      }
    }
  };
  useEffect(() => {
    positionTileHandle();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [expended]);

  useResize(positionTileHandle);
  useScroll(positionTileHandle);

  return (
    <>
      <BackDrop
        className={expended ? "z-back-modal" : "z-low"}
        onClick={ev => {
          ev.stopPropagation();
          close();
        }}
        isActive={expended}
      >
        <Button variant="outline-primary">Close</Button>
      </BackDrop>
      <ExpendedElementContainer
        className={`${expended ? "z-modal" : "z-low"} vertical-scrollbar`}
        isActive={expended}
        positionTile={positionTile}
        ref={mainTileRef}
      >
        {children}
      </ExpendedElementContainer>
    </>
  );
};

export default ModalExpended;
