import { atom, atomFamily, selector } from "recoil";
import { UtcTime } from "visible-ui";
import SurveyResponsesClient from "../../communication/clients/survey-client";
import { SurveyAnswer, Survey } from "../../model/survey";
import { useAuth } from "../../hooks/auth-hooks";

export const surveyAnswersMap = atom({
  key: "surveyAnswersMap",
  default: selector({
    key: "surveyAnswersMap/default",
    get: async () => {
      let answersMap = new Map<string, SurveyAnswer>();
      let lastUpdate, lastUpdater: string | undefined;
      try {
        // eslint-disable-next-line react-hooks/rules-of-hooks
        const { getAccessToken } = useAuth();
        const accessToken = await getAccessToken();
        const latestAnswers =
          await SurveyResponsesClient.Instance.getLatestAnswers(accessToken);
        latestAnswers?.answers.forEach((answer: SurveyAnswer) => {
          if (answer.value || answer.will_not_answer) {
            answersMap.set(answer.question_id, answer);
          }
        });
        lastUpdate = latestAnswers?.last_update;
        lastUpdater = latestAnswers?.last_updater;
      } catch (error) {
        console.error(`error while trying to fetch latest answers`, error);
      }
      return {
        answersMap: new Map<string, SurveyAnswer>(answersMap),
        lastUpdate: lastUpdate
          ? new Date(UtcTime(lastUpdate || new Date()))
          : undefined,
        lastUpdater,
      };
    },
  }),
});

export const currentSurveyAtom = atom<Survey | null>({
  key: "currentSurveyAtom",
  default: selector({
    key: "currentSurveyAtom/default",
    get: async () => {
      try {
        // eslint-disable-next-line react-hooks/rules-of-hooks
        const { getAccessToken } = useAuth();
        const accessToken = await getAccessToken();
        return await SurveyResponsesClient.Instance.getCurrentSurvey(
          accessToken
        );
      } catch (error) {
        console.error(`error while trying to fetch current survey`, error);
        return null;
      }
    },
  }),
});

export const invalidAnswersAtomFamily = atomFamily<
  { valid: boolean; message: string },
  string
>({
  key: "invalidAnswersAtomFamily",
  default: { valid: true, message: "" },
});
