export const replaceElement = <T>(
  array: Array<T> | null | undefined,
  elementToReplace: T,
  newElement: T,
  property = "id"
): Array<T> => {
  const newArray = [...(array || [])];
  const elementIndex = newArray.findIndex(
    _ => _[property] === elementToReplace[property]
  );
  if (elementIndex !== -1) {
    newArray.splice(elementIndex, 1, newElement);
  }
  return newArray;
};

export const replaceOrAddElement = <T>(
  array: Array<T> | null | undefined,
  newElement: T,
  property = "id"
): Array<T> => {
  const newArray = [...(array || [])];
  const elementIndex = newArray.findIndex(
    _ => _[property] === newElement[property]
  );
  newArray.splice(elementIndex, elementIndex === -1 ? 0 : 1, newElement);
  return newArray;
};

export const replaceElementByIdx = <T>(
  array: Array<T> | null | undefined,
  newElement: T,
  elementIndex: number
): Array<T> => {
  const newArray = [...(array || [])];
  newArray.splice(elementIndex, 1, newElement);
  return newArray;
};

export const removeElement = <T>(
  array: Array<T> | null | undefined,
  elementToRemove: T,
  property = "id"
): Array<T> => {
  const newArray = [...(array || [])];
  const elementIndex = newArray.findIndex(
    _ => _[property] === elementToRemove[property]
  );
  if (elementIndex !== -1) {
    newArray.splice(elementIndex, 1);
  }
  return newArray;
};
