import { AxiosRequestConfig, Method, AxiosResponse, ResponseType } from "axios";

type Parameters = Record<string, string | number | boolean | undefined | any[]>;
type ParamsSerializer = ((params: Parameters) => string) | undefined;

export interface BaseRequestArgs {
  headers?: Record<string, string>;
  parameters?: Parameters;
  payload?: any;
  timeout?: number;
  responseType?: ResponseType;
  paramsSerializer?: ParamsSerializer;
}

// S - the data received from the server
// T - the processed data that the webapp expects
// processResponse converts S to T
export default abstract class BaseRequest<S, T> {
  protected method: Method;
  protected requestURL: string;
  protected headers?: Record<string, string>;
  protected parameters?: Parameters;
  protected payload?: any;
  protected timeout?: number;
  protected responseType?: ResponseType;
  protected paramsSerializer?: ParamsSerializer;

  constructor(method: Method, url: string, options?: BaseRequestArgs) {
    this.method = method;
    this.requestURL = url;
    this.headers = options?.headers;
    this.parameters = options?.parameters;
    this.payload = options?.payload;
    this.timeout = options?.timeout;
    this.responseType = options?.responseType;
    this.paramsSerializer = options?.paramsSerializer;
  }

  abstract processResponse(response: AxiosResponse<S>): T | Promise<T>;

  public async toRequest(): Promise<AxiosRequestConfig> {
    return {
      method: this.method,
      url: this.requestURL,
      headers: this.headers,
      params: this.parameters,
      data: this.payload,
      timeout: this.timeout,
      responseType: this.responseType,
      paramsSerializer: { serialize: this.paramsSerializer },
    };
  }
}
