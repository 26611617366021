import { useEffect, useState } from "react";
import { useRecoilValueLoadable } from "recoil";
import { currentSurveyAtom } from "../state/atoms/survey-responses-atoms";

export const useSurveySubmittedOrEnded = () => {
  const _surveyResponseLoadable = useRecoilValueLoadable(currentSurveyAtom);

  const [loading, setLoading] = useState(true);
  const [submitted, setSubmitted] = useState(false);
  const [ended, setEnded] = useState(false);

  useEffect(() => {
    setLoading(_surveyResponseLoadable.state === "loading");
    if (_surveyResponseLoadable.state === "hasValue") {
      setLoading(false);
      const surveyResponse = _surveyResponseLoadable.getValue();
      surveyResponse && setSubmitted(surveyResponse.submitted);
      surveyResponse && setEnded(surveyResponse.ended);
    }
  }, [_surveyResponseLoadable]);

  return { loading, submitted, ended };
};
