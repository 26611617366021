import { FC } from "react";
import { ToastContainer } from "react-toastify";

import "react-toastify/dist/ReactToastify.min.css";
import styled from "styled-components";

export const toasterAutoClose: number | false = 10000; // 10 sec

const StyledContainer = styled(ToastContainer)`
  .Toastify__close-button {
    transform: scale(1.4);
    height: 100%;
    display: flex;
    align-items: center;
  }

  .Toastify__toast {
    max-width: 517px;
    width: 80vw;
    min-height: 48px;
    height: auto;
    padding: 12px 20px 15px;
    border-radius: 6px;
    font-family: inherit;
  }
  .Toastify__toast--error {
    background-color: var(--vr-red-pink);
  }
`;

const VisibleToastContainer: FC = () => {
  return (
    <StyledContainer
      position="bottom-center"
      autoClose={toasterAutoClose}
      hideProgressBar={false}
      newestOnTop={false}
      closeOnClick
      rtl={false}
      limit={3}
      pauseOnFocusLoss
      draggable
      pauseOnHover
    />
  );
};

export default VisibleToastContainer;
