import { FC } from "react";
import styled from "styled-components";
import { BaseComponentProps } from "../../../";

const TabItemStyled = styled.span`
  padding: 10px 12px;
  transition: background-color 0.3s, color 0.3s;
  display: flex;
  align-items: center;
  &:not(:last-child) {
    border-inline-end: 1px solid var(--grey-3);
  }
  &.active {
    color: #fff;
    background-color: var(--bs-blue);
  }
  &:not(.active) {
    cursor: pointer;
  }
`;

export interface TabItemProps extends BaseComponentProps {
  active: boolean;
  tab: JSX.Element;
  refs: (HTMLSpanElement | null)[];
  idx: number;
}

const TabItem: FC<TabItemProps> = ({ tab, active, onClick, refs, idx }) => {
  return (
    <TabItemStyled
      className={`tab-item ${active ? "active" : ""}`}
      onClick={onClick}
    >
      <span ref={ev=> refs[idx] = ev}></span>
      {tab}
    </TabItemStyled>
  );
};

export default TabItem;
