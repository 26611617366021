import { EngagementTaskType } from "./engagement-task";

export type KnowledgeBaseCategory =
  | EngagementTaskType.document
  | EngagementTaskType.report
  | EngagementTaskType.tool
  | EngagementTaskType.requested_file
  | EngagementTaskType.ng_tool
  | EngagementTaskType.ci_tool
  | EngagementTaskType.requested_file_tool;

export const knowledgeBaseCategories: KnowledgeBaseCategory[] = [
  EngagementTaskType.document,
  EngagementTaskType.report,
  EngagementTaskType.tool,
  EngagementTaskType.requested_file,
  EngagementTaskType.ng_tool,
  EngagementTaskType.ci_tool,
  EngagementTaskType.requested_file_tool,
];

export const ENGAGEMENT_TASK_TYPE: Record<EngagementTaskType, string> = {
  questionnaire: "Questionnaire",
  tool: "Tool",
  report: "Report",
  review_session: "Review session",
  document: "Document",
  requested_file: "File request",
  ng_tool: "Tool",
  ci_tool: "Control Insights Tool",
  requested_file_tool: "File request tool",
};

export const oldKnowledgeBaseCategories: KnowledgeBaseCategory[] =
  knowledgeBaseCategories.filter(
    _ =>
      _ !== EngagementTaskType.requested_file &&
      _ !== EngagementTaskType.requested_file_tool &&
      _ !== EngagementTaskType.ng_tool
  );

export type SectionType = "about" | "instructions" | "privacy";
export const dataSecurity = "data security";

export const SECTIONS: Record<SectionType, string> = {
  about: "About",
  instructions: "Instructions",
  privacy: "Privacy",
};

export interface KnowledgeBaseDocumentSection {
  section_link?: string;
  section_title?: string;
  section_content?: string;
}

export interface KnowledgeBaseDocumentModel {
  id?: string;
  content?: string;
  document_name: string;
  description?: string;
  category: KnowledgeBaseCategory;
  version: number;
  last_updated?: string;
  creation_date?: string;
  sections?: Record<SectionType, KnowledgeBaseDocumentSection>;
  last_editor_user_name?: string;
}
