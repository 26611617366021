import { AxiosResponse } from "axios";
import { User } from "../../model/auth";
import { GenericResponse } from "../../model";
import BaseAuthorizedRequest from "../requests/base-authorized-request";

export default class UpdateOtpUserRequest extends BaseAuthorizedRequest<
  GenericResponse,
  GenericResponse
> {
  constructor(user: User, currentChampionId?: string) {
    super("PUT", `/update-otp-user/`, {
      payload: user,
      parameters: { current_champion_id: currentChampionId },
    });
  }

  processResponse(response: AxiosResponse<GenericResponse>): GenericResponse {
    return response.data;
  }
}
