import { useEffect } from "react";

export enum DefaultTitle {
  BitSight360 = "BitSight 360",
  CyberSurveys = "Cyber Surveys",
}

export const useTitle = (title: string, defaultTitle: DefaultTitle) => {
  useEffect(() => {
    document.title = title;

    // returned function will be called on component unmount
    return () => {
      document.title = defaultTitle;
    };
  }, [title, defaultTitle]);
};
