import { FC } from "react";
import { Form, FormSelectProps } from "react-bootstrap";
import { data } from "currency-codes";
import { useRecoilState } from "recoil";

import { currentCurrencySelectorFamily } from "../../state/selectors/survey-selector";

interface CurrencySelectorProps extends FormSelectProps {
  questionId: string;
  onCurrencyChange: (value: string) => void;
  defaultValue?: string;
}

const CurrencySelector: FC<CurrencySelectorProps> = ({
  questionId,
  onCurrencyChange,
  defaultValue = "USD",
  ...props
}) => {
  const [currency, setCurrency] = useRecoilState(
    currentCurrencySelectorFamily(questionId)
  );

  return (
    <Form.Select
      aria-label="currency selector"
      onChange={({ target: { value } }) => {
        setCurrency(value);
        onCurrencyChange(value);
      }}
      value={currency || defaultValue}
      {...props}
    >
      {[...data]
        .sort(
          // by code
          (a, b) => a.code.localeCompare(b.code)
        )
        .map(({ code, currency }, idx) => (
          <option key={`currency-code-option-${code}-${idx}`} value={code}>
            {`${code} (${currency})`}
          </option>
        ))}
    </Form.Select>
  );
};

export default CurrencySelector;
