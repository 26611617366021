import { FC, useEffect, useRef, useState } from "react";
import styled from "styled-components";
import TabItem from "./TabItem";
import TabContent from "./TabContent";

export type TabState = "initial" | "toEnd" | "toStart";

const TabGroupStyled = styled.section`
  border-radius: 6px;
  > nav {
    background-color: #fff;
    border: 1px solid var(--grey-3);
    width: fit-content;
    display: flex;
    white-space: nowrap;
    border-radius: 6px;
  }
  overflow: hidden;
  max-width: 100%;
  overflow-x: auto;
`;

export interface TabItemModel {
  tab: JSX.Element;
  content: JSX.Element;
}

interface TabGroupProps {
  tabItems: TabItemModel[];
  tabIdxInit?: number;
}

const TabGroup: FC<TabGroupProps> = ({ tabItems, tabIdxInit }) => {
  const [tabIdxActive, setTabIdxActive] = useState<number>(0);
  const refs = useRef<(HTMLSpanElement | null)[]>(tabItems.map(_ => null));

  useEffect(() => {
    tabIdxInit && setTabIdxActive(tabIdxInit);
  }, [tabIdxInit, setTabIdxActive]);

  useEffect(() => {
    refs.current[tabIdxActive]?.scrollIntoView({
      behavior: "smooth",
      block: "nearest",
      inline: "center",
    });
  }, [tabIdxActive]);

  return (
    <>
      {tabItems.length > 1 && (
        <TabGroupStyled className="horizontal-scrollbar">
          <nav>
            {tabItems.map((_, i) => (
              <TabItem
                refs={refs.current}
                idx={i}
                key={`tab-item-${i}`}
                tab={_.tab}
                active={tabIdxActive === i}
                onClick={() => setTabIdxActive(i)}
              />
            ))}
          </nav>
        </TabGroupStyled>
      )}
      {tabItems.map(
        (_, i) =>
          tabIdxActive === i && (
            <TabContent key={`tab-content-${i}`} content={_.content} />
          )
      )}
    </>
  );
};

export default TabGroup;
