import { useEffect, useState } from "react";
import { RecoilState, useRecoilStateLoadable } from "recoil";

export const useStateLoadable = <T>(atom: RecoilState<T | null>) => {
  const [stateLoadable, setStateLoadable] = useRecoilStateLoadable(atom);
  const [state, setState] = useState<T | null>(null);
  const [error, setError] = useState<Error | null>(null);
  const [loading, setLoading] = useState<boolean>(false);

  useEffect(() => {
    if (stateLoadable.state === "hasValue") {
      setState(stateLoadable.getValue());
    } else if (stateLoadable.state === "hasError") {
      setError(stateLoadable.errorMaybe());
    }
    setLoading(stateLoadable.state === "loading");
  }, [stateLoadable]);

  return {
    state,
    error,
    loading,
    setState: setStateLoadable,
  };
};
