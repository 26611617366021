import { FC, useState } from "react";
import BtnSpinnerLoading, { BtnSpinnerLoadingProps } from "./BtnSpinnerLoading";

export type handleClickModel = (
  onStart: () => void,
  onDone: () => void,
  e: React.MouseEvent<HTMLButtonElement, MouseEvent>
) => Promise<void>;
export interface BtnSpinnerAutoLoadingProps extends BtnSpinnerLoadingProps {
  loadMsg?: JSX.Element | string;
  handleClick: handleClickModel;
  done?: () => void;
}

const BtnSpinnerAutoLoading: FC<
  Omit<BtnSpinnerAutoLoadingProps, "isLoading">
> = ({
  disabled = false,
  spinnerClassName = "me-2",
  children,
  extraSmall = false,
  loadMsg = "Loading...",
  handleClick,
  done,
  ...props
}) => {
  const [loading, setLoading] = useState(false);

  const onDone = () => {
    setLoading(false);
    done && done();
  };

  return (
    <BtnSpinnerLoading
      {...props}
      onClick={e => handleClick(() => setLoading(true), onDone, e)}
      isLoading={loading}
      spinnerClassName={spinnerClassName}
      extraSmall={extraSmall}
      disabled={disabled || loading}
    >
      {loading ? loadMsg : children}
    </BtnSpinnerLoading>
  );
};

export default BtnSpinnerAutoLoading;
