import { GenericResponse, GenericUpdateResponse } from "../model/base";

export const lastChars = (str: string, countChars: number): string => {
  return str.substr(str.length - countChars);
};

export const ISO_DATE_PATTERN =
  /\d{4}-[01]\d-[0-3]\dT[0-2]\d:[0-5]\d:[0-5]\d\.\d+([+-][0-2]\d:[0-5]\d|Z)/;
export interface assetFileSplitterName {
  baseName: string;
  fileExtension: string | undefined;
}

export const nameSplitter = (fileName: string): assetFileSplitterName => {
  const splitterName = fileName.split(".");
  const fileExtension = splitterName.pop();
  const baseName = splitterName.join(".");
  return { baseName, fileExtension };
};

export const replaceIsoDateToTimestampInBaseFileName = (
  baseFileName: string,
  digitFromEnd = 5
): string => {
  const baseNameRegExpMatchArray = baseFileName.match(ISO_DATE_PATTERN);
  const [isoDate] = baseNameRegExpMatchArray ? baseNameRegExpMatchArray : [""];
  const timestamp = isoDate
    ? `_${(+new Date(isoDate)).toString().substr(-digitFromEnd)}`
    : "";
  return baseFileName.replace(ISO_DATE_PATTERN, timestamp);
};

export const isEllipsis = (e: Element): boolean => {
  return e.clientWidth < e.scrollWidth;
};

export const toggleStopPropagation = (
  element: Element | null,
  add: boolean,
  eventName: keyof WindowEventMap | string = "mousedown"
) =>
  element?.[add ? "addEventListener" : "removeEventListener"](eventName, ev =>
    ev.stopPropagation()
  );

export const convertGenericUpdateResponse = ({
  acknowledged,
  modified_count,
}: GenericUpdateResponse): GenericResponse => {
  return { success: acknowledged && modified_count > 0 };
};
