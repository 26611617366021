import { BaseAuthorizedRequest } from "visible-ui";
import { AxiosResponse } from "axios";
import { ShareSurveyResponse, SurveyUser } from "../../model/user";

export default class PostShareSurvey extends BaseAuthorizedRequest<
  ShareSurveyResponse,
  ShareSurveyResponse
> {
  constructor(accessToken: string, user: SurveyUser, personalMessage?: string) {
    super(
      "POST",
      `/share/`,
      {
        payload: { ...user, id: null },
        parameters: { personal_message: personalMessage },
      },
      accessToken
    );
  }

  processResponse({
    data,
  }: AxiosResponse<ShareSurveyResponse>): ShareSurveyResponse {
    return data;
  }
}
