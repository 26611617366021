import { FC } from "react";

interface SharedTextQuestionProps {
  questionNumber: number;
  questionContent: string;
}

const SharedTextQuestion: FC<SharedTextQuestionProps> = ({
  questionNumber,
  questionContent,
}) => {
  return (
    <div className="fw-bold pre-wrap">{`${questionNumber}. ${questionContent}`}</div>
  );
};

export default SharedTextQuestion;
